import { useEffect, useState } from "react";
import {
  Navigate,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Layout from "../../components/layout";

// components
import ErrorBoundary from "../../components/common/errorBoundary";
import LoadingSpinner from "../../components/common/loadingSpinner";
import IntakeHeader from "../../components/care/consultIntake/intakeHeader";

import IntakeIntro from "./intake/intro";
import DemographicsQuestions from "./intake/demographicsQuestions";
import MedicationQuestions from "./intake/medicationQuestions";
import PregnancyQuestions from "./intake/pregnancy";
import GeneralQuestions from "./intake/generalMedicalQuestions";
import IdentityVerificationInfo from "./intake/identityVerificationInfo";
import IdPhotoUpload from "./intake/idPhotoUpload";
import ShippingQuestions from "./intake/shipping";
import IntakeDone from "./intake/done";
import IntakeExit from "./intake/exit";
import { careService } from "../../services/care";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import Modal from "../../components/common/modal";
import { StiTreatmentModal } from "./stiTreatmentModal";
import { usePanelCart } from "../../hooks/cart/usePanelCart";
import { SUPPORT_EMAIL } from "../constants";
import { getCareIntakeUrl } from "../../components/care/utils";
import SymptomsUpsell from "../../components/care/consultIntake/upsells/SymptomsUpsell";

const ConsultIntake = ({ consults }) => {
  /*
   * State
   */

  const { consultId } = useParams();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // start out as loading so that page doesn't try to render without data loaded up first
  const [error, setError] = useState(null);
  const [consultIntake, setConsultIntake] = useState(null); // OK if is null, will create for first timers
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [consultAlreadySubmitted, setConsultAlreadySubmitted] = useState(false);
  const [allowReupload, setAllowReupload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const consult = consults.filter((c) => c.uid === consultId)[0];

  const { cart, checkoutOnCart } = usePanelCart();

  // CVR experiment state
  // only show the animation the first time they view their recommended treatment plan
  const sawBuildingRecommendationsKey = `consult-recommendations-loading-${consultId}`;
  const sawBuildingRecommendations = localStorage.getItem(
    sawBuildingRecommendationsKey
  )
    ? true
    : false;

  const currentUser = useLoggedInUser();
  const isFirstTimeVaginitisCareUser =
    !currentUser.care.vaginitis.hasCompletedConsult;

  const hasRecommendedProducts =
    consultIntake?.has_additional_product_recommendations;

  const vaginitisUpsellEnabled = currentUser.features.vaginitisUpsell;

  const baseUrl = `/care/consults/${consultId}/intake/`;
  const currentPage = pathname.split(baseUrl)[1];

  var allPages = [
    "intro/",
    "demographics/",
    "medical-questions/",
    "treatment/medications/",
    "treatment/general/",
    ...(hasRecommendedProducts &&
    vaginitisUpsellEnabled &&
    currentPage !== "treatment/general/"
      ? ["more-treatments/"]
      : []),
    "treatment/shipping/",
    "identity-verification/info/",
    "identity-verification/photo/",
    "done/",
  ];

  // these pages come before payment for recommended plan experiment
  const intakePagesPrePayment = [
    "intro/",
    "demographics/",
    "medical-questions/",
    "treatment/medications/",
    "treatment/general/",
  ];

  const intakeRecommendedPlanPages = [
    `/care/consults/${consultId}/recommended-treatment-plan/`,
  ];
  if (!sawBuildingRecommendations) {
    // add to beginning of array
    intakeRecommendedPlanPages.unshift(
      `/care/consults/${consultId}/build-recommendations/`
    );
  }

  // these pages come after payment for recommended plan first time users
  const intakePagesPostPayment = [
    "treatment/shipping/",
    "identity-verification/info/",
    "identity-verification/photo/",
    "done/",
  ];

  // only show these pages if user has not yet paid for care
  if (
    isFirstTimeVaginitisCareUser &&
    // exclude a la care, dynamic bundle, bundle + a la care
    consult?.purchase_type === "v0-bundle" &&
    !consult?.consult_paid
  ) {
    allPages = [
      ...intakePagesPrePayment,
      ...intakeRecommendedPlanPages,
      ...intakePagesPostPayment,
    ];
  }

  if (
    currentPage === "identity-verification/redo/" ||
    currentPage === "identity-verification/reupload/"
  ) {
    allPages = [
      "identity-verification/redo/",
      "identity-verification/reupload/",
      "done/",
    ];
  }

  const pageIndex = allPages.indexOf(currentPage);
  const isValidPage = pageIndex !== -1;
  const isFirstPage = isValidPage ? pageIndex === 0 : false;
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : false;
  var previousPage = isFirstPage ? "/care/" : allPages[pageIndex - 1];
  if (previousPage === "intro/") {
    // if consult is paid, skip intro
    if (consult?.consult_paid) {
      previousPage = "/care/";
    }
  }
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;
  const isLastIntakeInputPage = nextPage === "done/"; // last page where you are inputting data (so /done/ page doesn't count)

  /* effects */
  // Redirect to STI intake if consult is of type STI
  // Redirect to ungated rx intake of consult is of type UNGATED_RX
  useEffect(() => {
    if (consult?.type === "sti") {
      navigate(`/care/sti/consults/${consultId}/intake/`);
    } else if (consult?.type === "ungated-rx") {
      navigate(getCareIntakeUrl("consent", consult));
    }
  }, [consult, consultId, navigate]);

  useEffect(() => {
    const getOrCreateConsultIntake = async (consultId) => {
      setLoading(true);
      let consultIntake = null;

      const fetchConsultIntake = async (consultId) => {
        const consultResponse = await careService.fetchConsultIntake(
          consultId,
          (response) => {
            return response;
          }
        );

        return consultResponse ? consultResponse.data : null;
      };

      const createConsultIntake = async (consultId) => {
        const consultResponse = await careService.createConsultIntake(
          {
            consult_uid: consultId,
            remove_antibiotics_selected:
              state && state.removeAntibioticsSelected,
          },
          (response) => {
            return response;
          },
          (error, response) => {
            setError(response ? response : "Error creating Consult Intake");
          }
        );

        return consultResponse.data;
      };

      consultIntake = await fetchConsultIntake(consultId);
      if (!consultIntake) {
        consultIntake = await createConsultIntake(consultId);
      }

      setConsultIntake(consultIntake);

      if (consultIntake.submitted_at) {
        setConsultAlreadySubmitted(true);
        if (consultIntake.consult.status === "ID") {
          setAllowReupload(true);
        }
      }

      // If the user has not accepted the terms, then redirect them to the intro page
      if (!consultIntake.terms_agreed_at && !isFirstPage) {
        navigate(`${baseUrl}intro/`);
      }

      setLoading(false);

      return consultIntake;
    };

    getOrCreateConsultIntake(consultId);
  }, [baseUrl, consultId, navigate, state]); // Fetches consult intake upon page load & intake if it exists

  const getNextPageFullUrl = () => {
    // if next page is not a relative to the intake pages, then just use it directly
    if (nextPage.startsWith("/")) {
      return nextPage;
    } else {
      return `${baseUrl}${nextPage}`;
    }
  };

  const submitCurrentPage = (data, onError) => {
    // got rid of this check because sometimes the consult intake hasn't updated yet
    // to be in "paid" status
    // if they haven't paid for care yet, then just redirect them to checkout
    // if (consultIntake.consult?.status === "IN") {
    //   goToCheckout();
    //   return;
    // }

    // if user already submitted intake or if no data, then just continue to next page
    if (consultIntake?.submitted_at || Object.keys(data).length === 0) {
      // if is last page, then there might be empty data fields, so just submit
      if (!consultIntake?.submitted_at && isLastIntakeInputPage) {
        submitConsultIntake(onError);
        return;
      }
      if (nextPage) {
        navigate(getNextPageFullUrl());
      } else {
        navigate(`${baseUrl}done/`);
      }
      window.scrollTo(0, 0);
      return;
    }

    // otherwise if not already submitted, then update before continuing to next page
    updateConsultIntake(
      data,
      (responseData) => {
        // check if referred out
        // IE status means that they are ineligible for care, and move them to the exit page
        if (responseData.consult.status === "IE") {
          navigate(`${baseUrl}exit/`);
          window.scrollTo(0, 0);
          return;
        }

        if (!isLastIntakeInputPage) {
          if (
            currentPage === "treatment/general/" &&
            responseData?.has_additional_product_recommendations &&
            vaginitisUpsellEnabled
          ) {
            navigate(`${baseUrl}more-treatments/`);
          } else {
            navigate(getNextPageFullUrl());
          }
          window.scrollTo(0, 0);
        } else {
          // last page yay!
          // submit consult intake
          submitConsultIntake(onError);
        }
      },
      onError
    );
  };

  const submitConsultIntake = (onError) => {
    // if they haven't paid for care yet, then just redirect them to checkout
    if (!consultIntake.consult?.consult_paid) {
      goToCheckout();
      return;
    } else {
      setIsSubmitting(true);
      careService.submitConsultIntake(
        consultId,
        (response) => {
          navigate(`${baseUrl}done/`);
          setIsSubmitting(false);
          window.scrollTo(0, 0);
        },
        (err, resp) => {
          console.error(err);
          setIsSubmitting(false);
          if (onError) {
            onError(resp);
          }
        }
      );
    }
  };

  // this is used when we just want to update consult without going to next page
  const updateConsultIntake = (data, onSuccess, onError) => {
    setIsSubmitting(true);
    // if user already submitted intake or if no data
    if (consultIntake?.submitted_at && !allowReupload) {
      if (onError) {
        onError();
      }
      setIsSubmitting(false);
      navigate(`${baseUrl}done/`);
      return;
    }

    // otherwise if not already submitted, then update
    careService.updateConsultIntake(
      consultId,
      data,
      (response) => {
        setConsultIntake(response.data);
        if (onSuccess) {
          onSuccess(response.data);
        }
        setIsSubmitting(false);
      },
      (err, resp) => {
        console.error(err);
        setIsSubmitting(false);
        if (onError) {
          onError(resp);
        }
        if (resp === "Consult intake already submitted") {
          // if somehow user navigated back and tries to resubmit
          navigate(`${baseUrl}done/`);
        }
      }
    );
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedToNextPage = () => {
    // IE status means that they are ineligible for care, and move them to the exit page
    if (consultIntake.consult.status === "IE") {
      navigate(`${baseUrl}exit/`);
      window.scrollTo(0, 0);
      return;
    }
    // otherwise, if they've already paid then just progress them to next page
    if (consultIntake.consult.consult_paid) {
      navigate(`${baseUrl}${nextPage}`);
      window.scrollTo(0, 0);
      return;
    }

    // so user has NOT paid yet

    // for first time vaginitis care users for treatment programs
    if (
      isFirstTimeVaginitisCareUser &&
      consultIntake.consult.purchase_type === "v0-bundle"
    ) {
      if (intakePagesPostPayment.includes(nextPage)) {
        // they should have paid by now, so send them to checkout
        goToCheckout();
        return;
      } else {
        // otherwise, just proceed to next page
        navigate(`${baseUrl}${nextPage}`);
        window.scrollTo(0, 0);
        return;
      }
    }

    // this means that status is "IN" and they haven't paid yet, so send them to checkout
    goToCheckout();
  };

  // used by intro screen to accept terms and proceed to next page
  const proceedPastIntroPage = () => {
    if (!consultIntake.terms_agreed_at) {
      careService
        .asyncAgreeToTerms(consultId)
        .then((response) => {
          proceedToNextPage();
        })
        .catch((error) => {
          console.error(error);
          setError("Error agreeing to terms");
        });
    } else {
      proceedToNextPage();
    }
  };

  const goToCheckout = () => {
    setIsSubmitting(true);

    if (cart?.line_items?.length > 0) {
      // go to new ecomm checkout
      goToCartCheckout();
    }
  };

  // Checkout using ecomm panel cart
  const goToCartCheckout = async () => {
    try {
      const checkoutUrl = await checkoutOnCart(consultId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error(error);
      setError(
        `Error checking out. Please try again or contact ${SUPPORT_EMAIL}`
      );
    }
  };

  const stiTreatmentDisclaimerSeen = localStorage.getItem(
    "sti-treatment-disclaimer-seen"
  );

  useEffect(() => {
    if (!stiTreatmentDisclaimerSeen && consultIntake?.consult.sti_positive) {
      setIsModalOpen(true);
      localStorage.setItem("sti-treatment-disclaimer-seen", "true");
    }
  }, [consultIntake?.consult.sti_positive, stiTreatmentDisclaimerSeen]);

  return (
    <Layout
      title="Care | Consult | Intake"
      padded={false}
      bgClass="bg-evvy-cream"
      full
      header={
        <ErrorBoundary>
          <IntakeHeader previousPage={previousPage} baseUrl={baseUrl} />
        </ErrorBoundary>
      }
    >
      {error ? (
        <div className="text-red-500">{error}</div>
      ) : loading ? (
        <div className="mt-10">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="max-w-full sm:max-w-5xl py-4 mx-auto px-3 sm:px-0">
          {isModalOpen && (
            <Modal
              closeModal={() => setIsModalOpen(false)}
              widthClass={"max-w-2xl"}
              preventBodyScroll
            >
              <StiTreatmentModal
                handleClose={() => setIsModalOpen(false)}
                testHash={consultIntake?.consult.test_hash}
              />
            </Modal>
          )}
          {consultAlreadySubmitted &&
            currentPage !== "done/" &&
            !allowReupload && (
              <div className="flex">
                <div className="mx-auto bg-coral p-2 px-3 font-medium rounded-md mt-6">
                  These answers have been submitted.
                  <br />
                  Any changes you make will not be saved.
                </div>
              </div>
            )}
          <Routes>
            {/* consult intake info + form pages */}
            <Route
              path="/intro/"
              element={
                <IntakeIntro
                  consultIntake={consultIntake}
                  consult={consult}
                  submitPage={proceedPastIntroPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/demographics/"
              element={
                <DemographicsQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/medical-questions/"
              element={
                <PregnancyQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/treatment/medications/"
              element={
                <MedicationQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/treatment/general/"
              element={
                <GeneralQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/more-treatments"
              element={
                <SymptomsUpsell
                  getNextPageFullUrl={getNextPageFullUrl}
                  consultIntake={consultIntake}
                  source="vaginitis-intake"
                />
              }
            />
            <Route
              path="/identity-verification/info/"
              element={
                <IdentityVerificationInfo
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/identity-verification/photo/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/treatment/shipping/"
              element={
                <ShippingQuestions
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                />
              }
            />
            <Route
              path="/done/"
              element={<IntakeDone consultIntake={consultIntake} />}
            />
            <Route
              path="/exit/"
              element={<IntakeExit consultIntake={consultIntake} />}
            />
            <Route
              path="/identity-verification/redo/"
              element={
                <IdentityVerificationInfo
                  consultIntake={consultIntake}
                  submitPage={submitCurrentPage}
                  loading={isSubmitting}
                  redo={true}
                />
              }
            />
            <Route
              path="/identity-verification/reupload/"
              element={
                <IdPhotoUpload
                  consultIntake={consultIntake}
                  submitPage={submitConsultIntake}
                  updateConsultIntake={updateConsultIntake}
                  loading={isSubmitting}
                  redo={true}
                />
              }
            />

            {/* redirect on load */}
            <Route
              path="/"
              element={() => {
                return <Navigate to="intro/" replace />;
              }}
            />
          </Routes>
        </div>
      )}
    </Layout>
  );
};

export default ConsultIntake;

import { useLocation, useNavigate } from "react-router-dom";
import { Consult } from "../../types/care";
import refillsImage from "../common/images/refills.png";
import {
  sendVisitedRefillsFromHomePage,
  sendVisitedRefillsFromCarePage,
} from "../../utils/analytics/customEventTracking";

export const RefillsCard = ({
  consult,
  isCarePage,
}: {
  consult: Consult | null;
  isCarePage?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`${
        isCarePage
          ? `border rounded-md bg-evvy-highlighter-mint-dull`
          : `bg-white rounded-2xl`
      } p-6 sm:p-8 `}
    >
      <h3 className="m-0 mb-4">Stay Stocked.</h3>

      <div className={isCarePage ? "b1 medium mb-2" : `b2 medium`}>
        Seamlessly refill any treatments you're getting low on.
      </div>
      <img
        src={refillsImage}
        loading="lazy"
        alt="Evvy Refills"
        className={`${
          isCarePage ? "rounded-md" : "rounded-2xl"
        } mt-4 h-44 w-full object-cover mb-4`}
      />
      <button
        className="w-full bg-white border border-black hover:bg-evvy-black hover:text-white mt-auto p-6 font-semibold tracking-wider focus:outline-none"
        onClick={() => {
          isCarePage
            ? sendVisitedRefillsFromCarePage({ consultId: consult?.uid })
            : sendVisitedRefillsFromHomePage({ consultId: consult?.uid });
          navigate(`/care/consults/${consult?.uid}/refills/`, {
            state: { from: location.pathname },
          });
        }}
      >
        View Refills →
      </button>
    </div>
  );
};

import { useState, useEffect, useCallback } from "react";
import { fetchLatestOrder } from "../services/order";
import { Order } from "../types/orders";

export const useLatestOrder = () => {
  const [data, setData] = useState<Order | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const fetchLatest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchLatestOrder();
      setData(response[0]);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchLatest();
  }, [fetchLatest, refetch]);

  const refetchLatestOrder = () => {
    setRefetch((prev) => !prev);
  };

  return {
    latestOrder: data,
    error,
    isLoading,
    refetchLatestOrder,
  };
};

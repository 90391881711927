import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components
import Layout from "../../components/layout";
import PastTreatmentCard from "../../components/care/pastTreatmentCard";
import LoadingSpinner from "../../components/common/loadingSpinner";

// lib
import {
  sendCareViewPastConsultationsPage,
  eventNames,
} from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { testsService } from "../../services/tests";
import STITreatmentCard from "../../components/care/stiTreatmentCard";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";

const PastTreatments = ({ completedConsults }) => {
  const PER_PAGE = 3;
  const numPages =
    parseInt(completedConsults?.length / PER_PAGE) +
    (completedConsults?.length % PER_PAGE ? 1 : 0);
  const [currentPage, setCurrentPage] = useState(1);
  const [careEligible, setCareEligible] = useState(null);
  const [loading, setLoading] = useState(false);

  const consultsToShow = completedConsults.slice(
    (currentPage - 1) * PER_PAGE,
    currentPage * PER_PAGE
  );

  const currentUser = useLoggedInUser();

  useEffect(() => {
    fetchLatestReadyTest((latestTest) => {
      sendCareViewPastConsultationsPage({
        numPastConsultations: completedConsults?.length,
        careEligible: latestTest?.eligible_for_care,
      });
    });
  }, []);

  const isCareEnabled =
    currentUser && currentUser.results && currentUser.results.has_ready_test;

  /* Actions */
  // fetch user's latest ready test
  const fetchLatestReadyTest = (callback) => {
    setLoading(true);
    testsService.fetchLatestReadyTest(
      (response) => {
        setCareEligible(response.data.eligible_for_care);
        if (callback) {
          callback(response.data);
        }
        setLoading(false);
      },
      (error, response) => {
        console.error(response);
        setLoading(false);
      }
    );
  };

  return (
    <Layout title="Care | Past Treatments" padded={false}>
      <Link
        onClick={analyticsClickHandler({
          eventName: eventNames.PAST_CONSULTATIONS_CLICKED_BACK,
          eventArgs: {
            numPastConsultations: completedConsults?.length,
            careEligible,
          },
        })}
        to={isCareEnabled ? "/care" : "/tests"}
      >
        {/* border  wrapper div*/}
        <button
          className={`ml-4 p-1.5 rounded-full border-2 border-evvy-black text-evvy-black group flex items-center pointer-events-auto`}
          aria-label="Back to care"
        >
          <span className="px-2 h-8 inline uppercase tracking-wider font-semibold flex items-center">
            <span className="pr-2">
              <h4 className="font-bold leading-6 margin-0 inline">←</h4>
            </span>{" "}
            Back to {isCareEnabled ? "Care" : "Home"}
          </span>
        </button>
      </Link>
      <div className="max-w-plan-column-md items-center mx-auto px-4 pb-10 pt-4 sm:pb-28">
        <div className="justify-content mt-7">
          <h2>Past Treatments</h2>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div id="consults-list">
            {consultsToShow.map((consult, index) =>
              consult.type === "vaginitis" || consult.type === "ungated-rx" ? (
                <PastTreatmentCard
                  key={index}
                  consult={consult}
                  index={index}
                  numConsults={completedConsults?.length}
                  currentlyCareEligible={careEligible}
                />
              ) : (
                <STITreatmentCard
                  key={index}
                  consult={consult}
                  index={index}
                  numConsults={completedConsults?.length}
                />
              )
            )}
          </div>
        )}
        {numPages > 1 ? (
          <div className="p-4 sm:p-8 flex items-center border-b border-r border-l border-gray-300 rounded-b-lg">
            <div
              className="ml-auto border-2 rounded-full p-2 cursor-pointer"
              disabled={currentPage <= 1}
              onClick={analyticsClickHandler({
                eventName: eventNames.PAST_CONSULTATIONS_CLICKED_ARROW_NAV,
                eventArgs: {
                  numPastConsultations: completedConsults?.length,
                  careEligible,
                  arrowDirection: "left",
                },
                clickHandler: () => {
                  setCurrentPage(currentPage - 1);
                  document.getElementById("consults-list").scrollIntoView({
                    behavior: "smooth",
                  });
                },
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </div>
            <div className="uppercase semibold mx-4 tracking-wider">
              Page {currentPage} of {numPages}
            </div>
            <div
              className="mr-auto border-2 rounded-full p-2 cursor-pointer"
              disabled={currentPage >= numPages}
              onClick={analyticsClickHandler({
                eventName: eventNames.PAST_CONSULTATIONS_CLICKED_ARROW_NAV,
                eventArgs: {
                  numPastConsultations: completedConsults?.length,
                  careEligible,
                  arrowDirection: "right",
                },
                clickHandler: () => {
                  setCurrentPage(currentPage + 1);
                  document.getElementById("consults-list").scrollIntoView({
                    behavior: "smooth",
                  });
                },
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
};

export default PastTreatments;

import {
  GENERAL_COACHING_CALL_LINK,
  PDP_URL,
  EVVY_MEMBERSHIP_CHECKOUT_LINK,
} from "../constants";

const links = {
  EXPERT: GENERAL_COACHING_CALL_LINK,
  MEMBER: `${EVVY_MEMBERSHIP_CHECKOUT_LINK}&utm_source=myevvy&utm_medium=myplan&utm_campaign=howtousesection`,
  SUPPORT: "mailto:support@evvy.com",
  REVIEW: `${PDP_URL}?monto_review_form_open=1`,
  COMMUNITY: "https://www.evvy.com/supportgroup",
  MICROBIOME: "https://www.evvy.com/blog/meet-your-microbiome",
};

const modalTypes = {
  FEEDBACK: "_feedback",
  NEXT_TEST: "_nexttest",
  RECOMMENDATION: "_recommendation",
  LEARN_MORE: "_learnmore",
  RESEARCH: "_research",
  LEAVE_REVIEW: "_leavereview",
};

// feedback modal copy
const feedbackCopy = {
  TITLE: "We'd love to hear from you",
  CAPTION: (
    <div>
      <p>
        We're sorry to hear your plan wasn't useful — can you tell us more about
        why you feel that way?
      </p>
      <p>
        {" "}
        Please note this form is for product feedback only. For anything else,
        please reach out to <strong>support@evvy.com.</strong>
      </p>
    </div>
  ),
  SUBMIT_TITLE: "Thanks for letting us know!",
  SUBMIT_CAPTION: (
    <div>
      <p>
        We deeply care about each of our users, and your feedback helps us
        improve Evvy for the whole community.
      </p>
      <p>
        {" "}
        If you need help resolving an issue, please reach out to{" "}
        <strong>support@evvy.com</strong>
      </p>
    </div>
  ),
  SUBMIT_ERROR_CAPTION:
    "Sorry — your feedback could not be sent at this time! Please try again later.",
};

const feedbackValidationCopy = {
  LENGTH: "Feeback must be longer than 3 characters",
};

// leave review modal copy
const reviewCopy = {
  TITLE: "Thanks for letting us know!",
  CAPTION:
    "We're in this together - and sharing your experience will help other women navigate their health journeys. We'd truly appreciate if you took a moment to share your experience as a review!",
};

export { links, modalTypes, feedbackCopy, feedbackValidationCopy, reviewCopy };

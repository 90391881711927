import { useParams } from "react-router-dom";
import Layout from "../../../components/layout";
import { BackButton } from "../../../components/common/backButton";
import { useTreatment } from "../../../hooks/care/useTreatment";
import LoadingSpinner from "../../../components/common/loadingSpinner";
import {
  getPurposeTagText,
  getTreatmentImageFromFormFactor,
  getTreatmentPurposeTagColor,
} from "../../../components/care/utils";
import { TreatmentDescriptionTag } from "../../../components/care/aLaCare/treatmentDescription";
import RectangularButton from "../../../components/common/rectangularButton";
import { TreatmentFormFactor } from "../../../types/care";
import { TreatmentResearchRating } from "../../../components/care/treatmentResearchRating";
import classNames from "classnames";
import { EvvyMarkdown } from "../../../components/common/evvyMarkdown";
import { useCart } from "../../../contexts/aLaCareCartContext";
import { useLatestReadyTest } from "../../../hooks/test/useLatestReadyTest";
import { cn } from "../../../utils/cn";
import { useProblemsSummary } from "../../../hooks/care/useProblemsSummary";
import { useALaCareRecommendations } from "../../../hooks/care/useALaCareRecommendations";
import { useEffect, useMemo } from "react";
import { sendTreatmentPDPViewedPage } from "../../../utils/analytics/customEventTracking";
import { usePanelCart } from "../../../hooks/cart/usePanelCart";

const FORM_FACTOR_TO_DISPLAY_TEXT = {
  pill: "Oral capsule",
  cream: "Topical ointment",
  "applicator-cream": "Vulvar topical ointment",
  suppository: "Vaginal suppository",
};

const ANTIBIOTIC_REASON_FOR_PRESCRIBING = `Antibiotics are commonly used to treat bacterial infections in the vagina.
But it's important to choose the right antibiotics based on your specific microbiome
and a variety of holistic factors.|One factor to consider is the specific disruptive
bacteria that are present in the microbiome, as different antibiotics work best against
different microbes.|Another factor to consider is protecting the protective bacteria,
as some antibiotics are more likely to kill off protective bacteria in addition to pathogens.
A provider will determine which antiboitic is most likely to help your unique microbiome.`;

const ANTIFUNGAL_REASON_FOR_PRESCRIBING = `Antifungals are commonly used to treat vaginal
yeast infections caused by an overgrowth of yeast like *Candida* in your microbiome.
Antifungals work by targeting and stopping the growth of yeast that may cause symptoms.
A provider will determine which antifungal is most likely to help your unique microbiome.`;

const TREATMENT_SECTION_TITLE_TEXT_STYLE = "t2 semibold text-evvy-pine";
const TREATMENT_SECTION_CONTENT_TEXT_STYLE = "b1 regular";

const getTreatmentFormFactorDisplayText = (
  formFactor?: TreatmentFormFactor
) => {
  return formFactor ? FORM_FACTOR_TO_DISPLAY_TEXT[formFactor] : "";
};

export const TreatmentPDP = () => {
  const { treatmentSlug } = useParams();
  const { treatment } = useTreatment(treatmentSlug);
  const { test } = useLatestReadyTest();
  const {
    addToCart: addToCartPanel,
    setShowCart,
    getCartItemByTreatmentSlug,
    baseCartAnalyticsArgs,
  } = usePanelCart();
  const { state: cartState } = useCart();
  const alreadyPaid = test?.latest_vaginitis_consult?.consult_paid;
  // page is disabled if they already paid or if test is not eligible for a la care
  const isDisabled = alreadyPaid || !test?.eligible_for_a_la_care;

  // just loaded for analytics purposes
  const { testSummary } = useProblemsSummary(test?.hash);
  const { aLaCareRecommendations } = useALaCareRecommendations(test?.hash);

  const treatmentImage = getTreatmentImageFromFormFactor(
    treatment?.form_factor
  );
  const purposeTagBGColor = getTreatmentPurposeTagColor(treatment?.purpose_tag);
  const isAntibioticOrAntifungal =
    treatment?.type === "antibiotic" || treatment?.type === "antifungal";

  const inCart =
    treatmentSlug && getCartItemByTreatmentSlug(treatmentSlug) ? true : false;

  // analytics
  useEffect(() => {
    // send analytics event, only fire on first render
    if (treatment && test && testSummary && aLaCareRecommendations) {
      sendTreatmentPDPViewedPage({
        inCart,
        treatmentSlug: treatment?.slug,
        microbiomeState: testSummary?.problems_summary.microbiome_state,
        conditions: testSummary?.problems_summary.conditions,
        symptoms: testSummary?.problems_summary.symptoms,
        ...baseCartAnalyticsArgs,
        recommendedALaCareTreatments:
          aLaCareRecommendations?.a_la_care.recommended_treatments.map(
            (treatment) => treatment.slug
          ),
      });
    }
  }, [
    treatment,
    test,
    testSummary,
    aLaCareRecommendations,
    inCart,
    cartState?.items,
    baseCartAnalyticsArgs,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddToCartClick = () => {
    if (treatment) {
      if (inCart) {
        setShowCart(true);
      } else {
        addToCartPanel([
          {
            item_type: "individual-treatment",
            quantity: 1,
            treatment_product_id: treatment.id,
            treatment_product: treatment,
          },
        ]);
      }
    }
  };

  const reasonForPrescribing = useMemo(() => {
    if (treatment?.type === "antibiotic") {
      return ANTIBIOTIC_REASON_FOR_PRESCRIBING;
    } else if (treatment?.type === "antifungal") {
      return ANTIFUNGAL_REASON_FOR_PRESCRIBING;
    } else {
      return treatment?.reason_for_prescribing || "";
    }
  }, [treatment]);

  if (!treatmentSlug) {
    return null;
  }

  return (
    <>
      <Layout
        title={"Care | Treatment PDP"}
        bgClass="bg-evvy-white"
        full
        centered
      >
        <div className={`max-w-7xl mx-auto px-4 sm:px-0 w-full pb-10 sm:pb-20`}>
          <div className="flex justify-start mx-auto">
            <BackButton
              backLink={"/care/"}
              backText="View all products"
              showLabelWithoutHover
            />
          </div>
          {/* add loading state (loading spinner) */}
          {!treatment ? (
            <div className="min-h-screen">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 sm:px-16 py-4 sm:space-x-10">
              <div className="sm:sticky sm:top-7 relative h-[284px] sm:h-[402px] sm:max-w-[502px] py-12 sm:py-0 bg-evvy-dark-beige rounded-xl flex">
                <img
                  src={treatmentImage}
                  loading="lazy"
                  alt="Treatment packaging"
                  className={cn("object-cover mx-auto my-auto", {
                    "h-[200px]":
                      treatment?.form_factor !== "applicator-cream" &&
                      treatment?.form_factor !== "cream",
                    "h-64 sm:h-96 bottom-0 absolute right-0 left-0":
                      treatment?.form_factor === "applicator-cream" ||
                      treatment?.form_factor === "cream",
                  })}
                />
                {treatment.purpose_tag && (
                  <div
                    className={`absolute bottom-2 sm:bottom-auto sm:top-2 left-2 rounded-md ${purposeTagBGColor} p-2`}
                  >
                    <span className="text-sm uppercase tracking-wider semibold">
                      {getPurposeTagText(treatment.purpose_tag)}
                    </span>
                  </div>
                )}
              </div>
              <div className="space-y-10">
                <>
                  <div className="flex justify-between">
                    <div>
                      <div className="t1">{treatment.preview_title}</div>
                      <div className="b1 regular">
                        {isAntibioticOrAntifungal
                          ? "Determined by provider"
                          : treatment.quantity_text}
                      </div>
                    </div>
                    <div className="t1">{`$${treatment.ecomm_product.price}`}</div>
                  </div>
                  <hr className="my-5" />
                  <div className="space-y-4 sm:space-y-5">
                    {!isAntibioticOrAntifungal && (
                      <div className="b1 regular">{treatment.description}</div>
                    )}
                    <TreatmentDescriptionTag treatment={treatment} />
                    <div className="space-y-2.5">
                      <RectangularButton
                        text={inCart ? "Added" : "Add"}
                        textColorClass="text-evvy-black"
                        verticalPadding="py-5"
                        fullWidth
                        onClick={handleAddToCartClick}
                        disabled={isDisabled}
                        icon={inCart ? "checkmark" : ""}
                      />
                      <div className="b3 medium">
                        Prescriptions are determined at the sole discretion of
                        an Evvy-affiliated provider. Please note: once you check
                        out, you will not be able to request additional
                        treatments until after you re-test.
                      </div>
                    </div>
                  </div>
                </>
                <div className="space-y-5 sm:space-y-7">
                  {!isAntibioticOrAntifungal && (
                    <div className="space-y-2.5">
                      <div className={TREATMENT_SECTION_TITLE_TEXT_STYLE}>
                        Type
                      </div>
                      <div className={TREATMENT_SECTION_CONTENT_TEXT_STYLE}>
                        {getTreatmentFormFactorDisplayText(
                          treatment.form_factor
                        )}
                      </div>
                    </div>
                  )}
                  <div className="space-y-2.5">
                    <div className={TREATMENT_SECTION_TITLE_TEXT_STYLE}>
                      Why should I use this treatment?
                    </div>
                    <div className={TREATMENT_SECTION_CONTENT_TEXT_STYLE}>
                      {/* split reason_for_prescribing by "|" and map to bullets */}
                      <ol className="space-y-4 list-none ml-0 sm:ml-5 sm:list-disc">
                        {reasonForPrescribing.split("|").map((reason, i) => (
                          // add a bottom border if it's not the last item
                          <li
                            key={`reason-${i}`}
                            className={classNames({
                              "border-t border-evvy-black-dull border-dashed sm:border-t-0 pt-4 sm:pt-0":
                                i !== 0,
                            })}
                          >
                            <EvvyMarkdown>{reason}</EvvyMarkdown>
                          </li>
                        ))}
                      </ol>
                      {treatment.pre_purchase_disclaimer && (
                        <EvvyMarkdown newlineOnPipe={true}>
                          {treatment.pre_purchase_disclaimer}
                        </EvvyMarkdown>
                      )}
                    </div>
                  </div>
                  {!isAntibioticOrAntifungal && (
                    <div className="space-y-2.5">
                      <div className={TREATMENT_SECTION_TITLE_TEXT_STYLE}>
                        Ingredients
                      </div>
                      <div className={TREATMENT_SECTION_CONTENT_TEXT_STYLE}>
                        {treatment.ingredients}
                      </div>
                    </div>
                  )}
                  <div className="space-y-2.5">
                    <div className={TREATMENT_SECTION_TITLE_TEXT_STYLE}>
                      Research
                    </div>
                    <TreatmentResearchRating treatment={treatment} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

import Layout from "../../../../components/layout";
import StickyHeader from "../../../../components/common/stickyHeader";
import { ResultsLoadingAnimation } from "../../../results_v2/viz";
import { useContext, useEffect, useState } from "react";
import { careService } from "../../../../services/care";
import { useLatestOrder } from "../../../../hooks/useLatestOrder";
import React from "react";
import BlueRectangularButton from "../../../../components/common/blueRectangularButton";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  sendConsultIntakeLoadingScreenClickedBack,
  sendConsultIntakeLoadingTimeoutClickedCTA,
  sendConsultIntakeViewedLoading,
  sendConsultIntakeViewedLoadingTimeout,
} from "../../../../utils/analytics/customEventTracking";
import { ConsultsContext } from "../../../../contexts/consultsContext";

const CREATING_CONSULT_COPY = "One more step to complete your order...";

const LoadingConsult: React.FC<{ source?: string }> = ({
  source,
}: {
  source?: string;
}) => {
  const { refetchConsults } = useContext(ConsultsContext);
  const { latestOrder, refetchLatestOrder } = useLatestOrder();
  const urlParams = new URLSearchParams(
    window.location.search.replace(/\+/g, "%2B")
  );
  const checkoutId = urlParams.get("checkoutId");
  const orderEmail = decodeURIComponent(urlParams.get("email") || "");
  const [showStaticRedirect, setShowStaticRedirect] = useState(false);
  const [refetched, setRefetched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    sendConsultIntakeViewedLoading({
      checkoutId,
      checkoutSource: source,
    });

    // Poll for the latest order every 5 seconds
    setInterval(() => {
      if (!showStaticRedirect) {
        refetchLatestOrder();
        setRefetched(true);
      }
    }, 5000);

    // If it makes it to 20 seconds and they haven't been redirected, show the static message
    setTimeout(() => {
      setShowStaticRedirect(true);
      sendConsultIntakeViewedLoadingTimeout({
        checkoutId,
        checkoutSource: source,
      });
    }, 20000);
  }, []);

  // If there has been a consult created for this order already, then redirect to that intake
  useEffect(() => {
    if (checkoutId && orderEmail && latestOrder && refetched) {
      if (latestOrder?.checkout_id == checkoutId) {
        careService.getAssociatedConsultForOrder(
          checkoutId,
          (response: AxiosResponse) => {
            const consultId = response.data?.consult_uid;
            if (consultId) {
              refetchConsults();
              navigate(`/care/rx/consults/${consultId}/intake/consent/`);
            }
          }
        );
      }
    }
  }, [latestOrder, refetched]);

  return (
    <Layout
      title={`Care | Creating Consult`}
      bgClass="bg-evvy-cream"
      full
      centered
      header={
        <StickyHeader
          backLink="/tests/"
          className="bg-evvy-cream"
          onClickBack={() => {
            sendConsultIntakeLoadingScreenClickedBack({
              checkoutId,
              checkoutSource: source,
            });
          }}
        />
      }
    >
      {!showStaticRedirect ? (
        <div className="w-full sm:w-1/2">
          <div className="mt-20 sm:-mt-20" />
          <ResultsLoadingAnimation />
          <div className="mt-20 sm:-mt-20 mb-20 medium text-lg md:text-xl text-center px-4">
            {CREATING_CONSULT_COPY}
          </div>
        </div>
      ) : (
        <div className="mt-20 w-full sm:max-w-1/2 p-8">
          <div className="mb-10 medium text-lg md:text-xl text-center px-4">
            Your order is still processing. You will receive an order
            confirmation email shortly. Please follow the steps in the email to
            complete your intake form in order to receive your order.
          </div>
          <BlueRectangularButton
            fullWidth
            text="Go to my tests ->"
            onClick={() => {
              sendConsultIntakeLoadingTimeoutClickedCTA({
                checkoutId,
                checkoutSource: source,
              });
              navigate("/tests/");
            }}
          />
        </div>
      )}
    </Layout>
  );
};

export default LoadingConsult;

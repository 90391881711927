import {
  trackCustomEvent,
  DESTINATION_FULLSTORY,
  DESTINATION_KLAVIYO,
} from "./tracking";

// define Fullstory custom event names
const eventNames = {
  CLICKED_LEARN_MORE: "My Plan - Clicked Learn More",
  CLICKED_RESEARCH_STATUS: "My Plan - Clicked Research Status",
  CLICKED_SOURCE: "My Plan - Clicked Source",
  CLICKED_RECOMMENDATION: "My Plan - Clicked Recommendation",
  CLICKED_CIRCLE_NAV: "My Plan - Clicked Circle Nav",
  CLICKED_RETEST: "My Plan - Clicked Retest",
  CLICKED_EMAIL_US: "My Plan - Clicked Email Us",
  CLICKED_TALK_EXPERT: "My Plan - Clicked Talk to Expert",
  CLICKED_JOIN_COMMUNITY: "My Plan - Clicked Join Community",
  CLICKED_PLAN_FEEDBACK: "My Plan - Clicked Feedback Option",
  VIEWED_PLAN_PAGE: "My Plan - View Page",
  PLAN_CLICKED_START_CONSULT: "My Plan - Clicked Get Treated",
  PLAN_CLICKED_VIEW_STEPS: "My Plan - Clicked View Steps",
  PLAN_CLICKED_HIDE_TREATMENT_GUIDE: "My Plan - Clicked Hide Steps",
  PLAN_CLICKED_PRICING_MORE_INFO: "Clicked Pricing More Info",
  PLAN_CLICKED_LEARN_MORE_CARE: "My Plan - Clicked Learn More About Care",
  PLAN_CLICKED_L1_RESULTS_LINK: "My Plan - Clicked Initial Results Link",
  HEALTH_HISTORY_FORM_VIEW_SECTION: "Health History Form - View Section",
  HEALTH_HISTORY_FORM_VIEW_PAGE: "Health History Form - View Page",
  HEALTH_HISTORY_FORM_CLICK_BACK: "Health History Form - Clicked Back",
  HEALTH_HISTORY_FORM_VIEW_EDUCATIONAL_MOMENT:
    "Health History Form - View Educational Moment",

  HEALTH_HISTORY_FORM_ACCEPT_SAMPLE_CRITERIA:
    "Health History Form - Accept Sample Criteria",
  HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN:
    "Health History Form - Clicked Sample Taken",
  HEALTH_HISTORY_FORM_PROVIDER_AUTHORIZATION:
    "Health History - Clicked Provider Results Authorization",
  // L1 Upgrade
  HEALTH_HISTORY_FORM_UPGRADE_CLICKED_CTA:
    "Health History Form - Clicked Upgrade CTA",
  HEALTH_HISTROY_CLICKED_SEE_EXPANDED_PCR_LIST_POPUP:
    "Health History - Clicked See Expanded PCR List Popup",
  HEALTH_HISTORY_VIEWED_SYMPTOMS_UPSELL: "Health History Form - View Upsell",
  HEALTH_HISTORY_CLICKED_SYMPTOMS_UPSELL_CTA:
    "Health History Form - Clicked CTA on Upsell",
  HEALTH_HISTORY_CLICKED_ADD_CTA_ON_SYMPTOMS_UPSELL:
    "Health History Form - Clicked Add CTA on Upsell",
  CONSULT_INTAKE_VIEWED_LOADING_PAGE:
    "Consultation Intake - View Symptoms Loading Page",
  CONSULT_INTAKE_VIEWED_LOADING_TIMEOUT:
    "Consultation Intake - View Symptoms Loading Timeout",
  CONSULT_INTAKE_LOADING_TIMEOUT_CLICKED_CTA:
    "Consultation Intake - Loading Timeout Clicked CTA",
  CONSULT_INTAKE_LOADING_CLICKED_BACK:
    "Consultation Intake - Loading Clicked Back",
  CLICKED_TOP_NAV: "Internal Product - Clicked Top Nav Item",
  VIEWED_COMPARE: "Compare - View Page",
  VIEWED_COMPARE_INFO_MODAL: "Compare - View Pop Up",
  CLICKED_MORE_INFO_COMPARE: "Compare - Clicked More Info",
  CLICKED_UPGRADE_NOW: "Compare - Clicked Upgrade Now",
  // Account
  VIEWED_MY_ACCOUNT: "My Account - View Page",
  ACCOUNT_CREATION_VIEWED_PAGE: "Account Creation - View Page",
  ACCOUNT_CREATION_CLICKED_CTA: "Account Creation - Clicked CTA",
  ACCOUNT_CLICKED_VIEW_EXPANDED_PCR_DETAIL:
    "My Account - Clicked View Expanded PCR Modal",
  ACCOUNT_CLICKED_DOWNGRADE: "My Account - Clicked Downgrade Membership",
  ACCOUNT_CLICKED_UPGRADE: "My Account - Clicked Upgrade Membership",
  CLICKED_MANAGE_MEMBERSHIP: "My Account - Clicked Manage Membership",
  ACCOUNT_EDIT_INFORMATION: "My Account - Edit Information",
  CLICKED_LOGOUT: "My Account - Clicked Logout",
  ACCOUNT_CLICKED_CANCEL: "My Account - Clicked Cancel Membership",
  ACCOUNT_SELECTED_CANCEL_REASON: "My Account - Selected Cancel Reason",
  ACCOUNT_SUBMIT_CANCELLATION: "My Account - Submitted Cancellation",
  VIEWED_MY_TESTS: "My Tests - View Page",
  CLICKED_ACTIVATE: "My Tests - Clicked Activate",
  ACTIVATION_MODAL_CLICKED_ACTIVATE: "Activation Modal - Confirm Activation",
  CLICKED_CANT_FIND_ID: "My Tests - Clicked Can't Find ID",
  CLICKED_TEST_CARD_CTA: "My Tests - Clicked CTA on Test Card",
  CLICKED_TEST_CARD_VIEW_CONTEXT:
    "My Tests - Clicked View Health Context on Test Card",
  CLICKED_TEST_CARD_SHOW_PROGRESS: "My Tests - Clicked Show Test Progress",
  CLICKED_TEST_SAMPLE_TRACKING_LINK: "My Tests - Clicked Tracking Link",
  CLICKED_TESTS_FOR_YOU_CTA: "My Tests - Click For You CTA",
  TESTS_CLICKED_JOIN_COMMUNITY: "My Tests - Clicked Join Community",
  TESTS_CLICKED_BLOG_LINK: "My Tests - Clicked Blog Link",
  VIEWED_CHECKLIST: "My Tests - View Checklist",
  CHECKLIST_CLICKED_CTA: "My Tests - Clicked Checklist CTA",
  CHECKLIST_CLICKED_LINK: "My Tests - Clicked Checklist Link",
  VIEWED_MY_RESULTS: "My Results - View Page",
  CLICKED_RESULTS_DOWNLOAD: "My Results - Clicked Download Results",
  CLICKED_RESULTS_VIEW_PLAN: "My Results - Clicked View Plan",
  CLICKED_RESULTS_VIEW_CARE: "My Results - Clicked Get Care",
  EXPANDED_RESULTS_CARD: "My Results - Expand Card",
  RESULTS_CLICKED_LEGEND: "My Results - Clicked Table of Contents",
  RESULTS_CLICKED_BACK: "My Results - Clicked Back",
  RESULTS_CLICKED_BLOG_LINK: "My Results - Clicked Blog Link",
  RESULTS_CLICKED_FILTER_TAGS: "My Results - Clicked Filter Tags",
  RESULTS_CLICKED_CST_ASSOCIATION: "My Results - Clicked CST Association",
  RESULTS_CLICKED_ASSOCIATION_DISCLAIMER:
    "My Results - Clicked Association Disclaimer",
  RESULTS_CLICKED_BACTERIA_ASSOCIATION:
    "My Results - Clicked Bacteria Association",
  RESULTS_CHANGED_TEST_DATE: "My Results - Changed Test Date",
  RESULTS_EXPAND_ALL_MICROBES: "My Results - Expand All Microbes",
  RESULTS_EXPAND_MICROBE_CATEGORY_BREAKDOWN:
    "My Results - Expand Microbe Category Breakdown",
  RESULTS_EXPAND_HEALTH_HISTORY: "My Results - Expand Health History",
  LOGIN_VIEW_PAGE: "Login Page - View Page",
  LOGIN_CLICKED_CTA: "Login Page - Clicked CTA",
  LOGGED_IN: "Login Page - Logged In",

  VERIFY_EMAIL_VIEW_PAGE: "Verify Email - View Page",
  VERIFY_EMAIL_CLICKED_CTA: "Verify Email - Clicked CTA",
  RESEARCH_CONSENT_VIEW_PAGE: "Research Consent - View Page",
  RESEARCH_CONSENT_CLICKED_CTA: "Research Consent - Clicked CTA",
  // Treatment Plan
  TREATMENT_VIEW_PAGE: "My Treatment - View Page",
  TREATMENT_EXPAND_PAGE_SECTION: "My Treatment - Expand Page Section",
  TREATMENT_EXPAND_PRESCRIPTION:
    "My Treatment - Expand Prescription Information",
  TREATMENT_ASK_QUESTION: "My Treatment - Clicked Ask a Question Button",
  TREATMENT_CLICKED_FAQ: "My Treatment - Clicked FAQ Button",
  TREATMENT_CLICKED_COACHING: "My Treatment - Clicked Coaching Button",
  TREATMENT_CLICKED_RETEST: "My Treatment - Clicked Retest Button",
  TREATMENT_SELECT_START_DATE: "My Treatment - Select Treatment Start Date",
  TREATMENT_EDIT_START_DATE: "My Treatment - Edit Treatment Start Date",
  TREATMENT_CLICKED_DOWNLOAD_PDF: "My Treatment - Clicked Download PDF",
  TREATMENT_CLICKED_ADD_CALENDAR: "My Treatment - Clicked Add to Calendar",
  TREATMENT_CLICKED_EDIT_CALENDAR_TREATMENT:
    "My Treatment - Clicked Edit Treatment",
  TREATMENT_EDIT_TREATMENT_SELECT_TYPE:
    "My Treatment - Edit Treatment Select Type",
  TREATMENT_EDIT_TREATMENT_SELECT_CADENCE_OPTION:
    "My Treatment - Edit Treatment Select Cadence Option",
  TREATMENT_EDIT_TREATMENT_CLICKED_NEXT:
    "My Treatment - Edit Treatment Clicked Next",
  TREATMENT_EDIT_TREATMENT_CLICKED_BACK:
    "My Treatment - Edit Treatment Clicked Back",
  TREATMENT_EDIT_TREATMENT_SELECT_REASON:
    "My Treatment - Edit Treatment Select Reason",
  TREATMENT_OPEN_PERIOD_MODAL: "My Treatment - Open Period Modal",
  TREATMENT_DELETE_PERIOD: "My Treatment - Delete Period",
  TREATMENT_SAVE_PERIOD: "My Treatment - Save Period",
  TREATMENT_CLICKED_POPUP_CTA: "My Treatment - Clicked Popup CTA",
  TREATMENT_CHANGE_WEEK: "My Treatment - Change Calendar Week",
  TREATMENT_RESET_CALENDAR: "My Treatment - Reset Calendar",
  TREATMENT_EXPAND_FAQ: "My Treatment - Expand FAQ",
  TREATMENT_SHOWED_INTERCOM_WIDGET: "My Treatment - Show Intercom Widget",
  // Consultation Intake
  CONSULT_INTAKE_VIEW_SECTION: "Consultation Intake - View Section",
  CONSULT_INTAKE_CLICKED_EXIT_CTA:
    "Consultation Intake - Clicked CTA on Exit Screen",
  CONSULT_INTAKE_CLICKED_ALT_PHARMACY:
    "Consultation Intake - Clicked Alt Pharmacy Email",
  CONSULT_INTAKE_VIEWED_UPSELL: "Consultation Intake - Viewed Upsell Page",
  CONSULT_INTAKE_CLICKED_UPSELL_CTA:
    "Consultation Intake - Clicked Add CTA on Upsell",
  CONSULT_INTAKE_CLICKED_CTA: "Consultation Intake - Clicked CTA on Upsell",
  // Care Page
  CARE_VIEW_PAGE: "Care - View Page",
  CARE_CLICKED_VIEW_PAST_CONSULTATIONS:
    "Care - Clicked View Past Consultations",
  CARE_CLICKED_A_LA_CARE_ONRAMP: "Care - Clicked A La Care On-ramp",
  CARE_CLICKED_TALK_TO_EXPERT: "Care - Clicked Talk to Expert",
  CARE_CLICKED_VIEW_COMMUNITY_RECS: "Care - Clicked View Community Recs",
  CARE_CLICKED_JOIN_COMMUNITY: "Care - Clicked Join Community",
  CARE_CLICKED_BLOG_ARTICLE: "Care - Clicked Blog Article",
  CARE_CLICKED_VIEW_INTAKE: "Care - Clicked View Provider Intake",
  CARE_CLICKED_VIEW_TREATMENT_PLAN: "Care - Clicked View Treatment Plan",
  CARE_CLICKED_SEE_ALL_ARTICLES: "Care - Clicked See All Articles",
  CARE_CLICKED_VIEW_PAST_MESSAGES: "Care - Clicked View Past Messages",
  CARE_CLICKED_MESSAGE_PROVIDER: "Care - Clicked Message Provider Now",
  CARE_CLICKED_VIEW_MESSAGE: "Care - Clicked View Message",
  CARE_EXPANDED_FAQ: "Care - Expand FAQ",
  CARE_ASK_QUESTION: "Care - Clicked Ask a Question",
  CARE_CLICKED_GET_CARE_FOOTER: "Care - Clicked Get Care Footer",
  CARE_CLICKED_VIEW_WHITE_PAPER: "Care - Clicked View White Paper",
  CARE_CLICKED_VIEW_PLAN: "Care - Clicked View Plan",
  CARE_CLICKED_TRACK_ORDER: "Care - Clicked Track Order",
  CARE_CLICKED_VIEW_SAMPLE_PLAN: "Care - Clicked View Sample Treatment Plan",
  CARE_SELECTED_TREATMENT_OPTION: "Care - Selected Treatment Option",
  CARE_CLICKED_ADD_ALL_TO_CART: "Care - Clicked Add All to Cart",
  // Recommended Treatment Program
  RECOMMENDED_TREATMENT_VIEW_PAGE: "Recommended Treatment - View Page",
  RECOMMENDED_TREATMENT_VIEW_LOADING_PAGE:
    "Recommended Treatment - View Loading Page",
  RECOMMENDED_TREATMENT_CLICKED_BUY: "Recommended Treatment - Clicked Buy",
  RECOMMENDED_TREATMENT_EXPAND_PRESCRIPTION:
    "Recommended Treatment - Expand Prescription Information",
  // Sample Treatment Plan Page
  SAMPLE_PLAN_VIEW_PAGE: "Sample Treatment Plan - View Page",
  SAMPLE_PLAN_CLICKED_GET_CARE: "Sample Treatment Plan - Clicked Get Care",
  SAMPLE_PLAN_CLICKED_BACK: "Sample Treatment Plan - Clicked Back",
  SAMPLE_PLAN_VIEWED_SECTION: "Sample Treatment Plan - Viewed Section",
  // Past Consultations page
  PAST_CONSULTATIONS_VIEW_PAGE: "Past Consultations - View Page",
  PAST_CONSULTATIONS_CLICKED_BACK: "Past Consultations - Clicked Back to Care",
  PAST_CONSULTATIONS_CLICKED_VIEW_TREATMENT:
    "Past Consultations - Clicked View Treatment Plan",
  PAST_CONSULTATIONS_CLICKED_ARROW_NAV:
    "Past Consultations - Clicked Arrow Nav",
  // Messaging
  MESSAGING_VIEW_THREAD: "Messaging - View Message Thread",
  MESSAGING_CLICKED_BACK: "Messaging - Clicked Back to Care",
  MESSAGING_CLICKED_SUPPORT: "Messaging - Clicked Contact Evvy Support",
  MESSAGING_CLICKED_SUBMIT_MESSAGE: "Messaging - Clicked to Submit Message",
  MESSAGING_VIEW_MESSAGES_HOME: "Messaging - View Messages Home",
  MESSAGING_CLICKED_TEST_RESULTS: "Messaging - Clicked Test Results",
  MESSAGING_CLICKED_PROVIDER_INTAKE: "Messaging - Clicked Provider Intake",
  // Providers
  PROVIDER_REGISTRATION_VIEW_PAGE: "Provider Registration - View Page",
  PROVIDER_REGISTRATION_CLICKED_AGREE_TO_TERMS:
    "Provider Registration - Clicked Read and Agree Terms and Conditions",
  PROVIDER_REGISTRATION_CLICKED_VIEW_TERMS:
    "Provider Registration - Clicked Terms and Conditions Link",
  PROVIDER_REGISTRATION_CLICKED_CONTINUE_REGISTRATION:
    "Provider Registration - Clicked Continue Registration",
  PROVIDER_REGISTRATON_CLICKED_SIGN_IN:
    "Provider Registration - Clicked Sign In",
  PROVIDER_INTAKE_VIEW_PAGE: "Provider Intake - View Page",
  PROVIDER_INTAKE_CLICKED_CONTINUE: "Provider Intake - Clicked Continue",
  PROVIDER_ORDER_VIEW_PAGE: "Provider New Order - View Page",
  PROVIDER_RESOURCES_VIEW_PAGE: "Provider Resources - View Page",
  PROVIDER_TESTS_VIEW_PAGE: "Provider Tests - View Page",
  PROVIDER_HOME_CLICKED_JOIN_COMMUNITY:
    "Provider - Clicked Join Provider Community",
  PROVIDER_HOME_CLICKED_RESOURCE_LINK: "Provider Home - Clicked Resources Link",
  PROVIDER_RESOURCES_CLICKED_ORDER: "Provider Resources - Clicked Order",
  PROVIDER_HOME_CLICKED_ORDER: "Provider Home - Ordered Test",
  PROVIDER_HOME_TRACK_ORDER_FILTER:
    "Provider Tests - Clicked Track Order Filter",
  PROVIDER_HOME_CLICKED_DOWNLOAD_RESULTS:
    "Provider Tests - Clicked Download Results",
  PROVIDER_ORDER_CLICKED_VIEW_EXPANDED_PCR_MODAL:
    "Provider Order - Clicked View Expanded PCR Modal",
  REMOVE_ANTIBIOTICS_CLICKED: "Remove Antibiotics - Clicked Remove Antibiotics",
  COLLAPSIBLE_MENU_TOGGLED: "Common - Collapsible Menu Expanded/Collapsed",
  CARE_EXPANDED_WHAT_YOU_GET: "Care - Expanded What You Get with Evvy",
  CARE_EXPANDED_WHY_OUR_TREATMENTS_WORK:
    "Care - Expanded Why Our Treatments Work",
  CARE_CLICKED_THROUGH_ANTIBIOTICS_DISCLAIMER:
    "Care - Clicked Through Antibiotics Disclaimer",
  CARE_CLICKED_CTA_BUTTON: "Care - Clicked CTA Button",
  STI_INTAKE_CLICKED_CTA_BUTTON: "STI Treatment - Clicked CTA Button",
  STI_PAST_TREATMENT_CARD_CTA:
    "STI Treatment - Past Treatment Card CTA Clicked",
  VIEWED_ANNOUNCEMENT: "My Tests - Viewed Announcement",
  REFILL_VIEWED_CART_PAGE: "Refill - Viewed Page",
  REFILL_ADDED_TO_CART: "Refill - Added to Cart",
  REFILL_REMOVED_FROM_CART: "Refill - Clicked Remove",
  REFILL_CLICKED_RETEST: "Refill - Clicked Retest",
  REFILL_CLICKED_CHECKOUT: "Refill - Clicked Checkout",
  REFILL_VISITED_FROM_HOME_PAGE: "My Tests - Clicked Refill On-ramp",
  REFILL_VISITED_FROM_CARE_PAGE: "Care - Clicked Refill On-ramp",
  REFILL_VIEWED_PRESCRIPTION_DETAILS: "Refill - Viewed Prescription Details",
  // shop treatments
  SHOP_TREATMENTS_VIEWED_PAGE: "Shop Treatments - Viewed Page",
  SHOP_TREATMENTS_CLICKED_TREATMENT: "Shop Treatments - Clicked Treatment",
  SHOP_TREATMENTS_CLICKED_TREATMENT_PROGRAM_ONRAMP:
    "Shop Treatments - Clicked Treatment Program On-ramp",
  SHOP_TREATMENTS_CLICKED_RETEST_ONRAMP:
    "Shop Treatments - Clicked Retest On-ramp",
  SHOP_TREATMENTS_CLICKED_ADD_TO_CART: "Shop Treatments - Clicked Add to Cart",
  SHOP_TREATMENTS_CLICKED_REMOVE_FROM_CART:
    "Shop Treatments - Clicked Remove from Cart",
  TREATMENT_PDP_VIEWED_PAGE: "Treatment PDP - Viewed Page",
  TREATMENT_CART_CLICKED_CHECKOUT: "Cart - Clicked Checkout",
  TREATMENT_CART_VIEWED_CART: "Cart - Viewed Cart",
  TREATMENT_CART_CLICKED_VIEW_MORE_PRODUCTS:
    "Cart - Clicked View More Products",
  TREATMENT_CART_CLICKED_EMPTY_STATE_CTA: "Cart - Clicked Empty State CTA",
  TREATMENT_CART_CLICKED_CART_IN_NAV: "Cart - Clicked Cart Nav",
  TREATMENT_CART_CLICKED_REMOVE: "Cart - Clicked Remove from Cart",
  TREATMENT_CART_CLICKED_ADD_TO_CART: "Cart - Clicked Add to Cart",
};

// define Fullstory custom event properties
// custom event names are supposed to be suffixed by their data type
// https://help.fullstory.com/hc/en-us/articles/360020623234#Custom%20Event%20Name%20Requirements
const propertyNames = {
  PLAN_ITEM_SLUG: "cardSlug_str",
  PLAN_ITEM_GROUP: "cardGroup_str",
  PLAN_TYPE: "planType_str",
  PLAN_PROFILE_ID: "planProfileId_int",
  CARE_ELIGIBLE: "careEligible_bool",
  RESEARCH_STATUS: "researchStatus_str",
  CITATION_TITLE: "citationTitle_str",
  RECOMMENDATION_TITLE: "recommendationTitle_str",
  LOCATION: "location_str",
  IS_MEMBER: "isMember_bool",
  RESPONSE: "response_str",
  SECTION: "section_str",
  HH_PAGE: "page_str",
  SECTION_STATUS: "sectionStatus_str",
  SECTION_TITLE: "sectionTitle_str",
  TEST_ORDER: "testOrder_int",
  TEST_HASH: "testHash_str",
  TEST_STATUS: "testStatus_str",
  QUESTION_GROUP: "questionGroup_str",
  NAV_ITEM: "navItem_str",
  RESULTS_RELEASED: "resultsReleased_date",
  CTA_TEXT: "ctaText_str",
  CTA_TYPE: "ctaType_str",
  COMMUNITY_STATE_TYPE: "communityStateType_str",
  CARD_TYPE: "cardType_str",
  CHECKLIST_STATUS: "checklistStatus_str",
  STEP_NUMBER: "stepNumber_int",
  BACTERIA_NAME: "bacteriaName_str",
  BACTERIA_ID: "bacteriaId_int",
  MICROBE_CATEGORY: "microbeCategory_str",
  INFORMATION_TYPE: "informationType_str",
  PAGE_SECTION: "pageSection_str",
  ARTICLE_URL: "articleUrl_str",
  TAG_TITLE: "tagTitle_str",
  ASSOCIATION_TITLE: "associationTitle_str",
  CHART_NAME: "chartName_str",
  SIDE: "side_str",
  CONSULT_ID: "consultUID_str",
  CONSULT_STATUS: "consultationStatus_str",
  CONSULT_TYPE: "consultationType_str",
  CARE_PAGE_STATUS: "carePageStatus_str",
  CARE_PRICE: "carePrice_real",
  PAGE_STATUS: "pageStatus_str",
  PRESCRIPTION_SLUG: "prescriptionSlug_str",
  CALL_NAME: "callName_str",
  TREATMENT_START_DATE: "treatmentStartDate_date",
  NEW_TREAMTNET_START_DATE: "newTreatmentStartDate_date",
  ORIGINAL_TREATMENT_START_DATE: "originalTreatmentStartDate_date",
  CURRENT_WEEK: "currentWeek_int",
  VIEWED_WEEK: "viewedWeek_int",
  FAQ_QUESTION: "faqQuestion_str",
  EXIT_TYPE: "exitType_str",
  NUM_PAST_CONSULTATIONS: "numPastConsultations_int",
  ARROW_DIRECTION: "arrowDirection_str",
  NUM_USER_MESSAGES: "numUserMessages_int",
  NUM_PROVIDER_MESSAGES: "numProviderMessages_int",
  NUM_THREADS: "numThreads_int",
  THREAD_STATUS: "threadStatus_str",
  MESSAGE_STATUS: "messageStatus_str",
  PAGE_VERSION: "pageVersion_str",
  EXPERIMENT_GROUP: "experimentGroup_str",
  PERIOD_ID: "periodId_str",
  CALENDAR_TREATMENT_ID: "calendarTreatmentId_str",
  EDIT_TYPE: "editType_str",
  REASON: "reason_str",
  START_DATE: "startDate_date",
  DAYS: "days_int",
  TYPE: "type_str",
  RESULTS_RELEASED_TO_PROVIDER: "resultsReleasedToProvider_bool",
  PROVIDER_ORDER_TYPE: "providerOrderType_str",
  PROVIDER_ORDER_PAYER: "providerOrderPayer_str",
  PROVIDER_ADD_STI_TEST: "providerAddSTITest_bool",
  FILTER_TEXT: "filterText_str",
  REMOVE_ANTIBIOTICS: "removeAntibiotics_bool",
  HEADER_TEXT: "headerText_str",
  MENU_OPEN: "menuOpen_bool",
  VALENCIA_TYPE: "valenciaType_str",
  TREATMENT_PATHWAY: "treatmentPathway_str",
  ELIGIBLE_TREATMENT_PATHWAYS: "eligibleTreatmentPathways_strs",
  PATHWAY_TYPE: "pathwayType_str",
  RECOMMENDED_PRODUCTS: "recommendedProducts_strs",
  SUBSCRIPTION_TYPE: "subscriptionType_str",
  PRODUCT: "product_str",
  CANCEL_REASON: "cancelReason_str",
  IS_SYMPTOMATIC: "isSymptomatic_bool",
  ANNOUNCEMENT_ID: "announcementId_str",
  LINE_ITEMS: "lineItems_strs",
  PRESCRIPTIONS_AVAILABLE: "prescriptionsAvailable_strs",
  INFECTION_STATE: "infectionState_str",
  SYMPTOMS: "symptoms_strs",
  A_LA_CARE_ELIGIBLE: "aLaCareEligible_bool",
  RECOMMENDED_A_LA_CARE_TREATMENTS: "recommendedALaCareTreatments_strs",
  SELECTED_TREATMENTS: "selectedTreatments_strs",
  SELECTED_BUNDLE_TREATMENTS: "selectedBundleTreatments_strs",
  UPDATED_TREATMENTS: "updatedTreatments_strs",
  UPDATED_BUNDLE: "updatedBundle_bool",
  TOTAL_IN_CART: "totalInCart_real",
  PAGE: "page_str",
  EMPTY_CART: "emptyCart_bool",
  WARNING_IN_CART: "warningInCart_bool",
  IN_CART: "inCart_bool",
  PURCHASE_TYPE: "purchaseType_str",
  HEALTH_HISTORY_VERSION: "healthHistoryVersion_int",
  VERSION: "version_str",
  AGE_ERROR: "ageError_bool",
  SKUS: "skus_strs",
  SKU: "sku_str",
  CHECKOUT_SOURCE: "checkoutSource_str",
  CHECKOUT_ID: "checkoutId_str",
};

const VAGINAL_HEALTH_HISTORY = "vaginal health history";

// Custom events functions
// Nav
const sendClickedTopNavItem = ({ navItem }) => {
  trackCustomEvent(eventNames.CLICKED_TOP_NAV, {
    [propertyNames.NAV_ITEM]: navItem,
  });
};

// util
const getInfectionState = (conditions, microbiomeState) => {
  // define infection state based on conditions and microbiome.
  // sort conditions alphabetically and then join them by " + "
  return microbiomeState === "disrupted" && conditions?.length > 0
    ? conditions.sort().join(" + ")
    : microbiomeState;
};

// My Plan

const sendViewedPlanPage = ({
  planVersion,
  planProfileId,
  careEligible,
  carePrice,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  aLaCareEligible,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(eventNames.VIEWED_PLAN_PAGE, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
  });
};

const sendClickedLearnMoreEvent = ({
  slug,
  groupName,
  planVersion,
  planProfileId,
  careEligible,
  location,
  carePrice,
}) => {
  trackCustomEvent(eventNames.CLICKED_LEARN_MORE, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.LOCATION]: location,
  });
};

const sendClickedResearchStatusEvent = ({
  slug,
  groupName,
  researchStatus,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
  location,
}) => {
  trackCustomEvent(eventNames.CLICKED_RESEARCH_STATUS, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.RESEARCH_STATUS]: researchStatus,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.LOCATION]: location,
  });
};

const sendClickedSource = ({
  slug,
  groupName,
  citationTitle,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
  location,
}) => {
  trackCustomEvent(eventNames.CLICKED_SOURCE, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.CITATION_TITLE]: citationTitle,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.LOCATION]: location,
  });
};

const sendClickedRecommendation = ({
  slug,
  groupName,
  recommendationTitle,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
  aLaCareEligible,
}) => {
  trackCustomEvent(eventNames.CLICKED_RECOMMENDATION, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.RECOMMENDATION_TITLE]: recommendationTitle,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
  });
};

const sendClickedCircleNav = ({
  slug,
  groupName,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
}) => {
  trackCustomEvent(eventNames.CLICKED_CIRCLE_NAV, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedRetest = ({
  slug,
  groupName,
  isMember,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
}) => {
  trackCustomEvent(eventNames.CLICKED_RETEST, {
    [propertyNames.PLAN_ITEM_SLUG]: slug,
    [propertyNames.PLAN_ITEM_GROUP]: groupName,
    [propertyNames.IS_MEMBER]: isMember,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedEmailUs = ({
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
}) => {
  trackCustomEvent(eventNames.CLICKED_EMAIL_US, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedTalkToExpert = ({
  location,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
}) => {
  trackCustomEvent(eventNames.CLICKED_TALK_EXPERT, {
    [propertyNames.LOCATION]: location,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedJoinCommunity = () => {
  trackCustomEvent(eventNames.CLICKED_JOIN_COMMUNITY, {});
};

const sendClickedFeedback = ({
  response,
  planVersion,
  careEligible,
  carePrice,
  planProfileId,
}) => {
  trackCustomEvent(eventNames.CLICKED_PLAN_FEEDBACK, {
    [propertyNames.RESPONSE]: response,
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedStartConsult = ({
  isMember,
  planVersion,
  planProfileId,
  carePrice,
  location,
}) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_START_CONSULT, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.IS_MEMBER]: isMember,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.LOCATION]: location,
  });
};

const sendClickedViewSteps = ({ planVersion, planProfileId, carePrice }) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_VIEW_STEPS, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedHideTreatmentGuide = ({
  planVersion,
  planProfileId,
  carePrice,
}) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_HIDE_TREATMENT_GUIDE, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendClickedPricingMoreInfo = ({
  planVersion,
  planProfileId,
  carePrice,
  location,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_PRICING_MORE_INFO, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
    [propertyNames.LOCATION]: location,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendClickedLearnMoreAboutCare = ({
  planVersion,
  planProfileId,
  carePrice,
  location,
}) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_LEARN_MORE_CARE, {
    [propertyNames.PLAN_TYPE]: planVersion,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.LOCATION]: location,
    [propertyNames.PLAN_PROFILE_ID]: planProfileId,
  });
};

const sendPlanClickedL1ResultsLink = ({ testHash, type }) => {
  trackCustomEvent(eventNames.PLAN_CLICKED_L1_RESULTS_LINK, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TYPE]: type,
  });
};

// Health History

const sendViewedHealthHistoryPage = ({
  section, // Health profile, health context, sample, etc.
  page, // Optional/only populated for V2 health histories
  questionGroup,
  testOrder = undefined, // optional
  testHash,
  isSymptomatic, // optional
  version,
}) => {
  const eventParameters = {
    [propertyNames.SECTION]: section,
    [propertyNames.QUESTION_GROUP]: questionGroup,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.HEALTH_HISTORY_VERSION]: version,
  };

  if (testOrder) {
    eventParameters[propertyNames.TEST_ORDER] = testOrder;
  }

  if (page) {
    eventParameters[propertyNames.HH_PAGE] = page;
  }

  if (isSymptomatic !== undefined) {
    eventParameters[propertyNames.IS_SYMPTOMATIC] = isSymptomatic;
  }

  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_VIEW_PAGE, eventParameters);
};

const sendViewedHealthHistoryEducationalMoment = ({
  section,
  page,
  questionGroup,
  testHash,
}) => {
  const eventParameters = {
    [propertyNames.SECTION]: section,
    [propertyNames.HH_PAGE]: page,
    [propertyNames.QUESTION_GROUP]: questionGroup,
    [propertyNames.TEST_HASH]: testHash,
  };

  trackCustomEvent(
    eventNames.HEALTH_HISTORY_FORM_VIEW_EDUCATIONAL_MOMENT,
    eventParameters
  );
};

const sendClickedBackHealthHistoryPage = ({
  section,
  page,
  questionGroup,
  testHash,
}) => {
  const eventParameters = {
    [propertyNames.SECTION]: section,
    [propertyNames.HH_PAGE]: page,
    [propertyNames.QUESTION_GROUP]: questionGroup,
    [propertyNames.TEST_HASH]: testHash,
  };
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_CLICK_BACK, eventParameters);
};

const sendViewedHealthHistorySection = ({
  section,
  sectionStatus,
  testOrder,
  testHash,
  version,
}) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_VIEW_SECTION, {
    [propertyNames.SECTION]: section,
    [propertyNames.SECTION_STATUS]: sectionStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.HEALTH_HISTORY_VERSION]: version,
  });
};

const sendHealthHistoryUpsellClickedCTA = ({
  testHash,
  ctaText,
  isSymptomatic,
}) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_UPGRADE_CLICKED_CTA, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.IS_SYMPTOMATIC]: isSymptomatic,
  });
};

const sendClickedAcceptSampleCriteria = ({ testOrder, testHash, version }) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_ACCEPT_SAMPLE_CRITERIA, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.HEALTH_HISTORY_VERSION]: version,
  });
};

const sendClickedSampleTaken = ({ testOrder, testHash, version }) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.HEALTH_HISTORY_VERSION]: version,
  });
};

const sendHealthHistorySymptomsUpsellViewed = ({
  testHash,
  symptoms,
  availableSKUs,
}) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_VIEWED_SYMPTOMS_UPSELL, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SYMPTOMS]: symptoms,
    [propertyNames.SKUS]: availableSKUs,
  });
};

const sendHealthHistorySymptomsUpsellClickedAdd = ({
  testHash,
  symptoms,
  sku,
}) => {
  trackCustomEvent(
    eventNames.HEALTH_HISTORY_CLICKED_ADD_CTA_ON_SYMPTOMS_UPSELL,
    {
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.SYMPTOMS]: symptoms,
      [propertyNames.SKU]: sku,
    }
  );
};

const sendHealthHistorySymptomsUpsellCheckout = ({
  testHash,
  symptoms,
  selectedSkus,
  totalInCart,
}) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_CLICKED_SYMPTOMS_UPSELL_CTA, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SYMPTOMS]: symptoms,
    [propertyNames.SKUS]: selectedSkus,
    [propertyNames.TOTAL_IN_CART]: totalInCart,
  });
};

// Consult loading page
const sendConsultIntakeViewedLoading = ({ checkoutSource, checkoutId }) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_VIEWED_LOADING_PAGE, {
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.CHECKOUT_ID]: checkoutId,
  });
};

const sendConsultIntakeViewedLoadingTimeout = ({
  checkoutSource,
  checkoutId,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_VIEWED_LOADING_TIMEOUT, {
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.CHECKOUT_ID]: checkoutId,
  });
};

const sendConsultIntakeLoadingScreenClickedBack = ({
  checkoutSource,
  checkoutId,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_LOADING_CLICKED_BACK, {
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.CHECKOUT_ID]: checkoutId,
  });
};

const sendConsultIntakeLoadingTimeoutClickedCTA = ({
  checkoutSource,
  checkoutId,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_LOADING_TIMEOUT_CLICKED_CTA, {
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.CHECKOUT_ID]: checkoutId,
  });
};

// Tests

const sendViewedMyTestsPage = ({ testOrder, careEligible }) => {
  trackCustomEvent(eventNames.VIEWED_MY_TESTS, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
  });
};

const sendViewedAnnouncements = ({ announcementId }) => {
  trackCustomEvent(eventNames.VIEWED_ANNOUNCEMENT, {
    [propertyNames.ANNOUNCEMENT_ID]: announcementId,
  });
};

const sendClickedActivateTest = ({ testOrder, testHash }) => {
  trackCustomEvent(eventNames.CLICKED_ACTIVATE, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendClickedActivationModalActivate = ({ testOrder, testHash }) => {
  trackCustomEvent(eventNames.ACTIVATION_MODAL_CLICKED_ACTIVATE, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendClickedCantFindID = () => {
  trackCustomEvent(eventNames.CLICKED_CANT_FIND_ID, {});
};

const sendClickedTestCardCTA = ({
  testOrder,
  testHash,
  testStatus,
  ctaText,
}) => {
  trackCustomEvent(eventNames.CLICKED_TEST_CARD_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedTestCardViewContext = ({
  testOrder,
  testHash,
  testStatus,
}) => {
  trackCustomEvent(eventNames.CLICKED_TEST_CARD_VIEW_CONTEXT, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedTestCardShowProgress = ({
  testOrder,
  testHash,
  testStatus,
}) => {
  trackCustomEvent(eventNames.CLICKED_TEST_CARD_SHOW_PROGRESS, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedTestSampleTrackingLink = ({
  testOrder,
  testHash,
  testStatus,
  location,
}) => {
  trackCustomEvent(eventNames.CLICKED_TEST_SAMPLE_TRACKING_LINK, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.LOCATION]: location,
  });
};

const clickedTestsForYouCTA = ({
  testOrder,
  testHash,
  testStatus,
  ctaText,
}) => {
  trackCustomEvent(eventNames.CLICKED_TESTS_FOR_YOU_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendTestsClickedJoinCommunity = () => {
  trackCustomEvent(eventNames.TESTS_CLICKED_JOIN_COMMUNITY, {});
};

const sendTestsClickedBlogLink = ({ link, ctaText }) => {
  trackCustomEvent(eventNames.TESTS_CLICKED_BLOG_LINK, {
    [propertyNames.ARTICLE_URL]: link,
    [propertyNames.CTA_TEXT]: ctaText,
  });
};

// Checklist

const sendViewedChecklist = ({ testHash, testOrder, checklistStatus }) => {
  trackCustomEvent(eventNames.VIEWED_CHECKLIST, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CHECKLIST_STATUS]: checklistStatus,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedChecklistCTA = ({
  testOrder,
  testHash,
  testStatus,
  ctaText,
  checklistStatus,
  stepNumber,
}) => {
  trackCustomEvent(eventNames.CHECKLIST_CLICKED_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CHECKLIST_STATUS]: checklistStatus,
    [propertyNames.STEP_NUMBER]: stepNumber,
  });
};

const sendClickedChecklistLink = ({
  testOrder,
  testHash,
  testStatus,
  link,
}) => {
  trackCustomEvent(eventNames.CHECKLIST_CLICKED_LINK, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_STATUS]: testStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.ARTICLE_URL]: link,
  });
};

// Results

const sendViewedMyResultsPage = ({
  resultsReleased,
  testOrder,
  testHash,
  careEligible,
  aLaCareEligible,
  experimentGroup = "results_to_plan",
}) => {
  trackCustomEvent(eventNames.VIEWED_MY_RESULTS, {
    [propertyNames.RESULTS_RELEASED]: resultsReleased,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

const sendExpandedResultsCard = ({
  testOrder,
  testHash,
  cardType,
  bacteriaName,
  bacteriaId,
  cst,
  microbeCategory,
}) => {
  trackCustomEvent(eventNames.EXPANDED_RESULTS_CARD, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.CARD_TYPE]: cardType,
    [propertyNames.BACTERIA_NAME]: bacteriaName,
    [propertyNames.BACTERIA_ID]: bacteriaId,
    [propertyNames.MICROBE_CATEGORY]: microbeCategory,
  });
};

const sendClickedDownloadResults = ({
  cst,
  testHash,
  testOrder,
  downloadType,
}) => {
  trackCustomEvent(eventNames.CLICKED_RESULTS_DOWNLOAD, {
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TYPE]: downloadType,
  });
};

const sendClickedResultsViewPlan = ({
  testOrder,
  testHash,
  cst,
  pageSection,
  experimentGroup,
  careEligible,
}) => {
  trackCustomEvent(eventNames.CLICKED_RESULTS_VIEW_PLAN, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_SECTION]: pageSection,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
  });
};

const sendClickedResultsGetCare = ({
  testOrder,
  testHash,
  cst,
  pageSection,
  experimentGroup = "results_to_plan",
}) => {
  trackCustomEvent(eventNames.CLICKED_RESULTS_VIEW_CARE, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_SECTION]: pageSection,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

const sendClickedResultsLegend = ({ testOrder, testHash, sectionTitle }) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_LEGEND, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SECTION_TITLE]: sectionTitle,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedResultsBack = ({ testOrder, testHash, sectionTitle }) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_BACK, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SECTION_TITLE]: sectionTitle,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendClickedBlogLink = ({
  testOrder,
  testHash,
  ctaName,
  articleUrl,
  pageSection,
}) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_BLOG_LINK, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CTA_TEXT]: ctaName,
    [propertyNames.PAGE_SECTION]: pageSection,
    [propertyNames.ARTICLE_URL]: articleUrl,
  });
};

const sendClickedFilterTags = ({ testOrder, testHash, cst, tagTitle }) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_FILTER_TAGS, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.TAG_TITLE]: tagTitle,
  });
};

const sendClickedCSTAssociation = ({
  testOrder,
  testHash,
  cst,
  associationTitle,
}) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_CST_ASSOCIATION, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.ASSOCIATION_TITLE]: associationTitle,
  });
};

const sendClickedAssociationDisclaimer = ({ testOrder, testHash, cst }) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_ASSOCIATION_DISCLAIMER, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
  });
};

const sendClickedBacteriaAssociation = ({
  testOrder,
  testHash,
  cst,
  bacteriaName,
  bacteriaId,
  associationTitle,
}) => {
  trackCustomEvent(eventNames.RESULTS_CLICKED_BACTERIA_ASSOCIATION, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.BACTERIA_NAME]: bacteriaName,
    [propertyNames.BACTERIA_ID]: bacteriaId,
    [propertyNames.ASSOCIATION_TITLE]: associationTitle,
  });
};

const sendExpandAllMicrobes = ({ testOrder, testHash, cst }) => {
  trackCustomEvent(eventNames.RESULTS_EXPAND_ALL_MICROBES, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
  });
};

const sendChangedTestDate = ({
  testOrder,
  testHash,
  cst,
  side,
  chartName = VAGINAL_HEALTH_HISTORY,
}) => {
  trackCustomEvent(eventNames.RESULTS_CHANGED_TEST_DATE, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.SIDE]: side,
    [propertyNames.CHART_NAME]: chartName,
  });
};

const sendExpandMicrobeCategoryBreakdown = ({
  testOrder,
  testHash,
  cst,
  microbeCategory,
  chartName = VAGINAL_HEALTH_HISTORY,
}) => {
  trackCustomEvent(eventNames.RESULTS_EXPAND_MICROBE_CATEGORY_BREAKDOWN, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.MICROBE_CATEGORY]: microbeCategory,
    [propertyNames.CHART_NAME]: chartName,
  });
};

const sendResultsExpandHealthHistory = ({
  testOrder,
  testHash,
  cst,
  chartName = VAGINAL_HEALTH_HISTORY,
}) => {
  trackCustomEvent(eventNames.RESULTS_EXPAND_HEALTH_HISTORY, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
    [propertyNames.CHART_NAME]: chartName,
  });
};

// Compare

const sendViewedComparePage = () => {
  trackCustomEvent(eventNames.VIEWED_COMPARE, {});
};

const sendClickedMoreInfoCompare = () => {
  trackCustomEvent(eventNames.CLICKED_MORE_INFO_COMPARE, {});
};

const sendClickedUpgradeNow = ({ cst, testHash }) => {
  trackCustomEvent(eventNames.CLICKED_UPGRADE_NOW, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.COMMUNITY_STATE_TYPE]: cst,
  });
};

const sendViewedComparePopUp = () => {
  trackCustomEvent(eventNames.VIEWED_COMPARE_INFO_MODAL, {});
};

// Login Page
const sendLoginViewedPage = ({ version }) => {
  trackCustomEvent(eventNames.LOGIN_VIEW_PAGE, {
    [propertyNames.VERSION]: version,
  });
};

const sendLoginPageClickedCTA = ({ ctaText, version }) => {
  trackCustomEvent(eventNames.LOGIN_CLICKED_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.VERSION]: version,
  });
};

const sendUserLoggedIn = ({ version }) => {
  trackCustomEvent(eventNames.LOGGED_IN, {
    [propertyNames.VERSION]: version,
  });
};

// Accounts
const sendViewedMyAccountPage = () => {
  trackCustomEvent(eventNames.VIEWED_MY_ACCOUNT, {});
};

const sendAccountCreationViewedPage = ({ version }) => {
  trackCustomEvent(eventNames.ACCOUNT_CREATION_VIEWED_PAGE, {
    [propertyNames.VERSION]: version,
  });
};

const sendAccountCreationClickedCTA = ({ ctaText, version, ageError }) => {
  trackCustomEvent(eventNames.ACCOUNT_CREATION_CLICKED_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.VERSION]: version,
    [propertyNames.AGE_ERROR]: ageError,
  });
};

const sendVerifyEmailViewedPage = ({ ctaText, version }) => {
  trackCustomEvent(eventNames.VERIFY_EMAIL_VIEW_PAGE, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.VERSION]: version,
  });
};

const sendVerifyEmailClickedCTA = ({ version }) => {
  trackCustomEvent(eventNames.VERIFY_EMAIL_CLICKED_CTA, {
    [propertyNames.VERSION]: version,
  });
};

const sendResearchConsentViewedPage = ({ version }) => {
  trackCustomEvent(eventNames.RESEARCH_CONSENT_VIEW_PAGE, {
    [propertyNames.VERSION]: version,
  });
};

const sendResearchConsentClickedCTA = ({ ctaText, version }) => {
  trackCustomEvent(eventNames.RESEARCH_CONSENT_CLICKED_CTA, {
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.VERSION]: version,
  });
};

const sendClickedManageMembership = () => {
  trackCustomEvent(eventNames.CLICKED_MANAGE_MEMBERSHIP, {});
};

const sendClickedCancelMembership = () => {
  trackCustomEvent(eventNames.ACCOUNT_CLICKED_CANCEL, {});
};

const sendSelectedCancellationReason = ({ cancelReason }) => {
  trackCustomEvent(eventNames.ACCOUNT_SELECTED_CANCEL_REASON, {
    [propertyNames.CANCEL_REASON]: cancelReason,
  });
};

const sendSubmitCancelMembership = ({ cancelReason }) => {
  trackCustomEvent(eventNames.ACCOUNT_SUBMIT_CANCELLATION, {
    [propertyNames.CANCEL_REASON]: cancelReason,
  });
};

const sendEditAccountInformation = ({ informationType }) => {
  trackCustomEvent(eventNames.ACCOUNT_EDIT_INFORMATION, {
    [propertyNames.INFORMATION_TYPE]: informationType,
  });
};

const sendClickedLogout = () => {
  trackCustomEvent(eventNames.CLICKED_LOGOUT, {});
};

const sendClickedViewExpandedPCRModalFromAccount = () => {
  trackCustomEvent(eventNames.ACCOUNT_CLICKED_VIEW_EXPANDED_PCR_DETAIL, {});
};

const sendClickedViewExpandedPCRModalFromHealthHistory = () => {
  trackCustomEvent(
    eventNames.HEALTH_HISTROY_CLICKED_SEE_EXPANDED_PCR_LIST_POPUP,
    {}
  );
};

const sendClickedViewExpandedPCRModalFromProviderOrder = () => {
  trackCustomEvent(
    eventNames.PROVIDER_ORDER_CLICKED_VIEW_EXPANDED_PCR_MODAL,
    {}
  );
};

const sendClickedUpgradeMembership = ({ subscriptionType }) => {
  trackCustomEvent(eventNames.ACCOUNT_CLICKED_UPGRADE, {
    [propertyNames.SUBSCRIPTION_TYPE]: subscriptionType,
  });
};

const sendClickedDowngradeMembership = ({ subscriptionType }) => {
  trackCustomEvent(eventNames.ACCOUNT_CLICKED_DOWNGRADE, {
    [propertyNames.SUBSCRIPTION_TYPE]: subscriptionType,
  });
};

// Treatment Plan Page

const sendViewedTreatmentPage = ({
  consultId,
  testHash,
  pageStatus,
  pathwayType,
}) => {
  trackCustomEvent(eventNames.TREATMENT_VIEW_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.PAGE_STATUS]: pageStatus,
    [propertyNames.PATHWAY_TYPE]: pathwayType,
  });
};

const sendTreatmentExpandSection = ({ consultId, testHash, section }) => {
  trackCustomEvent(eventNames.TREATMENT_EXPAND_PAGE_SECTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SECTION]: section,
  });
};

const sendTreatmentExpandPrescription = ({
  consultId,
  testHash,
  prescriptionSlug,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EXPAND_PRESCRIPTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

const sendTreatmentClickedQuestion = ({ consultId, testHash, location }) => {
  trackCustomEvent(eventNames.TREATMENT_ASK_QUESTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.LOCATION]: location,
  });
};

const sendTreatmentClickedFAQ = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.TREATMENT_CLICKED_FAQ, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendTreatmentClickedCoaching = ({
  consultId,
  testHash,
  location,
  callName,
}) => {
  const eventArgs = {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.LOCATION]: location,
  };
  if (callName) {
    eventArgs[propertyNames.CALL_NAME] = callName;
  }
  trackCustomEvent(eventNames.TREATMENT_CLICKED_COACHING, eventArgs);
};

const sendTreatmentClickedRetest = ({ consultId, testHash, location }) => {
  const eventArgs = {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.LOCATION]: location,
  };
  trackCustomEvent(eventNames.TREATMENT_CLICKED_RETEST, eventArgs);
};

const sendSelectedTreatmentStartDate = ({
  consultId,
  testHash,
  treatmentStartDate,
  oldTreatmentStartDate,
}) => {
  if (oldTreatmentStartDate) {
    trackCustomEvent(eventNames.TREATMENT_EDIT_START_DATE, {
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.NEW_TREAMTNET_START_DATE]: treatmentStartDate,
      [propertyNames.ORIGINAL_TREATMENT_START_DATE]: oldTreatmentStartDate,
    });
  } else {
    trackCustomEvent(eventNames.TREATMENT_SELECT_START_DATE, {
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.TREATMENT_START_DATE]: treatmentStartDate,
    });
  }
};

const sendClickedDownloadTreatmentPDF = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.TREATMENT_CLICKED_DOWNLOAD_PDF, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendClickedAddTreatmentCalendar = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.TREATMENT_CLICKED_ADD_CALENDAR, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendClickedEditTreatment = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CLICKED_EDIT_CALENDAR_TREATMENT, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

const sendEditTreatmentSelectType = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
  editType,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EDIT_TREATMENT_SELECT_TYPE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EDIT_TYPE]: editType,
  });
};

const sendEditTreatmentSelectCadenceOption = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
  editType,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EDIT_TREATMENT_SELECT_CADENCE_OPTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EDIT_TYPE]: editType,
  });
};

const sendEditTreatmentClickedNext = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
  editType,
  currentStep,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EDIT_TREATMENT_CLICKED_NEXT, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EDIT_TYPE]: editType,
    [propertyNames.SECTION_TITLE]: currentStep,
  });
};

const sendEditTreatmentClickedBack = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
  editType,
  currentStep,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EDIT_TREATMENT_CLICKED_BACK, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EDIT_TYPE]: editType,
    [propertyNames.SECTION_TITLE]: currentStep,
  });
};

const sendEditTreatmentSelectReason = ({
  consultId,
  testHash,
  calendarTreatmentId,
  prescriptionSlug,
  editType,
  reason,
}) => {
  trackCustomEvent(eventNames.TREATMENT_EDIT_TREATMENT_SELECT_REASON, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CALENDAR_TREATMENT_ID]: calendarTreatmentId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EDIT_TYPE]: editType,
    [propertyNames.REASON]: reason,
  });
};

const sendOpenPeriodModal = ({ consultId, testHash, periodId, location }) => {
  trackCustomEvent(eventNames.TREATMENT_OPEN_PERIOD_MODAL, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.PERIOD_ID]: periodId,
    [propertyNames.LOCATION]: location,
  });
};

const sendSavePeriod = ({ consultId, periodId, startDate, days, type }) => {
  trackCustomEvent(eventNames.TREATMENT_SAVE_PERIOD, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PERIOD_ID]: periodId,
    [propertyNames.START_DATE]: startDate,
    [propertyNames.DAYS]: days,
    [propertyNames.TYPE]: type,
  });
};

const sendDeletePeriod = ({ consultId, periodId, startDate, days }) => {
  trackCustomEvent(eventNames.TREATMENT_DELETE_PERIOD, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PERIOD_ID]: periodId,
    [propertyNames.START_DATE]: startDate,
    [propertyNames.DAYS]: days,
  });
};

const sendTreatmentChangedWeek = ({
  consultId,
  testHash,
  currentWeek,
  viewedWeek,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CHANGE_WEEK, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CURRENT_WEEK]: currentWeek,
    [propertyNames.VIEWED_WEEK]: viewedWeek,
  });
};

const sendTreatmentResetCalendar = ({ consultUid, testHash }) => {
  trackCustomEvent(eventNames.TREATMENT_RESET_CALENDAR, {
    [propertyNames.CONSULT_UID]: consultUid,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendTreatmentExpandFAQ = ({ consultId, testHash, faqQuestion }) => {
  trackCustomEvent(eventNames.TREATMENT_EXPAND_FAQ, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.FAQ_QUESTION]: faqQuestion,
  });
};

const sendShowedIntercomWidget = ({ consultId, testHash }) => {
  if (consultId) {
    trackCustomEvent(eventNames.TREATMENT_SHOWED_INTERCOM_WIDGET, {
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.TEST_HASH]: testHash,
    });
  }
};

// Recommended Treatment
const sendRecommendedTreatmentViewLoadingPage = ({ consultId }) => {
  trackCustomEvent(eventNames.RECOMMENDED_TREATMENT_VIEW_LOADING_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
  });
};

const sendRecommendedTreatmentViewPage = ({
  consultId,
  testHash,
  carePrice,
  treatmentPathway,
  recommendedProducts,
  experimentGroup = "control",
}) => {
  trackCustomEvent(eventNames.RECOMMENDED_TREATMENT_VIEW_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.TREATMENT_PATHWAY]: treatmentPathway,
    [propertyNames.RECOMMENDED_PRODUCTS]: recommendedProducts,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

const sendRecommendedTreatmentClickedBuy = ({
  consultId,
  testHash,
  carePrice,
  treatmentPathway,
  recommendedProducts,
  experimentGroup = "control",
}) => {
  trackCustomEvent(eventNames.RECOMMENDED_TREATMENT_CLICKED_BUY, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.TREATMENT_PATHWAY]: treatmentPathway,
    [propertyNames.RECOMMENDED_PRODUCTS]: recommendedProducts,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

const sendRecommendedTreatmentExpandPrescriptionInfo = ({
  consultId,
  testHash,
  carePrice,
  treatmentPathway,
  recommendedProducts,
  prescriptionSlug,
  experimentGroup = "control",
}) => {
  trackCustomEvent(eventNames.RECOMMENDED_TREATMENT_EXPAND_PRESCRIPTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.TREATMENT_PATHWAY]: treatmentPathway,
    [propertyNames.RECOMMENDED_PRODUCTS]: recommendedProducts,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

// Consultation Intake
const sendConsultIntakeViewedUpsell = ({
  consultUid,
  testHash,
  consultType,
  checkoutSource,
  availableSKUs,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_VIEWED_UPSELL, {
    [propertyNames.CONSULT_ID]: consultUid,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_TYPE]: consultType,
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.SKUS]: availableSKUs,
  });
};

const sendConsultIntakeClickedAdd = ({
  consultUid,
  testHash,
  consultType,
  checkoutSource,
  sku,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_CLICKED_UPSELL_CTA, {
    [propertyNames.CONSULT_ID]: consultUid,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_TYPE]: consultType,
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.SKU]: sku,
  });
};

const sendConsultIntakeClickedCheckout = ({
  consultUid,
  testHash,
  consultType,
  checkoutSource,
  selectedSkus,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_CLICKED_CTA, {
    [propertyNames.CONSULT_ID]: consultUid,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_TYPE]: consultType,
    [propertyNames.CHECKOUT_SOURCE]: checkoutSource,
    [propertyNames.SKUS]: selectedSkus,
  });
};

const sendConsultIntakeViewSection = ({
  consultId,
  testHash,
  section,
  consultType = "vaginitis",
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_VIEW_SECTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.SECTION]: section,
    [propertyNames.CONSULT_TYPE]: consultType,
  });
};

const sendConsultIntakeClickedExitCTA = ({
  consultId,
  testHash,
  ctaText,
  exitType,
  consultType,
}) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_CLICKED_EXIT_CTA, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.EXIT_TYPE]: exitType,
    [propertyNames.CONSULT_TYPE]: consultType,
  });
};

const sendConsultIntakeClickedAltPharmacy = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.CONSULT_INTAKE_CLICKED_ALT_PHARMACY, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

// Care Page
const sendCareViewedPage = ({
  consultId,
  testHash,
  carePageStatus,
  careEligible,
  eligibleTreatmentPathways,
  carePrice,
  consultStatus,
  pageVersion,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(
    eventNames.CARE_VIEW_PAGE,
    {
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
      [propertyNames.CARE_ELIGIBLE]: careEligible,
      [propertyNames.CARE_PRICE]: carePrice,
      [propertyNames.CONSULT_STATUS]: consultStatus,
      [propertyNames.PAGE_VERSION]: pageVersion,
      [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
        eligibleTreatmentPathways || [],
      [propertyNames.INFECTION_STATE]: infectionState,
      [propertyNames.SYMPTOMS]: symptoms || [],
      [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    },
    [DESTINATION_FULLSTORY, DESTINATION_KLAVIYO]
  );
};

const sendCareClickedALaCareOnRamp = ({
  consultId,
  testHash,
  carePageStatus,
  careEligible,
  eligibleTreatmentPathways,
  carePrice,
  consultStatus,
  pageVersion,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(eventNames.CARE_CLICKED_A_LA_CARE_ONRAMP, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.PAGE_VERSION]: pageVersion,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
  });
};

const sendCareClickedTrackOrder = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  experimentGroup = "results_to_plan",
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_TRACK_ORDER, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
  });
};

const sendCareClickedViewPastConsultations = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedTalkToExpert = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  location,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_TALK_TO_EXPERT, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.LOCATION]: location,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedViewCommunityRecs = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_COMMUNITY_RECS, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedJoinCommunity = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_JOIN_COMMUNITY, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedSeeAllArticles = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_SEE_ALL_ARTICLES, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedBlogArticle = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  articleUrl,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_BLOG_ARTICLE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.ARTICLE_URL]: articleUrl,
  });
};

const sendCareSelectedTreatmentOption = ({
  testHash,
  treatmentPathway,
  pathwayType,
  eligibleTreatmentPathways,
  carePrice,
  location,
}) => {
  trackCustomEvent(eventNames.CARE_SELECTED_TREATMENT_OPTION, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TREATMENT_PATHWAY]: treatmentPathway,
    [propertyNames.PATHWAY_TYPE]: pathwayType,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]: eligibleTreatmentPathways,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.LOCATION]: location,
  });
};

const sendCareClickedViewPlan = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  location,
  pageVersion,
  experimentGroup = "results_to_plan",
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_PLAN, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.EXPERIMENT_GROUP]: experimentGroup,
    [propertyNames.PAGE_VERSION]: pageVersion,
    [propertyNames.LOCATION]: location,
  });
};

const sendCareClickedViewSampleTreatmentPlan = ({
  consultId,
  testHash,
  testOrder,
  carePrice,
  consultStatus,
  carePageStatus,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_SAMPLE_PLAN, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
  });
};

const sendCareClickedViewIntake = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_INTAKE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedViewTreatmentPlan = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_TREATMENT_PLAN, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareExpandFAQ = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  faqQuestion,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_EXPANDED_FAQ, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
    [propertyNames.FAQ_QUESTION]: faqQuestion,
  });
};

const sendCareClickedQuestion = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_ASK_QUESTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedGetCareFooter = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  pageVersion,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_GET_CARE_FOOTER, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
  });
};

const sendCareClickedViewWhitePaper = ({
  consultId,
  testHash,
  careEligible,
  carePrice,
  consultStatus,
  carePageStatus,
  testOrder,
  pageVersion,
  location,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_WHITE_PAPER, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_PAGE_STATUS]: carePageStatus,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.PAGE_VERSION]: pageVersion,
    [propertyNames.LOCATION]: location,
  });
};

// Sample Treatment Plan Page
const sendSamplePlanViewPage = ({
  consultId,
  testHash,
  testOrder,
  carePrice,
  consultStatus,
}) => {
  trackCustomEvent(eventNames.SAMPLE_PLAN_VIEW_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
  });
};

const sendSamplePlanClickedGetCare = ({
  consultId,
  testHash,
  testOrder,
  carePrice,
  consultStatus,
}) => {
  trackCustomEvent(eventNames.SAMPLE_PLAN_CLICKED_GET_CARE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
  });
};

const sendSamplePlanClickedBack = ({
  consultId,
  testHash,
  testOrder,
  carePrice,
  consultStatus,
}) => {
  trackCustomEvent(eventNames.SAMPLE_PLAN_CLICKED_BACK, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
  });
};

const sendSamplePlanViewedSection = ({
  consultId,
  testHash,
  testOrder,
  carePrice,
  consultStatus,
  sectionTitle,
}) => {
  trackCustomEvent(eventNames.SAMPLE_PLAN_VIEWED_SECTION, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CONSULT_STATUS]: consultStatus,
    [propertyNames.SECTION_TITLE]: sectionTitle,
  });
};

// Care - Past Consultations page
const sendCareViewPastConsultationsPage = ({
  numPastConsultations,
  careEligible,
}) => {
  trackCustomEvent(eventNames.PAST_CONSULTATIONS_VIEW_PAGE, {
    [propertyNames.NUM_PAST_CONSULTATIONS]: numPastConsultations,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
  });
};

const sendCarePastConsultationsClickedBack = ({
  numPastConsultations,
  careEligible,
}) => {
  trackCustomEvent(eventNames.PAST_CONSULTATIONS_CLICKED_BACK, {
    [propertyNames.NUM_PAST_CONSULTATIONS]: numPastConsultations,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
  });
};

const sendCarePastConsultationsClickedViewTreatment = ({
  numPastConsultations,
  careEligible,
  consultId,
  testHash,
  consultStatus,
}) => {
  trackCustomEvent(eventNames.PAST_CONSULTATIONS_CLICKED_VIEW_TREATMENT, {
    [propertyNames.NUM_PAST_CONSULTATIONS]: numPastConsultations,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_STATUS]: consultStatus,
  });
};

const sendCarePastConsultationsClickedArrowNav = ({
  numPastConsultations,
  careEligible,
  arrowDirection,
}) => {
  trackCustomEvent(eventNames.PAST_CONSULTATIONS_CLICKED_ARROW_NAV, {
    [propertyNames.NUM_PAST_CONSULTATIONS]: numPastConsultations,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ARROW_DIRECTION]: arrowDirection,
  });
};

// Messaging
const sendCareClickedViewPastMessages = ({
  consultId,
  testHash,
  testOrder,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendCareClickedMessageProvider = ({ consultId, testHash, testOrder }) => {
  trackCustomEvent(eventNames.CARE_CLICKED_MESSAGE_PROVIDER, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.TEST_ORDER]: testOrder,
  });
};

const sendMessagingViewThread = ({
  consultId,
  testHash,
  numUserMessages,
  numProviderMessages,
}) => {
  trackCustomEvent(eventNames.MESSAGING_VIEW_THREAD, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.NUM_USER_MESSAGES]: numUserMessages,
    [propertyNames.NUM_PROVIDER_MESSAGES]: numProviderMessages,
  });
};

const sendMessagingClickedBack = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.MESSAGING_CLICKED_BACK, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendMessagingClickedSupport = ({
  consultId,
  testHash,
  numUserMessages,
  numProviderMessages,
  location,
}) => {
  trackCustomEvent(eventNames.MESSAGING_CLICKED_SUPPORT, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.NUM_USER_MESSAGES]: numUserMessages,
    [propertyNames.NUM_PROVIDER_MESSAGES]: numProviderMessages,
    [propertyNames.LOCATION]: location,
  });
};

const sendMessagingClickedSubmitMessage = ({ consultId, testHash }) => {
  trackCustomEvent(eventNames.MESSAGING_CLICKED_SUBMIT_MESSAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
  });
};

const sendMessagingViewMessagesHome = ({ consultId, testHash, numThreads }) => {
  trackCustomEvent(eventNames.MESSAGING_VIEW_MESSAGES_HOME, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.NUM_THREADS]: numThreads,
  });
};

const sendMessagingClickedTestResults = ({
  consultId,
  testHash,
  threadStatus,
  messageStatus,
}) => {
  trackCustomEvent(eventNames.MESSAGING_CLICKED_TEST_RESULTS, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.THREAD_STATUS]: threadStatus,
    [propertyNames.MESSAGE_STATUS]: messageStatus,
  });
};

const sendMessagingClickedProviderIntake = ({
  consultId,
  testHash,
  threadStatus,
  messageStatus,
}) => {
  trackCustomEvent(eventNames.MESSAGING_CLICKED_PROVIDER_INTAKE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.THREAD_STATUS]: threadStatus,
    [propertyNames.MESSAGE_STATUS]: messageStatus,
  });
};

const sendCareClickedViewMessage = ({
  consultId,
  testHash,
  threadStatus,
  messageStatus,
  location,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_VIEW_MESSAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.THREAD_STATUS]: threadStatus,
    [propertyNames.MESSAGE_STATUS]: messageStatus,
    [propertyNames.LOCATION]: location,
  });
};

// Providers
const sendProviderViewedRegistrationPage = () => {
  trackCustomEvent(eventNames.PROVIDER_REGISTRATION_VIEW_PAGE, {});
};

const sendProviderClickedAgreeToTerms = () => {
  trackCustomEvent(eventNames.PROVIDER_REGISTRATION_CLICKED_AGREE_TO_TERMS, {});
};

const sendProviderClickedViewTerms = () => {
  trackCustomEvent(eventNames.PROVIDER_REGISTRATION_CLICKED_VIEW_TERMS, {});
};

const sendProviderClickedContinueRegistration = () => {
  trackCustomEvent(
    eventNames.PROVIDER_REGISTRATION_CLICKED_CONTINUE_REGISTRATION,
    {}
  );
};

const sendProviderClickedSignInFromRegistration = () => {
  trackCustomEvent(eventNames.PROVIDER_REGISTRATON_CLICKED_SIGN_IN, {});
};

const sendProviderViewedIntakePage = () => {
  trackCustomEvent(eventNames.PROVIDER_INTAKE_VIEW_PAGE, {});
};

const sendProviderClickedContinueOnIntake = () => {
  trackCustomEvent(eventNames.PROVIDER_INTAKE_CLICKED_CONTINUE, {});
};

const sendProviderViewedResourcesPage = () => {
  trackCustomEvent(eventNames.PROVIDER_RESOURCES_VIEW_PAGE, {});
};

const sendProviderViewedOrderPage = () => {
  trackCustomEvent(eventNames.PROVIDER_ORDER_VIEW_PAGE, {});
};

const sendProviderViewedTestsPage = () => {
  trackCustomEvent(eventNames.PROVIDER_TESTS_VIEW_PAGE, {});
};

const sendProviderClickedJoinCommunity = () => {
  trackCustomEvent(eventNames.PROVIDER_HOME_CLICKED_JOIN_COMMUNITY, {});
};

const sendProviderClickedResourceLink = ({ articleUrl, ctaText }) => {
  trackCustomEvent(eventNames.PROVIDER_HOME_CLICKED_RESOURCE_LINK, {
    [propertyNames.ARTICLE_URL]: articleUrl,
    [propertyNames.CTA_TEXT]: ctaText,
  });
};

const sendProviderClickedOrderFromResources = ({ type }) => {
  trackCustomEvent(eventNames.PROVIDER_RESOURCES_CLICKED_ORDER, {
    [propertyNames.PROVIDER_ORDER_TYPE]: type,
  });
};

const sendProviderPlacedOrder = ({ type, addSTI }) => {
  trackCustomEvent(eventNames.PROVIDER_HOME_CLICKED_ORDER, {
    [propertyNames.PROVIDER_ORDER_TYPE]: type,
    [propertyNames.PROVIDER_ADD_STI_TEST]: addSTI,
  });
};

const sendProviderClickedFilterText = ({ filterText }) => {
  trackCustomEvent(eventNames.PROVIDER_HOME_TRACK_ORDER_FILTER, {
    [propertyNames.FILTER_TEXT]: filterText,
  });
};

const sendProviderClickedDownloadResults = () => {
  trackCustomEvent(eventNames.PROVIDER_HOME_CLICKED_DOWNLOAD_RESULTS, {});
};

const sendPatientRespondedToProviderAuthorization = ({
  testOrder,
  testHash,
  resultsReleasedToProvider,
}) => {
  trackCustomEvent(eventNames.HEALTH_HISTORY_FORM_PROVIDER_AUTHORIZATION, {
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.RESULTS_RELEASED_TO_PROVIDER]: resultsReleasedToProvider,
  });
};

const sendRemoveAntibioticsClicked = ({
  removeAntibiotics,
  testHash,
  location,
}) => {
  trackCustomEvent(eventNames.REMOVE_ANTIBIOTICS_CLICKED, {
    [propertyNames.REMOVE_ANTIBIOTICS]: removeAntibiotics,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.LOCATION]: location,
  });
};

const sendCollapsibleMenuToggled = ({ menuOpen, location, headerText }) => {
  trackCustomEvent(eventNames.COLLAPSIBLE_MENU_TOGGLED, {
    [propertyNames.MENU_OPEN]: menuOpen,
    [propertyNames.LOCATION]: location,
    [propertyNames.HEADER_TEXT]: headerText,
  });
};

const sendExpandWhatYouGetWithEvvy = ({ location, testHash, carePrice }) => {
  trackCustomEvent(eventNames.CARE_EXPANDED_WHAT_YOU_GET, {
    [propertyNames.LOCATION]: location,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PRICE]: carePrice,
  });
};

const sendExpandWhyOurTreatmentsWork = ({ location, testHash, carePrice }) => {
  trackCustomEvent(eventNames.CARE_EXPANDED_WHY_OUR_TREATMENTS_WORK, {
    [propertyNames.LOCATION]: location,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CARE_PRICE]: carePrice,
  });
};

const sendClickedThroughAntibioticsDisclaimer = ({
  location,
  testHash,
  ctaText,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_THROUGH_ANTIBIOTICS_DISCLAIMER, {
    [propertyNames.LOCATION]: location,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CTA_TEXT]: ctaText,
  });
};

const sendClickedCareCTA = ({
  location,
  testHash,
  ctaText,
  testOrder,
  careEligible,
  carePrice,
  valenciaType,
  treatmentPathway,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_CTA_BUTTON, {
    [propertyNames.LOCATION]: location,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CTA_TEXT]: ctaText,
    [propertyNames.TEST_ORDER]: testOrder,
    [propertyNames.CARE_PRICE]: carePrice,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.VALENCIA_TYPE]: valenciaType,
    [propertyNames.TREATMENT_PATHWAY]: treatmentPathway,
  });
};

const sendClickedSTIIntakeCTA = ({ location, testHash, ctaText }) => {
  trackCustomEvent(eventNames.STI_INTAKE_CLICKED_CTA_BUTTON, {
    [propertyNames.LOCATION]: location,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CTA_TEXT]: ctaText,
  });
};

const sendSTIPastTreatmentCTAClicked = ({
  ctaType,
  testHash,
  consultId,
  productName,
}) => {
  trackCustomEvent(eventNames.STI_PAST_TREATMENT_CARD_CTA, {
    [propertyNames.CTA_TYPE]: ctaType,
    [propertyNames.PRODUCT]: productName,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
  });
};

const sendViewedRefillsPage = ({ consultId, prescriptionsAvailable }) => {
  trackCustomEvent(eventNames.REFILL_VIEWED_CART_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PRESCRIPTIONS_AVAILABLE]: prescriptionsAvailable,
  });
};

const sendClickedAddRefillToCart = ({ consultId, prescriptionSlug }) => {
  trackCustomEvent(eventNames.REFILL_ADDED_TO_CART, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

const sendClickedRemoveRefillFromCart = ({ consultId, prescriptionSlug }) => {
  trackCustomEvent(eventNames.REFILL_REMOVED_FROM_CART, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

const sendClickedRetestForPrescription = ({ consultId, prescriptionSlug }) => {
  trackCustomEvent(eventNames.REFILL_CLICKED_RETEST, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

const sendClickedCheckoutForRefills = ({
  consultId,
  lineItems,
  prescriptionsAvailable,
}) => {
  trackCustomEvent(eventNames.REFILL_CLICKED_CHECKOUT, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.LINE_ITEMS]: lineItems,
    [propertyNames.PRESCRIPTIONS_AVAILABLE]: prescriptionsAvailable,
  });
};

const sendVisitedRefillsFromHomePage = ({ consultId }) => {
  trackCustomEvent(eventNames.REFILL_VISITED_FROM_HOME_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
  });
};

const sendVisitedRefillsFromCarePage = ({ consultId }) => {
  trackCustomEvent(eventNames.REFILL_VISITED_FROM_CARE_PAGE, {
    [propertyNames.CONSULT_ID]: consultId,
  });
};

const sendViewedPrescriptionDetails = ({ consultId, prescriptionSlug }) => {
  trackCustomEvent(eventNames.REFILL_VIEWED_PRESCRIPTION_DETAILS, {
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.PRESCRIPTION_SLUG]: prescriptionSlug,
  });
};

// shop treatments
const sendViewedShopTreatmentsPage = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
  recommendedALaCareTreatments,
  selectedTreatments,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(
    eventNames.SHOP_TREATMENTS_VIEWED_PAGE,
    {
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.CARE_ELIGIBLE]: careEligible,
      [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
        eligibleTreatmentPathways || [],
      [propertyNames.INFECTION_STATE]: infectionState,
      [propertyNames.SYMPTOMS]: symptoms || [],
      [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
      [propertyNames.RECOMMENDED_A_LA_CARE_TREATMENTS]:
        recommendedALaCareTreatments || [],
      [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    },
    [DESTINATION_FULLSTORY, DESTINATION_KLAVIYO]
  );
};

const sendShopTreatmentsClickedTreatment = ({
  treatmentSlug,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
  recommendedALaCareTreatments,
  selectedTreatments,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(eventNames.SHOP_TREATMENTS_CLICKED_TREATMENT, {
    [propertyNames.PRESCRIPTION_SLUG]: treatmentSlug,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.RECOMMENDED_A_LA_CARE_TREATMENTS]:
      recommendedALaCareTreatments || [],
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
  });
};

const sendShopTreatmentsClickedTreatmentProgramOnramp = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
  recommendedALaCareTreatments,
  selectedTreatments,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(
    eventNames.SHOP_TREATMENTS_CLICKED_TREATMENT_PROGRAM_ONRAMP,
    {
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.CARE_ELIGIBLE]: careEligible,
      [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
        eligibleTreatmentPathways || [],
      [propertyNames.INFECTION_STATE]: infectionState,
      [propertyNames.SYMPTOMS]: symptoms || [],
      [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
      [propertyNames.RECOMMENDED_A_LA_CARE_TREATMENTS]:
        recommendedALaCareTreatments || [],
      [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    }
  );
};

const sendShopTreatmentsClickedRetestOnramp = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
  recommendedALaCareTreatments,
  selectedTreatments,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(eventNames.SHOP_TREATMENTS_CLICKED_RETEST_ONRAMP, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.RECOMMENDED_A_LA_CARE_TREATMENTS]:
      recommendedALaCareTreatments || [],
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
  });
};

const sendShopTreatmentsClickedAddToCart = ({
  treatmentSlug,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  symptoms,
  conditions,
  microbiomeState,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(
    eventNames.SHOP_TREATMENTS_CLICKED_ADD_TO_CART,
    {
      [propertyNames.PRESCRIPTION_SLUG]: treatmentSlug,
      [propertyNames.TEST_HASH]: testHash,
      [propertyNames.CONSULT_ID]: consultId,
      [propertyNames.CARE_ELIGIBLE]: careEligible,
      [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
        eligibleTreatmentPathways || [],
      [propertyNames.INFECTION_STATE]: infectionState,
      [propertyNames.SYMPTOMS]: symptoms || [],
      [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
      [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    },
    [DESTINATION_FULLSTORY, DESTINATION_KLAVIYO]
  );
};

const sendShopTreatmentsClickedRemoveFromCart = ({
  treatmentSlug,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  symptoms,
  conditions,
  microbiomeState,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  trackCustomEvent(eventNames.SHOP_TREATMENTS_CLICKED_REMOVE_FROM_CART, {
    [propertyNames.PRESCRIPTION_SLUG]: treatmentSlug,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
  });
};

const sendTreatmentPDPViewedPage = ({
  treatmentSlug,
  inCart,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  microbiomeState,
  conditions,
  symptoms,
  aLaCareEligible,
  recommendedALaCareTreatments,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
  emptyCart,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);

  const eventProps = {
    [propertyNames.PRESCRIPTION_SLUG]: treatmentSlug,
    [propertyNames.IN_CART]: inCart,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.RECOMMENDED_A_LA_CARE_TREATMENTS]:
      recommendedALaCareTreatments || [],
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
  };

  if (selectedBundleTreatments) {
    eventProps[propertyNames.SELECTED_BUNDLE_TREATMENTS] =
      selectedBundleTreatments;
  }
  if (totalInCart) {
    eventProps[propertyNames.TOTAL_IN_CART] = totalInCart;
  }
  if (emptyCart) {
    eventProps[propertyNames.EMPTY_CART] = emptyCart;
  }

  trackCustomEvent(eventNames.TREATMENT_PDP_VIEWED_PAGE, eventProps, [
    DESTINATION_FULLSTORY,
    DESTINATION_KLAVIYO,
  ]);
};

const sendTreatmentCartViewedCart = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  warningInCart,
  totalInCart,
  page,
  emptyCart,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_VIEWED_CART, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.WARNING_IN_CART]: warningInCart,
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
    [propertyNames.EMPTY_CART]: emptyCart,
  });
};

const sendTreatmentClickedEmptyStateCTA = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  warningInCart,
  totalInCart,
  page,
  ctaText,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_EMPTY_STATE_CTA, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.WARNING_IN_CART]: warningInCart,
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
    [propertyNames.CTA_TEXT]: ctaText,
  });
};

const sendTreatmentCartClickedCartNav = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
  emptyCart,
  page,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_CART_IN_NAV, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.EMPTY_CART]: emptyCart,
    [propertyNames.PAGE]: page,
  });
};

const sendTreatmentCartClickedCheckout = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  microbiomeState,
  conditions,
  symptoms,
  selectedBundleTreatments,
  totalInCart,
  page,
  warningInCart,
  purchaseType,
}) => {
  const infectionState = getInfectionState(conditions, microbiomeState);
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_CHECKOUT, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.INFECTION_STATE]: infectionState,
    [propertyNames.SYMPTOMS]: symptoms || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
    [propertyNames.WARNING_IN_CART]: warningInCart,
    [propertyNames.PURCHASE_TYPE]: purchaseType,
  });
};

const sendTreatmentCartClickedRemoveFromCart = ({
  removedTreatments,
  removedBundle,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
  page,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_REMOVE, {
    [propertyNames.UPDATED_TREATMENTS]: removedTreatments || [],
    [propertyNames.UPDATED_BUNDLE]: removedBundle,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
  });
};

const sendTreatmentCartClickedAddToCart = ({
  addedTreatments,
  addedBundle,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
  page,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_ADD_TO_CART, {
    [propertyNames.UPDATED_TREATMENTS]: addedTreatments || [],
    [propertyNames.UPDATED_BUNDLE]: addedBundle,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
  });
};

const sendCareClickedAddAllToCart = ({
  addedTreatments,
  addedBundle,
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
}) => {
  trackCustomEvent(eventNames.CARE_CLICKED_ADD_ALL_TO_CART, {
    [propertyNames.UPDATED_TREATMENTS]: addedTreatments || [],
    [propertyNames.UPDATED_BUNDLE]: addedBundle,
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
  });
};

const sendTreatmentCartClickedViewMoreProducts = ({
  testHash,
  consultId,
  careEligible,
  eligibleTreatmentPathways,
  aLaCareEligible,
  selectedTreatments,
  selectedBundleTreatments,
  totalInCart,
  page,
  emptyCart,
  warningInCart,
}) => {
  trackCustomEvent(eventNames.TREATMENT_CART_CLICKED_VIEW_MORE_PRODUCTS, {
    [propertyNames.TEST_HASH]: testHash,
    [propertyNames.CONSULT_ID]: consultId,
    [propertyNames.CARE_ELIGIBLE]: careEligible,
    [propertyNames.ELIGIBLE_TREATMENT_PATHWAYS]:
      eligibleTreatmentPathways || [],
    [propertyNames.A_LA_CARE_ELIGIBLE]: aLaCareEligible,
    [propertyNames.SELECTED_TREATMENTS]: selectedTreatments || [],
    [propertyNames.SELECTED_BUNDLE_TREATMENTS]: selectedBundleTreatments || [],
    [propertyNames.TOTAL_IN_CART]: totalInCart,
    [propertyNames.PAGE]: page,
    [propertyNames.EMPTY_CART]: emptyCart,
    [propertyNames.WARNING_IN_CART]: warningInCart,
  });
};

// map event names to methods
const eventHandlers = {
  [eventNames.CLICKED_LEARN_MORE]: sendClickedLearnMoreEvent,
  [eventNames.CLICKED_RESEARCH_STATUS]: sendClickedResearchStatusEvent,
  [eventNames.CLICKED_SOURCE]: sendClickedSource,
  [eventNames.CLICKED_RECOMMENDATION]: sendClickedRecommendation,
  [eventNames.CLICKED_CIRCLE_NAV]: sendClickedCircleNav,
  [eventNames.CLICKED_RETEST]: sendClickedRetest,
  [eventNames.CLICKED_EMAIL_US]: sendClickedEmailUs,
  [eventNames.CLICKED_TALK_EXPERT]: sendClickedTalkToExpert,
  [eventNames.CLICKED_JOIN_COMMUNITY]: sendClickedJoinCommunity,
  [eventNames.CLICKED_PLAN_FEEDBACK]: sendClickedFeedback,
  [eventNames.VIEWED_PLAN_PAGE]: sendViewedPlanPage,
  [eventNames.PLAN_CLICKED_START_CONSULT]: sendClickedStartConsult,
  [eventNames.PLAN_CLICKED_VIEW_STEPS]: sendClickedViewSteps,
  [eventNames.PLAN_CLICKED_HIDE_TREATMENT_GUIDE]: sendClickedHideTreatmentGuide,
  [eventNames.PLAN_CLICKED_PRICING_MORE_INFO]: sendClickedPricingMoreInfo,
  [eventNames.PLAN_CLICKED_LEARN_MORE_CARE]: sendClickedLearnMoreAboutCare,
  [eventNames.HEALTH_HISTORY_FORM_VIEW_SECTION]: sendViewedHealthHistorySection,
  [eventNames.HEALTH_HISTORY_FORM_ACCEPT_SAMPLE_CRITERIA]:
    sendClickedAcceptSampleCriteria,
  [eventNames.HEALTH_HISTORY_FORM_VIEW_PAGE]: sendViewedHealthHistoryPage,
  [eventNames.HEALTH_HISTORY_FORM_VIEW_EDUCATIONAL_MOMENT]:
    sendViewedHealthHistoryEducationalMoment,
  [eventNames.HEALTH_HISTORY_FORM_CLICK_BACK]: sendClickedBackHealthHistoryPage,
  [eventNames.HEALTH_HISTORY_FORM_CLICKED_SAMPLE_TAKEN]: sendClickedSampleTaken,
  [eventNames.CLICKED_TOP_NAV]: sendClickedTopNavItem,
  [eventNames.CLICKED_ACTIVATE]: sendClickedActivateTest,
  [eventNames.ACTIVATION_MODAL_CLICKED_ACTIVATE]:
    sendClickedActivationModalActivate,
  [eventNames.CLICKED_CANT_FIND_ID]: sendClickedCantFindID,
  [eventNames.CLICKED_TEST_CARD_CTA]: sendClickedTestCardCTA,
  [eventNames.CLICKED_TEST_CARD_VIEW_CONTEXT]: sendClickedTestCardViewContext,
  [eventNames.CLICKED_TEST_CARD_SHOW_PROGRESS]: sendClickedTestCardShowProgress,
  [eventNames.CLICKED_TEST_SAMPLE_TRACKING_LINK]:
    sendClickedTestSampleTrackingLink,
  [eventNames.CLICKED_TESTS_FOR_YOU_CTA]: clickedTestsForYouCTA,
  [eventNames.CHECKLIST_CLICKED_CTA]: sendClickedChecklistCTA,
  [eventNames.CHECKLIST_CLICKED_LINK]: sendClickedChecklistLink,
  [eventNames.CLICKED_RESULTS_DOWNLOAD]: sendClickedDownloadResults,
  [eventNames.CLICKED_RESULTS_VIEW_PLAN]: sendClickedResultsViewPlan,
  [eventNames.CLICKED_RESULTS_VIEW_CARE]: sendClickedResultsGetCare,
  [eventNames.EXPANDED_RESULTS_CARD]: sendExpandedResultsCard,
  [eventNames.RESULTS_CLICKED_LEGEND]: sendClickedResultsLegend,
  [eventNames.RESULTS_CLICKED_BACK]: sendClickedResultsBack,
  [eventNames.RESULTS_CLICKED_BLOG_LINK]: sendClickedBlogLink,
  [eventNames.RESULTS_CLICKED_FILTER_TAGS]: sendClickedFilterTags,
  [eventNames.RESULTS_CLICKED_CST_ASSOCIATION]: sendClickedCSTAssociation,
  [eventNames.RESULTS_CLICKED_ASSOCIATION_DISCLAIMER]:
    sendClickedAssociationDisclaimer,
  [eventNames.RESULTS_CLICKED_BACTERIA_ASSOCIATION]:
    sendClickedBacteriaAssociation,
  [eventNames.RESULTS_EXPAND_ALL_MICROBES]: sendExpandAllMicrobes,
  [eventNames.RESULTS_CHANGED_TEST_DATE]: sendChangedTestDate,
  [eventNames.RESULTS_EXPAND_MICROBE_CATEGORY_BREAKDOWN]:
    sendExpandMicrobeCategoryBreakdown,
  [eventNames.RESULTS_EXPAND_HEALTH_HISTORY]: sendResultsExpandHealthHistory,
  [eventNames.CLICKED_MORE_INFO_COMPARE]: sendClickedMoreInfoCompare,
  [eventNames.CLICKED_UPGRADE_NOW]: sendClickedUpgradeNow,
  [eventNames.ACCOUNT_CREATION_VIEWED_PAGE]: sendAccountCreationViewedPage,
  [eventNames.ACCOUNT_CREATION_CLICKED_CTA]: sendAccountCreationClickedCTA,
  [eventNames.VERIFY_EMAIL_CLICKED_CTA]: sendVerifyEmailClickedCTA,
  [eventNames.VERIFY_EMAIL_VIEWED_PAGE]: sendVerifyEmailViewedPage,
  [eventNames.RESEARCH_CONSENT_CLICKED_CTA]: sendResearchConsentClickedCTA,
  [eventNames.RESEARCH_CONSENT_VIEWED_PAGE]: sendResearchConsentViewedPage,
  [eventNames.LOGIN_CLICKED_CTA]: sendLoginPageClickedCTA,
  [eventNames.CLICKED_MANAGE_MEMBERSHIP]: sendClickedManageMembership,
  [eventNames.ACCOUNT_EDIT_INFORMATION]: sendEditAccountInformation,
  [eventNames.CLICKED_LOGOUT]: sendClickedLogout,
  [eventNames.TREATMENT_VIEW_PAGE]: sendViewedTreatmentPage,
  [eventNames.TREATMENT_EXPAND_PAGE_SECTION]: sendTreatmentExpandSection,
  [eventNames.TREATMENT_EXPAND_PRESCRIPTION]: sendTreatmentExpandPrescription,
  [eventNames.TREATMENT_ASK_QUESTION]: sendTreatmentClickedQuestion,
  [eventNames.TREATMENT_CLICKED_FAQ]: sendTreatmentClickedFAQ,
  [eventNames.TREATMENT_CLICKED_COACHING]: sendTreatmentClickedCoaching,
  [eventNames.TREATMENT_CLICKED_RETEST]: sendTreatmentClickedRetest,
  [eventNames.TREATMENT_SELECT_START_DATE]: sendSelectedTreatmentStartDate,
  [eventNames.TREATMENT_CLICKED_DOWNLOAD_PDF]: sendClickedDownloadTreatmentPDF,
  [eventNames.TREATMENT_CLICKED_ADD_CALENDAR]: sendClickedAddTreatmentCalendar,
  [eventNames.TREATMENT_CHANGE_WEEK]: sendTreatmentChangedWeek,
  [eventNames.TREATMENT_RESET_CALENDAR]: sendTreatmentResetCalendar,
  [eventNames.TREATMENT_EXPAND_FAQ]: sendTreatmentExpandFAQ,
  [eventNames.CONSULT_INTAKE_CLICKED_EXIT_CTA]: sendConsultIntakeClickedExitCTA,
  [eventNames.CONSULT_INTAKE_CLICKED_ALT_PHARMACY]:
    sendConsultIntakeClickedAltPharmacy,
  [eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS]:
    sendCareClickedViewPastConsultations,
  [eventNames.CARE_CLICKED_TALK_TO_EXPERT]: sendCareClickedTalkToExpert,
  [eventNames.CARE_CLICKED_VIEW_COMMUNITY_RECS]:
    sendCareClickedViewCommunityRecs,
  [eventNames.CARE_CLICKED_JOIN_COMMUNITY]: sendCareClickedJoinCommunity,
  [eventNames.CARE_CLICKED_BLOG_ARTICLE]: sendCareClickedBlogArticle,
  [eventNames.CARE_CLICKED_VIEW_PLAN]: sendCareClickedViewPlan,
  [eventNames.CARE_CLICKED_VIEW_INTAKE]: sendCareClickedViewIntake,
  [eventNames.CARE_CLICKED_VIEW_TREATMENT_PLAN]:
    sendCareClickedViewTreatmentPlan,
  [eventNames.CARE_CLICKED_VIEW_SAMPLE_PLAN]:
    sendCareClickedViewSampleTreatmentPlan,
  [eventNames.CARE_CLICKED_SEE_ALL_ARTICLES]: sendCareClickedSeeAllArticles,
  [eventNames.PAST_CONSULTATIONS_CLICKED_BACK]:
    sendCarePastConsultationsClickedBack,
  [eventNames.PAST_CONSULTATIONS_CLICKED_VIEW_TREATMENT]:
    sendCarePastConsultationsClickedViewTreatment,
  [eventNames.PAST_CONSULTATIONS_CLICKED_ARROW_NAV]:
    sendCarePastConsultationsClickedArrowNav,
  [eventNames.MESSAGING_CLICKED_BACK]: sendMessagingClickedBack,
  [eventNames.MESSAGING_CLICKED_SUPPORT]: sendMessagingClickedSupport,
  [eventNames.MESSAGING_CLICKED_SUBMIT_MESSAGE]:
    sendMessagingClickedSubmitMessage,
  [eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES]: sendCareClickedViewPastMessages,
  [eventNames.MESSAGING_CLICKED_TEST_RESULTS]: sendMessagingClickedTestResults,
  [eventNames.MESSAGING_CLICKED_PROVIDER_INTAKE]:
    sendMessagingClickedProviderIntake,
  [eventNames.CARE_CLICKED_MESSAGE_PROVIDER]: sendCareClickedMessageProvider,
  [eventNames.CARE_CLICKED_VIEW_MESSAGE]: sendCareClickedViewMessage,
  [eventNames.CARE_ASK_QUESTION]: sendCareClickedQuestion,
  [eventNames.CARE_CLICKED_GET_CARE_FOOTER]: sendCareClickedGetCareFooter,
  [eventNames.CARE_CLICKED_VIEW_WHITE_PAPER]: sendCareClickedViewWhitePaper,
  [eventNames.CARE_CLICKED_TRACK_ORDER]: sendCareClickedTrackOrder,
};

export {
  eventHandlers,
  eventNames,
  sendClickedActivateTest,
  sendClickedActivationModalActivate,
  sendClickedBacteriaAssociation,
  sendClickedChecklistCTA,
  sendClickedDownloadResults,
  sendConsultIntakeViewSection,
  sendConsultIntakeClickedAdd,
  sendConsultIntakeClickedCheckout,
  sendConsultIntakeViewedUpsell,
  sendExpandedResultsCard,
  sendShowedIntercomWidget,
  sendTestsClickedBlogLink,
  sendTestsClickedJoinCommunity,
  sendTreatmentChangedWeek,
  sendTreatmentResetCalendar,
  sendTreatmentExpandFAQ,
  sendDeletePeriod,
  sendHealthHistoryUpsellClickedCTA,
  sendHealthHistorySymptomsUpsellViewed,
  sendHealthHistorySymptomsUpsellClickedAdd,
  sendHealthHistorySymptomsUpsellCheckout,
  sendConsultIntakeViewedLoading,
  sendConsultIntakeViewedLoadingTimeout,
  sendConsultIntakeLoadingScreenClickedBack,
  sendConsultIntakeLoadingTimeoutClickedCTA,
  sendOpenPeriodModal,
  sendPlanClickedL1ResultsLink,
  sendSavePeriod,
  sendViewedChecklist,
  sendViewedHealthHistoryPage,
  sendViewedHealthHistoryEducationalMoment,
  sendViewedHealthHistorySection,
  sendClickedBackHealthHistoryPage,
  sendViewedComparePage,
  sendViewedComparePopUp,
  sendViewedMyResultsPage,
  sendViewedMyAccountPage,
  sendAccountCreationViewedPage,
  sendAccountCreationClickedCTA,
  sendVerifyEmailViewedPage,
  sendVerifyEmailClickedCTA,
  sendResearchConsentViewedPage,
  sendResearchConsentClickedCTA,
  sendViewedMyTestsPage,
  sendViewedPlanPage,
  sendViewedTreatmentPage,
  sendCareViewedPage,
  sendClickedTestCardShowProgress,
  sendLoginViewedPage,
  sendClickedTestSampleTrackingLink,
  clickedTestsForYouCTA,
  sendCareClickedBlogArticle,
  sendCareViewPastConsultationsPage,
  sendCareExpandFAQ,
  sendConsultIntakeClickedExitCTA,
  sendMessagingViewThread,
  sendMessagingViewMessagesHome,
  sendSamplePlanClickedBack,
  sendSamplePlanClickedGetCare,
  sendSamplePlanViewPage,
  sendSamplePlanViewedSection,
  sendProviderViewedRegistrationPage,
  sendProviderClickedAgreeToTerms,
  sendProviderClickedViewTerms,
  sendProviderClickedContinueRegistration,
  sendProviderClickedSignInFromRegistration,
  sendProviderViewedIntakePage,
  sendProviderClickedContinueOnIntake,
  sendProviderViewedOrderPage,
  sendProviderViewedResourcesPage,
  sendProviderClickedOrderFromResources,
  sendProviderViewedTestsPage,
  sendProviderClickedJoinCommunity,
  sendProviderClickedResourceLink,
  sendProviderPlacedOrder,
  sendProviderClickedFilterText,
  sendProviderClickedDownloadResults,
  sendPatientRespondedToProviderAuthorization,
  sendRemoveAntibioticsClicked,
  sendCollapsibleMenuToggled,
  sendExpandWhatYouGetWithEvvy,
  sendExpandWhyOurTreatmentsWork,
  sendClickedThroughAntibioticsDisclaimer,
  sendClickedCareCTA,
  sendCareSelectedTreatmentOption,
  sendRecommendedTreatmentViewLoadingPage,
  sendRecommendedTreatmentViewPage,
  sendRecommendedTreatmentClickedBuy,
  sendRecommendedTreatmentExpandPrescriptionInfo,
  sendClickedViewExpandedPCRModalFromAccount as sendClickedViewExpandedPCRModal,
  sendClickedUpgradeMembership,
  sendClickedDowngradeMembership,
  sendClickedManageMembership,
  sendClickedSTIIntakeCTA,
  sendSTIPastTreatmentCTAClicked,
  sendClickedCancelMembership,
  sendSelectedCancellationReason,
  sendSubmitCancelMembership,
  sendClickedViewExpandedPCRModalFromHealthHistory,
  sendClickedEditTreatment,
  sendEditTreatmentSelectType,
  sendEditTreatmentClickedNext,
  sendEditTreatmentSelectReason,
  sendEditTreatmentClickedBack,
  sendEditTreatmentSelectCadenceOption,
  sendViewedAnnouncements,
  sendViewedRefillsPage,
  sendClickedAddRefillToCart,
  sendClickedRemoveRefillFromCart,
  sendClickedRetestForPrescription,
  sendClickedCheckoutForRefills,
  sendVisitedRefillsFromHomePage,
  sendVisitedRefillsFromCarePage,
  sendViewedPrescriptionDetails,
  sendUserLoggedIn,
  sendClickedViewExpandedPCRModalFromProviderOrder,
  sendCareClickedALaCareOnRamp,
  sendViewedShopTreatmentsPage,
  sendShopTreatmentsClickedTreatment,
  sendShopTreatmentsClickedTreatmentProgramOnramp,
  sendShopTreatmentsClickedRetestOnramp,
  sendShopTreatmentsClickedAddToCart,
  sendShopTreatmentsClickedRemoveFromCart,
  sendTreatmentPDPViewedPage,
  sendTreatmentCartClickedCheckout,
  sendTreatmentCartClickedViewMoreProducts,
  sendTreatmentCartViewedCart,
  sendTreatmentClickedEmptyStateCTA,
  sendTreatmentCartClickedCartNav,
  sendTreatmentCartClickedRemoveFromCart,
  sendTreatmentCartClickedAddToCart,
  sendCareClickedAddAllToCart,
};

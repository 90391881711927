import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import { useParams, useNavigate } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import {
  sendHealthHistoryUpsellClickedCTA,
  sendViewedHealthHistoryPage,
} from "../../../utils/analytics/customEventTracking";
import { testsService } from "../../../services/tests";
import { healthContextService } from "../../../services/healthContext";
import { CircleCheckmark } from "../../../components/common/circleCheckmark";

import { ReactComponent as Heart } from "../../../components/plan_unfurled/icons/heart.svg";

// Images
import TwoGalsCircleImg from "../../../components/common/images/twoGalsCircle.png";
import { HealthContextFields } from "../../../types/test";
import { Page, getHealthHistoryURL } from "../../../utils/healthHistory";
import ProgressBar from "../../../components/healthHistoryV2/progressBar";

const TITLE = "You're eligible for our<br>*Expanded PCR Panel*";
const BUTTON_CONTINUE = "CONTINUE WITH EXISTING TEST";
const BUTTON_UPGRADE = "ADD ADDITIONAL PANELS";
const RECOMMENDED = "Recommended";

const SUBTITLE = "Run 3 additional tests on your sample for";
const DESCRIPTION_1 =
  "Add PCR testing for all of the below with the same swab you have at home — plus get prelim results in 1-3 business days.";
const INCLUDED_ITEMS = [
  {
    title: "4 STIs",
    subtitle: {
      symptomatic: " – so you can rule them out as possible causes of symptoms",
      asymptomatic: " – so you can stay proactive about your health",
    },
    description:
      "Chlamydia, Gonorrhea, *Trichomonas vaginalis* (“trich”), *Mycoplasma genitalium*",
  },
  {
    title: "11 common microbes",
    subtitle: {
      symptomatic:
        " with microbial loads – so you can get preliminary answers in 1-3 days",
      asymptomatic:
        " with microbial loads – so you can see if anything is present in higher or lower amounts than is normal",
    },
    description:
      "*Candida albicans, Gardnerella vaginalis, Lactobacillus crispatus, Lactobacillus gasseri, Mobiluncus curtisii, Mobiluncus mulieris, Mycoplasma hominis, Prevotella bivia, Streptococcus agalactiae (group B), Ureaplasma parvum, Ureaplasma urealyticum.*",
    description_2:
      "Note: These microbes are also tested for via metagenomics in our Vaginal Health Test, but the Expanded PCR Panel tests for them via PCR with a 1-3 day turnaround",
  },
  {
    title: "10 Antibiotic Resistances",
    subtitle: {
      symptomatic: " to help guide future treatment decisions",
      asymptomatic: " to help guide future treatment decisions",
    },
    description:
      "Quinolone, Methicillin, Vancomycin, Beta-lactam, Carbapenem, Macrolide, Aminoglycoside, Tetracycline, Bactrim, Extended Spectrum Beta Lactamase CTX-M",
  },
];
const DISCLAIMER_COPY_1 =
  "This upgrade only applies to this test, and does not impact the processing time of your final vaginal microbiome results. ";
const DISCLAIMER_COPY_2 =
  "Note that this panel cannot be added after you submit this health history form.";

const TAT_COPY = "Prelim Results in 1-3 Days";
const FSA_HSA_COPY = "HSA/FSA Eligible";

const SYMPTOMATIC_TITLE = "From our coaching team";
const SYMPTOMATIC_SUBTITLE = "Given your symptoms...";
const SYMPTOMATIC_DESCRIPTION =
  "We highly recommend that you add our Expanded PCR Panel to your test so you can rule out STIs and get microbial loads for the microbes that can often cause symptoms like yours. ";
const RELEVANT_SYMPTOMS = "Relevant symptoms: ";

export const Upgrade = ({
  healthContext,
}: {
  healthContext?: HealthContextFields;
}) => {
  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [healthContextComplete, setHealthContextComplete] = useState(false);

  // route params
  const { testHash } = useParams();
  const navigate = useNavigate();

  const discountedPrice = 89;

  const isSymptomatic = allSymptoms.length > 0;

  // effects
  useEffect(() => {
    const fetchHealthContext = () => {
      healthContextService.getContext(
        testHash,
        (response: any) => {
          const allAdditionalSymptoms = response.data.all_additional_symptoms;
          setAllSymptoms(allAdditionalSymptoms);
          setHealthContextComplete(response.data.is_complete);

          sendViewedHealthHistoryPage({
            section: "upgrade",
            page: "additional",
            questionGroup: "sample",
            testHash: testHash,
            isSymptomatic: allAdditionalSymptoms.length > 0,
            version: healthContext?.is_version_2 ? 2 : 1,
          });
        },
        (error: any) => {
          console.error(error);
          setError(
            "Error fetching health context. Please try refreshing the page"
          );
        }
      );
    };

    fetchHealthContext();
  }, [testHash]);

  // actions
  const proceedToHistoryPage = () => {
    // go to history page
    setLoading(true);
    healthContext?.is_version_2
      ? navigate(getHealthHistoryURL(testHash, "health-profile"))
      : navigate(`/tests/${testHash}/history/`);
  };

  async function goToCheckout() {
    setLoading(true);
    if (testHash) {
      try {
        const response = await testsService.asyncCreateTestCheckoutURL(
          testHash
        );
        const checkoutURL = response.data.checkout_url;
        window.location = checkoutURL;
      } catch (error) {
        console.error(error);
        setError("Error creating checkout URL");
      }
    }
  }

  return healthContext?.is_version_2 ? (
    <div className="bg-evvy-cream min-h-screen">
      <ProgressBar currentSection={"sample_confirm"} />
      <div className="text-center p-4 lg:p-8 lg:font-medium text-md lg:text-md">
        Test ID: {testHash}
      </div>
      {testHash && (
        <UpgradeControl
          testHash={testHash}
          isSymptomatic={isSymptomatic}
          allSymptoms={allSymptoms}
          discountedPrice={discountedPrice}
          loading={loading}
          error={error}
          healthContextComplete={healthContextComplete}
          proceedToHistoryPage={proceedToHistoryPage}
          goToCheckout={goToCheckout}
        />
      )}
    </div>
  ) : (
    <Layout title="Test | Additional" bgClass="bg-evvy-cream">
      <div className="text-center p-4 lg:p-8 lg:font-medium text-md lg:text-md">
        Test ID: {testHash}
      </div>
      {testHash && (
        <UpgradeControl
          testHash={testHash}
          isSymptomatic={isSymptomatic}
          allSymptoms={allSymptoms}
          discountedPrice={discountedPrice}
          loading={loading}
          error={error}
          healthContextComplete={healthContextComplete}
          proceedToHistoryPage={proceedToHistoryPage}
          goToCheckout={goToCheckout}
        />
      )}
    </Layout>
  );
};

const UpgradeControl = ({
  testHash,
  isSymptomatic,
  allSymptoms,
  discountedPrice,
  proceedToHistoryPage,
  goToCheckout,
  loading,
  error,
  healthContextComplete,
}: {
  testHash: string;
  isSymptomatic: boolean;
  allSymptoms: string[];
  discountedPrice: number;
  proceedToHistoryPage: () => void;
  goToCheckout: () => void;
  loading: boolean;
  error: string;
  healthContextComplete: boolean;
}) => {
  return (
    <>
      <h3 className="mx-auto text-center px-14">
        <Markdown>{TITLE}</Markdown>
      </h3>
      <div className="mx-auto grid lg:grid-cols-8 lg:gap-5 max-w-[1100px]">
        <div className="lg:col-start-3 lg:col-span-4 pb-10 px-4 lg:px-0">
          <div className="bg-white rounded-t-lg py-5 px-4 lg:px-8 lg:py-8">
            <div className="rounded-full bg-evvy-starfish w-fit mx-auto px-4 py-1 leading-5 my-2 uppercase text-white text-[12px] font-semibold">
              {RECOMMENDED}
            </div>
            <div className="text-2xl lg:text-[20px] medium text-center">
              {SUBTITLE}
              <span className="ml-1 text-dv-pond">{`$${discountedPrice}`}</span>
            </div>
            <div className="b2 regular my-4 text-center px-4 lg:px-10">
              {DESCRIPTION_1}
            </div>
            <div className="rounded-lg bg-evvy-silverfish bg-opacity-50 px-4 py-6 my-6 flex flex-col gap-4">
              {INCLUDED_ITEMS.map((item, index) => (
                <div className="flex gap-2" key={`included-${index}`}>
                  <div className="rounded-full bg-highlighter-yellow w-8 h-8 aspect-square flex justify-center items-center font-bold">
                    {index + 1}
                  </div>
                  <div className="flex flex-col w-full gap-2 px-2">
                    <div className="b2">
                      <span className="semibold">{item.title}</span>
                      <span>
                        {isSymptomatic
                          ? item.subtitle.symptomatic
                          : item.subtitle.asymptomatic}
                      </span>
                    </div>
                    <Markdown className="text-sm text-gray-500">
                      {item.description}
                    </Markdown>
                    {item.description_2 && (
                      <Markdown className="text-sm text-gray-500">
                        {item.description_2}
                      </Markdown>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-6 b2">
              <span>{DISCLAIMER_COPY_1}</span>
              <br className="lg:hidden" />
              <br className="lg:hidden" />
              <span className="underline underline-offset-4">
                {DISCLAIMER_COPY_2}
              </span>
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <div className="grid grid-cols-4 gap-3 pb-3">
              <div className="col-span-4 lg:col-span-2 order-2 lg:order-1">
                <button
                  disabled={loading}
                  onClick={() => {
                    sendHealthHistoryUpsellClickedCTA({
                      testHash,
                      isSymptomatic,
                      ctaText: "continue",
                    });
                    proceedToHistoryPage();
                  }}
                  className="t4 bg-evvy-white text-evvy-black py-6 w-full rounded-sm font-semibold tracking-wider border border-evvy-black focus:outline-none"
                >
                  {BUTTON_CONTINUE}
                </button>
              </div>
              <div className="col-span-4 lg:col-span-2 order-1 lg:order-2">
                <button
                  disabled={loading || healthContextComplete}
                  onClick={() => {
                    sendHealthHistoryUpsellClickedCTA({
                      testHash,
                      ctaText: "upgrade",
                      isSymptomatic: isSymptomatic,
                    });
                    goToCheckout();
                  }}
                  className="t4 bg-evvy-blue text-evvy-black py-6 w-full rounded-sm font-semibold tracking-wider border border-evvy-blue focus:outline-none"
                >
                  {BUTTON_UPGRADE}
                </button>
              </div>
            </div>

            <div className="lg:flex justify-center pt-5 mt-3 border-t space-y-3 lg:space-y-0">
              <div className="flex my-auto justify-center">
                <CircleCheckmark
                  className="h-4 w-4"
                  circleClassName="stroke-marine stoke-3"
                  checkmarkClassName="stroke-marine"
                />{" "}
                <span className="ml-2">{TAT_COPY}</span>
              </div>
              <div className="flex my-auto justify-center lg:ml-5">
                <CircleCheckmark
                  className="h-4 w-4"
                  circleClassName="stroke-marine stoke-3"
                  checkmarkClassName="stroke-marine"
                />{" "}
                <span className="ml-2">{FSA_HSA_COPY}</span>
              </div>
            </div>
          </div>
          <div
            className="rounded-b-lg p-4"
            style={{
              backgroundImage: `url('/images/graphics/card-header-purple.jpg')`,
            }}
          />
        </div>

        {isSymptomatic && (
          <div className="lg:col-span-2 px-4 lg:px-0 mb-10">
            <div className="bg-evvy-pine rounded-t-lg py-3 px-5 flex items-center">
              <img
                src={TwoGalsCircleImg}
                loading="lazy"
                alt="Health coaches"
                className="h-8"
              />
              <div className="text-evvy-white medium ml-2 b2">
                {SYMPTOMATIC_TITLE}
              </div>
            </div>
            <div className="bg-highlighter-mint-dull py-4 px-5 rounded-b-lg">
              <div className="flex">
                <Heart />
                <div className="ml-1 italic b2 medium">
                  {SYMPTOMATIC_SUBTITLE}
                </div>
              </div>
              <div className="b3 py-3">{SYMPTOMATIC_DESCRIPTION} </div>
              <div className="border-t border-gray-300 py-2 text-[12px] italic">
                {`${RELEVANT_SYMPTOMS}${allSymptoms.join(", ")}`}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

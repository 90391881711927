import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Formik, Field, Form } from "formik";
import ReactTooltip from "react-tooltip";
import Markdown from "markdown-to-jsx";

import { indexRadioStyles, indexCheckboxStyles } from "../../../utils/colors";
import { uncheckNoneChangeHandler } from "../../../utils/answerUtils";
import { getQuestionTitle } from "../../../utils/questions";
import BlueRectangularButton from "../../../components/common/blueRectangularButton";
import { sendConsultIntakeViewSection } from "../../../utils/analytics/customEventTracking";
import UpdateReminderMsg from "../../../components/care/consultIntake/updateReminderMessage";
import { intakeDisclaimerCopy } from "../../../components/care/constants";

const PregnancyQuestions = ({ consultIntake, submitPage, loading }) => {
  const [error, setError] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  // for tooltips
  const pregnancyStatusQuestion = useRef(null);
  const menopauseStatusQuestion = useRef(null);
  const medicalConditionsQuestion = useRef(null);
  const medicalConditionsOtherQuestion = useRef(null);
  const diabetesControlledQuestion = useRef(null);
  const stiTreatedQuestion = useRef(null);
  const stiTreatmentsQuestion = useRef(null);
  const stiTreatmentsOtherQuestion = useRef(null);
  const currentSymptomsQuestion = useRef(null);
  const postPeriodSymptomsQuestion = useRef(null);
  const hormonalSymptomsQuestion = useRef(null);
  const severeSymptomsQuestion = useRef(null);

  const allQuestions = {
    currently_pregnant: pregnancyStatusQuestion,
    menopause_status: menopauseStatusQuestion,
    medical_conditions: medicalConditionsQuestion,
    medical_conditions_other: medicalConditionsOtherQuestion,
    sti_treated: stiTreatedQuestion,
    diabetes_control_status: diabetesControlledQuestion,
    sti_treatments: stiTreatmentsQuestion,
    sti_treatments_other: stiTreatmentsOtherQuestion,
    current_symptoms: currentSymptomsQuestion,
    post_period_symptoms: postPeriodSymptomsQuestion,
    hormonal_symptoms: hormonalSymptomsQuestion,
    severe_symptoms: severeSymptomsQuestion,
  };

  /* Effects */
  // First render
  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: consultIntake?.consult?.test_hash,
      section: "medical",
    });
  }, []);

  // utils
  const getError = (key, errors, apiErrors) => {
    // util to show error, either from frontend validation errors or from the API, which comes back as an array
    if (errors[key]) {
      return errors[key];
    } else if (apiErrors[key] && apiErrors[key].length) {
      return apiErrors[key][0];
    }
  };

  const populateInitialValues = () => {
    return {
      currently_pregnant: consultIntake?.currently_pregnant || "",
      currently_breastfeeding: consultIntake?.currently_breastfeeding || "",
      menopause_status:
        consultIntake?.menopause_status ||
        consultIntake?.past_health_history?.hormonal_menopause ||
        "",
      medical_conditions: consultIntake?.medical_conditions.length
        ? consultIntake?.medical_conditions
        : consultIntake?.past_health_history?.related_diagnoses || [],
      medical_conditions_other:
        consultIntake?.medical_conditions_other ||
        consultIntake?.past_health_history?.related_diagnoses_other ||
        "",
      diabetes_control_status: consultIntake?.diabetes_control_status || "",
      sti_treated: consultIntake?.sti_treated || "",
      sti_treatments: consultIntake?.sti_treatments || [],
      sti_treatments_other: consultIntake?.sti_treatments_other || "",
      current_symptoms: consultIntake?.current_symptoms || [],
      post_period_symptoms: consultIntake?.post_period_symptoms || "",
      hormonal_symptoms: consultIntake?.hormonal_symptoms.length
        ? consultIntake?.hormonal_symptoms
        : consultIntake?.past_health_history?.hormonal_symptoms || [],
      severe_symptoms: consultIntake?.severe_symptoms || "",
    };
  };

  return (
    <div className="block w-full mt-8 sm:mt-10">
      <Helmet>
        <title>Care | Consult | Intake | Medical Questions</title>
      </Helmet>
      <div className="max-w-2xl px-4">
        <h3 className="text-center">Medical Questions</h3>
        {error ? (
          <div className="bg-coral p-2 px-3 font-medium rounded-sm mt-6">
            {error}
          </div>
        ) : (
          ""
        )}
        {/* message reminding user to review their answers */}
        {consultIntake?.past_health_history && !consultIntake?.submitted_at ? (
          <UpdateReminderMsg />
        ) : (
          ""
        )}
        <Formik
          initialValues={populateInitialValues()}
          validate={(values) => {
            var errors = {};
            Object.keys(allQuestions).forEach((key) =>
              ReactTooltip.hide(allQuestions[key].current)
            );
            ReactTooltip.rebuild();
            // required questions
            var requiredQuestions = [
              "currently_pregnant",
              "menopause_status",
              "severe_symptoms",
              "post_period_symptoms",
            ];
            // only require sti questions if they were sti positive
            if (consultIntake.consult.sti_positive) {
              requiredQuestions.push("sti_treated");
            }
            var requiredMultiselects = [
              "medical_conditions",
              "current_symptoms",
              "hormonal_symptoms",
            ];
            var requiredOtherQuestions = ["medical_conditions"];

            // if sti treated, require sti treatments question
            if (values.sti_treated === "YES") {
              requiredMultiselects.push("sti_treatments");
              requiredOtherQuestions.push("sti_treatments");
            }
            requiredQuestions.forEach((key) => {
              if (!values[key]) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current); // manually show, without requiring hover
              }
            });
            // required multiselect questions (multi select, needs at least 1)
            requiredMultiselects.forEach((key) => {
              if (!values[key].length) {
                errors[key] = "This is a required question";
                ReactTooltip.show(allQuestions[key].current);
              }
            });

            // required "other" context if "Other" is selected
            requiredOtherQuestions.forEach((key) => {
              if (values[key].includes("OT") && !values[`${key}_other`]) {
                errors[`${key}_other`] = "This is a required question";
                ReactTooltip.show(allQuestions[`${key}_other`].current);
              }
              // require "diabetes" context if diabetes type 1/2 selected
              if (
                (values[key].includes("D1") || values[key].includes("D2")) &&
                !values["diabetes_control_status"]
              ) {
                errors["diabetes_control_status"] =
                  "This is a required question";
                ReactTooltip.show(
                  allQuestions["diabetes_control_status"].current
                );
              }
            });

            return errors;
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setError(null);
            submitPage(values, (response) => {
              if (typeof response === "object") {
                setApiErrors(response);
                Object.keys(response).forEach((k) => {
                  ReactTooltip.show(allQuestions[k].current);
                });
              } else {
                setError(response || "Error saving consult intake");
              }
            });
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <div className="flex-1 bg-evvy-white p-5 mb-3 sm:p-8 rounded-lg">
                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("currently_pregnant", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("currently_pregnant", errors, apiErrors)}
                  data-for="currently_pregnant"
                  ref={pregnancyStatusQuestion}
                >
                  <ReactTooltip
                    id="currently_pregnant"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_currently_pregnant")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  {/* <p>Evvy's treatment protocols are not designed to be taken when you are currently pregnant.</p> */}
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.currently_pregnant.map((o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <>
                          <Field
                            type="radio"
                            name="currently_pregnant"
                            value={o[0]}
                            onChange={() => {
                              setFieldValue("currently_pregnant", o[0]);
                              setFieldValue("currently_breastfeeding", o[0]);
                            }}
                            className={indexRadioStyles(i)}
                          />
                        </>
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("menopause_status", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("menopause_status", errors, apiErrors)}
                  data-for="menopause_status"
                  ref={menopauseStatusQuestion}
                >
                  <ReactTooltip
                    id="menopause_status"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("hormonal_menopause")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.menopause_status.map((o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="menopause_status"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("medical_conditions", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("medical_conditions", errors, apiErrors)}
                  data-for="medical_conditions"
                  ref={medicalConditionsQuestion}
                >
                  <ReactTooltip
                    id="medical_conditions"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("related_diagnoses")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.medical_conditions.map((o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="medical_conditions"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "medical_conditions",
                            fieldValues: values.medical_conditions,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                {values.medical_conditions.includes("OT") ? (
                  <div
                    className={`p-4 border rounded-md ${
                      getError("medical_conditions_other", errors, apiErrors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "medical_conditions_other",
                      errors,
                      apiErrors
                    )}
                    data-for="medical_conditions_other"
                    ref={medicalConditionsOtherQuestion}
                  >
                    <ReactTooltip
                      id="medical_conditions_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("related_diagnoses_other")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="medical_conditions_other"
                      as="input"
                      className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                      placeholder="Your Answer"
                      autoComplete="off"
                    />
                  </div>
                ) : (
                  ""
                )}

                {values.medical_conditions.includes("D1") ||
                values.medical_conditions.includes("D2") ? (
                  <div
                    className={`mt-2 p-4 border rounded-md ${
                      getError("diabetes_control_status", errors, apiErrors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError(
                      "diabetes_control_status",
                      errors,
                      apiErrors
                    )}
                    data-for="diabetes_control_status"
                    ref={diabetesControlledQuestion}
                  >
                    <ReactTooltip
                      id="diabetes_control_status"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {getQuestionTitle("related_diagnoses_diabetes")}{" "}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <div role="group" aria-labelledby="checkbox-group">
                      {consultIntake?._options.diabetes_control_status.map(
                        (o, i) => (
                          <label
                            className="block mb-3 cursor-pointer flex items-center"
                            key={o[0]}
                          >
                            <Field
                              type="radio"
                              name="diabetes_control_status"
                              value={o[0]}
                              className={indexRadioStyles(i)}
                            />
                            <span className="ml-2">{o[1]}</span>
                          </label>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {consultIntake.consult.sti_positive && (
                  <>
                    <div
                      className={`mt-2 p-4 border rounded-md ${
                        getError("sti_treated", errors, apiErrors)
                          ? "border-coral"
                          : "border-transparent"
                      }`}
                      data-tip={getError("sti_treated", errors, apiErrors)}
                      data-for="sti_treated"
                      ref={stiTreatedQuestion}
                    >
                      <ReactTooltip
                        id="sti_treated"
                        effect="solid"
                        place="left"
                        type="error"
                        backgroundColor="#FFA684"
                        textColor="#11161A"
                        className="rounded-xs py-20"
                      />
                      <h4 className="t1">
                        {getQuestionTitle("intake_sti_treated")}{" "}
                        <span className="text-evvy-blue">*</span>
                      </h4>
                      <div role="group" aria-labelledby="checkbox-group">
                        {consultIntake?._options.sti_treated.map((o, i) => (
                          <label
                            className="block mb-3 cursor-pointer flex items-center"
                            key={o[0]}
                          >
                            <Field
                              type="radio"
                              name="sti_treated"
                              value={o[0]}
                              className={indexRadioStyles(i)}
                            />
                            <span className="ml-2">{o[1]}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                    {values.sti_treated === "YES" && (
                      <div
                        className={`mt-2 p-4 border rounded-md ${
                          getError("sti_treatments", errors, apiErrors)
                            ? "border-coral"
                            : "border-transparent"
                        }`}
                        data-tip={getError("sti_treatments", errors, apiErrors)}
                        data-for="sti_treatments"
                        ref={stiTreatmentsQuestion}
                      >
                        <ReactTooltip
                          id="sti_treatments"
                          effect="solid"
                          place="left"
                          type="error"
                          backgroundColor="#FFA684"
                          textColor="#11161A"
                          className="rounded-xs py-20"
                        />
                        <h4 className="t1">
                          {getQuestionTitle("intake_sti_treatments")}{" "}
                          <span className="text-evvy-blue">*</span>
                        </h4>
                        <p>Please select all that apply</p>
                        <div role="group" aria-labelledby="checkbox-group">
                          {consultIntake?._options.sti_treatments.map(
                            (o, i) => (
                              <label
                                className="block mb-3 cursor-pointer flex items-center"
                                key={o[0]}
                              >
                                <Field
                                  type="checkbox"
                                  name="sti_treatments"
                                  value={o[0]}
                                  className={indexCheckboxStyles(i)}
                                />
                                <span className="ml-2">{o[1]}</span>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    )}
                    {values.sti_treated === "YES" &&
                      values.sti_treatments.includes("OT") && (
                        <div
                          className={`p-4 border rounded-md ${
                            getError("sti_treatments_other", errors, apiErrors)
                              ? "border-coral"
                              : "border-transparent"
                          }`}
                          data-tip={getError(
                            "sti_treatments_other",
                            errors,
                            apiErrors
                          )}
                          data-for="sti_treatments_other"
                          ref={stiTreatmentsOtherQuestion}
                        >
                          <ReactTooltip
                            id="sti_treatments_other"
                            effect="solid"
                            place="left"
                            type="error"
                            backgroundColor="#FFA684"
                            textColor="#11161A"
                            className="rounded-xs py-20"
                          />
                          <h4 className="t1">
                            {getQuestionTitle("intake_sti_treatments_other")}{" "}
                            <span className="text-evvy-blue">*</span>
                          </h4>
                          <Field
                            name="sti_treatments_other"
                            as="input"
                            className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                            placeholder="Your Answer"
                            autoComplete="off"
                          />
                        </div>
                      )}
                  </>
                )}

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("current_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("current_symptoms", errors, apiErrors)}
                  data-for="current_symptoms"
                  ref={currentSymptomsQuestion}
                >
                  <ReactTooltip
                    id="current_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_current_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.current_symptoms.map((o, i) => (
                      <label
                        className="block mb-2 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="current_symptoms"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "current_symptoms",
                            fieldValues: values.current_symptoms,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("post_period_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("post_period_symptoms", errors, apiErrors)}
                  data-for="post_period_symptoms"
                  ref={postPeriodSymptomsQuestion}
                >
                  <ReactTooltip
                    id="post_period_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_post_period_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.post_period_symptoms.map(
                      (o, i) => (
                        <label
                          className="block mb-3 cursor-pointer flex items-center"
                          key={o[0]}
                        >
                          <Field
                            type="radio"
                            name="post_period_symptoms"
                            value={o[0]}
                            className={indexRadioStyles(i)}
                          />
                          <span className="ml-2">
                            <Markdown>{o[1]}</Markdown>
                          </span>
                        </label>
                      )
                    )}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("hormonal_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("hormonal_symptoms", errors, apiErrors)}
                  data-for="hormonal_symptoms"
                  ref={hormonalSymptomsQuestion}
                >
                  <ReactTooltip
                    id="hormonal_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("hormonal_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <p>Please select all that apply</p>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.hormonal_symptoms.map((o, i) => (
                      <label
                        className="mb-3 last:mb-0 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="checkbox"
                          name="hormonal_symptoms"
                          value={o[0]}
                          className={indexCheckboxStyles(i)}
                          onChange={uncheckNoneChangeHandler({
                            handleChange,
                            setFieldValue,
                            fieldName: "hormonal_symptoms",
                            fieldValues: values.hormonal_symptoms,
                          })}
                        />
                        <span className="ml-2">{o[1]}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div
                  className={`mt-2 p-4 border rounded-md ${
                    getError("severe_symptoms", errors, apiErrors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("severe_symptoms", errors, apiErrors)}
                  data-for="severe_symptoms"
                  ref={severeSymptomsQuestion}
                >
                  <ReactTooltip
                    id="severe_symptoms"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {getQuestionTitle("intake_severe_symptoms")}{" "}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <div role="group" aria-labelledby="checkbox-group">
                    {consultIntake?._options.severe_symptoms.map((o, i) => (
                      <label
                        className="block mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="severe_symptoms"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">
                          <Markdown>{o[1]}</Markdown>
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-coral p-2 px-3 font-medium rounded-md my-4 text-center">
                  {errors && Object.keys(errors).length > 0
                    ? "Please fix the errors above to continue"
                    : intakeDisclaimerCopy}
                </div>
              </div>
              <div className="flex mt-6">
                <BlueRectangularButton
                  type="submit"
                  text="Continue"
                  paddingXClass="sm:px-32"
                  disabled={loading}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PregnancyQuestions;

import { Link, useNavigate } from "react-router-dom";
import PageWrapper from "../../../../components/care/consultIntake/ungatedRx/PageWrapper";
import {
  PRIVACY_POLICY,
  TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from "../../../constants";
import { Consult, ConsultIntake } from "../../../../types/care";
import { useEffect, useState } from "react";
import OfframpModal from "../../../../components/care/consultIntake/ungatedRx/OfframpModal";
import Markdown from "markdown-to-jsx";
import {
  sendConsultIntakeClickedExitCTA,
  sendConsultIntakeViewSection,
} from "../../../../utils/analytics/customEventTracking";
import EducationalMomentCard from "../../../../components/healthHistoryV2/educationalMoment";

const INTRO_COPY = "Please confirm the following is true:";
const CONSENT_FIELDS = [
  { text: "I have a vagina or a neovagina." },
  { text: "I do not have any vaginal lesions or open sores." },
  { text: "I do not have HIV, ovarian, endometrial, or cervical cancer." },
  { text: "I am not currently pregnant or breastfeeding." },
  {
    text: "I am not currently trying to become pregnant.",
    requiresConsentNotTryingToConceive: true,
  },
];

const SEVERE_SYMPTOMS_DISCLAIMER =
  "I am not currently experiencing any of the following:";
const SEVERE_SYMPTOMS_BULLETS = [
  "High fever (>101.9F/38.8C)",
  "Severe abdominal, pelvic, and/or flank pain",
  "Abnormal vaginal bleeding",
];

const CircleCheckmark = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="12" r="12" className="fill-highlighter-mint" />
    <path
      d="M8.75 12.5L12.5 16L16.25 9"
      className="stroke-evvy-black"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

interface ConsentProps {
  currentConsult?: Consult;
  consultIntake: ConsultIntake;
  submitPage: (data: any, onSuccess: () => void) => void;
  isSubmitting: boolean;
  agreeToTerms: () => void;
}

const Consent = ({
  currentConsult,
  consultIntake,
  submitPage,
  isSubmitting,
  agreeToTerms,
}: ConsentProps) => {
  const navigate = useNavigate();
  const [
    showConfirmConsentDeniedOfframpModal,
    setShowConfirmConsentDeniedOfframpModal,
  ] = useState(false);
  const [showConsentDeniedOfframpModal, setShowConsentDeniedOfframpModal] =
    useState(false);

  const handleConfirmConsentDeniedOfframp = () => {
    // Trigger analytics
    sendConsultIntakeClickedExitCTA({
      consultId: consultIntake.consult.uid,
      testHash: null,
      ctaText: "confirm",
      consultType: consultIntake.consult.type,
      exitType: "checklist",
    });

    setShowConfirmConsentDeniedOfframpModal(false);
    setShowConsentDeniedOfframpModal(true);
    // we submit the page with empty data since we are offramping the user
    // and we do not want to misconstrue the user's actual information
    submitPage({}, () => {});
  };

  const handleConsentDeniedOfframp = () => {
    setShowConsentDeniedOfframpModal(false);
    navigate("/tests");
  };

  useEffect(() => {
    // send analytics events
    sendConsultIntakeViewSection({
      consultId: consultIntake.consult.uid,
      testHash: null,
      section: "checklist",
      consultType: consultIntake.consult.type,
    });
  }, [consultIntake.consult.uid]);

  if (!currentConsult) return null;

  const checkoutSourceHealthHistory =
    consultIntake?.is_checkout_source_health_context;

  return (
    <>
      {checkoutSourceHealthHistory && (
        <div
          className={
            "mt-2 w-full bg-cover bg-no-repeat sm:flex flex-auto text-center sm:text-left bg-evvy-white rounded-lg justify-center items-center p-[15px] sm:p-[20px]"
          }
          style={{
            backgroundImage:
              "url('/images/graphics/section-intro-gradient.png')",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <p className="text-left t4 mb-0 block sm:hidden">One more step</p>
          <h6 className="text-left w-[250px] mr-4 mb-0 p-2 hidden sm:block">
            One more step
          </h6>
          <p
            className={
              "w-full sm:w-[500px] text-left sm:text-left b2 mb-0 mt-1"
            }
          >
            {
              "In order to complete your order, please complete this short (<3 minutes) intake form."
            }
          </p>
        </div>
      )}
      <PageWrapper section="Please confirm">
        <div className="mx-auto">
          <p className="b1 semibold mb-2">{INTRO_COPY}</p>
          <br />
          <ul className="list-none ml-0 flex-1 b2 medium">
            {CONSENT_FIELDS.filter(
              (field) =>
                !field.requiresConsentNotTryingToConceive ||
                consultIntake.requires_consent_not_trying_to_conceive
            )?.map((field, i) => (
              <li key={i} className="flex mb-3 items-center">
                <span className="mr-4">
                  <CircleCheckmark />
                </span>
                {field.text}
              </li>
            ))}
            <li className="flex items-center">
              <span className="mt-0.5 mr-4">
                <CircleCheckmark />
              </span>
              {SEVERE_SYMPTOMS_DISCLAIMER}
            </li>
            <ul className="list-none ml-12 flex-1 font-normal">
              {SEVERE_SYMPTOMS_BULLETS.map((bullet, i) => (
                <li key={i} className="flex mb-4">
                  • {bullet}
                </li>
              ))}
              <p>
                <i>
                  If you are experiencing any of these symptoms, please seek
                  immediate medical attention or call 911.
                </i>
              </p>
            </ul>
          </ul>

          <ul className="list-none ml-0 flex-1 b2 medium">
            <li className="flex items-center">
              <span className="mt-0.5 mr-4">
                <CircleCheckmark />
              </span>
              <p>
                I agree to the{" "}
                <Link
                  to={PRIVACY_POLICY}
                  className="border-b border-evvy-black"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                ,{" "}
                <Link
                  to={TERMS_OF_SERVICE}
                  className="border-b border-evvy-black"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  to={TELEHEALTH_INFORMED_CONSENT}
                  className="border-b border-evvy-black"
                  rel="noreferrer"
                  target="_blank"
                >
                  Telehealth Informed Consent
                </Link>
              </p>
            </li>
          </ul>
          <div className="mt-8 mb-6 bg-evvy-black opacity-20 h-px w-full" />
          <span className="semibold leading-normal">
            If any of the above are not true, we recommend seeking in-person
            care — please click{" "}
            <span
              className="underline hover:cursor-pointer"
              onClick={() => setShowConfirmConsentDeniedOfframpModal(true)}
            >
              here
            </span>
            .
          </span>
        </div>
        <PageWrapper.FormFooter
          isSubmitting={isSubmitting}
          handleSubmit={agreeToTerms}
          buttonCTA="I confirm the above are true"
        />
      </PageWrapper>
      {showConfirmConsentDeniedOfframpModal && (
        <OfframpModal
          heading="Please Confirm"
          onConfirm={handleConfirmConsentDeniedOfframp}
          onClose={() => setShowConfirmConsentDeniedOfframpModal(false)}
          buttonOptions="double"
        >
          <Markdown className="leading-normal">
            Based on your medical information, you aren’t eligible for care
            through Evvy at this time. **Please confirm you would like us to
            cancel and refund your order.**
          </Markdown>
        </OfframpModal>
      )}
      {showConsentDeniedOfframpModal && (
        <OfframpModal
          heading="Thank you"
          onConfirm={handleConsentDeniedOfframp}
          onClose={() => setShowConsentDeniedOfframpModal(false)}
          buttonOptions="single"
        >
          <Markdown className="leading-normal">
            Our team will be in touch to refund your order.
          </Markdown>
        </OfframpModal>
      )}
    </>
  );
};

export default Consent;

import React, { useState } from "react";

// icons
import { ReactComponent as Evvy } from "../../components/plan_unfurled/icons/evvy-logo.svg";
import { ReactComponent as ArrowDown } from "../../components/common/icons/arrow-down-no-tail.svg";
import { ReactComponent as ArrowUp } from "../../components/common/icons/arrow-up-no-tail.svg";

// components
import LinkedText from "../common/linkedText";
import RectangularButton from "../common/rectangularButton";

// imported components
import Markdown from "markdown-to-jsx";

// library
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { secondaryColors } from "../../utils/viz";

// Results Note + Coaching CTA for MyPlan
const PlanNote = ({
  username,
  noteParts,
  coachingCallLink,
  analyticsEventArgs,
}) => {
  // a little bit of state
  const [showFullNote, setShowFullNote] = useState(false);

  return (
    <div className="bg-evvy-white p-8 md:p-14">
      <div>
        <h3 className="text-4xl md:text-5xl">
          Hi {username ? username : "there"},
        </h3>
        <div className="b1">
          {/* only show first paragraph of note up front */}
          {showFullNote && noteParts.length > 1 ? (
            <div>
              {noteParts.map((point, i) => (
                <div className="mb-4" key={`note-${i}`}>
                  <Markdown>{point}</Markdown>
                </div>
              ))}
            </div>
          ) : noteParts.length > 0 ? (
            // just show first bullet
            <div className="mb-4" key={`note-0`}>
              <Markdown>{noteParts[0]}</Markdown>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex justify-between w-full mt-8">
        {noteParts.length > 1 && (
          <LinkedText
            onClick={() => setShowFullNote(!showFullNote)}
            noIcon
            noTopMargin
          >
            {showFullNote ? (
              <>
                {"Show Less"}
                <span className="ml-2 inline-block">
                  <ArrowUp fill={secondaryColors["evvy-black"]} />
                </span>
              </>
            ) : (
              <>
                {"Read More"}
                <span className="ml-2 inline-block">
                  <ArrowDown fill={secondaryColors["evvy-black"]} />
                </span>
              </>
            )}
          </LinkedText>
        )}
        <Evvy />
      </div>

      {/* divider */}
      <hr className="my-8"></hr>

      {/* coaching component */}
      <div>
        <div className="b1 regular mb-8 md:mb-12">Still have questions?</div>
        <span className="bg-highlighter-yellow p-2 mt-14 sm:mt-20 font-semibold text-sm tracking-wider uppercase whitespace-nowrap">
          {"Free session"}
        </span>
        <h3 className="mt-4">
          Talk to an expert who{" "}
          <span className="whitespace-nowrap">gets it</span>
        </h3>
        <div className="mb-4 md:mb-8 b1 px-2 sm:px-0">
          Book a free 20-minute call with a certified Evvy health coach. You’ll
          review your microbial results in the context of your unique symptoms
          and goals.
        </div>
        <div className="py-4">
          <RectangularButton
            text="Talk to an expert →"
            bgColorClass="bg-evvy-blue"
            textColorClass="text-evvy-black"
            isLink
            horizontalPadding="block"
            href={coachingCallLink}
            onClick={analyticsClickHandler({
              eventName: eventNames.CLICKED_TALK_EXPERT,
              eventArgs: { ...analyticsEventArgs, location: "block" },
            })}
            fullWidth
            verticalPadding="py-6"
          />
        </div>
      </div>
    </div>
  );
};
export default PlanNote;

import { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";

import { ReactComponent as Checkmark } from "../components/common/icons/checkmark.svg";
import { ReactComponent as Info } from "../components/common/icons/infoGray.svg";

import { authService } from "../services/auth";

import Layout from "../components/layout";
import { subscriptionService } from "../services/subscription";
import {
  eventNames,
  sendClickedCancelMembership,
  sendClickedDowngradeMembership,
  sendClickedManageMembership,
  sendClickedUpgradeMembership,
  sendViewedMyAccountPage,
} from "../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../utils/analytics/helpers";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import { AxiosError, AxiosResponse } from "axios";

// images
import EvvyKitStandard from "../components/common/images/evvy-kit-1.png";
import EvvyKitExpanded from "../components/common/images/evvy-kit-2.png";
import RectangularButton from "../components/common/rectangularButton";
import LinkedText from "../components/common/linkedText";
import LoadingSpinner from "../components/common/loadingSpinner";
import { Subscription } from "../types/subscription";
import Markdown from "markdown-to-jsx";

import { ExpandedPCRInfoModal } from "../components/common/expandedPCRInfoModal";
import NameModal from "../components/account/NameModal";
import EmailModal from "../components/account/EmailModal";
import PasswordModal from "../components/account/PasswordModal";
import { SubscriptionCancelModal } from "../components/account/subscriptionCancelModal";

const EXPANDED_INFO_RELATIVE_URL = "/account/expanded-pcr-info/";
const SECTION_HEADER_CLASS = "font-semibold text-lg";
const EXPANDED_ADD_ON_VALUE_PROPS = [
  `Adds PCR testing for 4 STIs, 11 common microbes, antibiotic resistance, and microbial load (<u>[see full list](${EXPANDED_INFO_RELATIVE_URL})</u>)`,
  "Preliminary PCR results in 1-3 business days",
  "Full vaginal microbiome results in 7-10 business days",
  "All with the same swab",
];
const YOUR_EVVY_MEMBERSHIP = "Your Evvy Membership";
const CURRENT_MEMBERSHIP = "Current Membership";
const EVVY_MEMBERSHIP_COPY = {
  standard: {
    title: "Evvy Vaginal Health Test",
    description:
      "The world’s first at-home metagenomic (mNGS) vaginal microbiome test, which screens for all bacteria & fungi (excluding STIs).",
  },
  expanded: {
    title: "Evvy Vaginal Health Test +<br>Expanded PCR Panel",
    description: `The world’s first at-home metagenomic (mNGS) vaginal microbiome test, plus PCR testing for 4 STIs, 11 common microbes, and 10 antibiotic resistance classes (<u>[see list](${EXPANDED_INFO_RELATIVE_URL})</u>).`,
  },
};
const REMOVE_EXPANDED_PANEL_CTA = "Remove Expanded PCR Panel";
const ADD_ON_OPTION_FOR_PCR = "ADD-ON OPTION FOR PCR + STI TESTING";
const UPGRADE_DISCLAIMER =
  "By clicking “Upgrade” below, all future tests in your Membership will automatically include the Expanded PCR Panel.";
const MEMBERSHIP_PORTAL_SECTION_TITLE =
  "Looking to manage the rest of your Evvy Membership?";
const CANCEL_MEMBERSHIP_SECTION_TITLE = "Need to cancel?";
const CANCEL_MEMBERSHIP_INSTRUCTIONS =
  'If you\'re looking to move or skip your next test, use the "Access Dashboard" button above. Otherwise, click "Cancel Membership" to send our support team an email. They\'ll take it from there!';
const CANCEL_MEMBERSHIP_CTA = "Cancel Membership";
const CANCEL_MEMBERSHIP_URL = "/account/cancel-membership/";
const ACCESS_DASHBOARD_INSTRUCTIONS =
  "To update your shipping or billing info or change the date of your next test, click “Access Dashboard” to securely access your Membership dashboard:";
const ACCESS_DASHBOARD_CTA = "Access Dashboard";

const Account = () => {
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const currentUser = useLoggedInUser();
  const navigate = useNavigate();

  useEffect(() => {
    sendViewedMyAccountPage();
  }, []);

  return (
    <Layout title="My Account" padded={true} bgClass="bg-evvy-cream">
      <h2>My Account</h2>

      <div className="bg-evvy-white rounded-xl py-8 px-4 md:px-8">
        <div className="border-b border-dashed pb-8">
          <label className={SECTION_HEADER_CLASS}>Name</label>
          <div className="flex pt-4 items-center">
            {currentUser.identity.firstName || currentUser.identity.lastName ? (
              <div className="mr-auto">
                {currentUser.identity.firstName} {currentUser.identity.lastName}
              </div>
            ) : (
              <div className="mr-auto opacity-50">--</div>
            )}
            <button
              onClick={() => {
                setNameModalOpen(true);
                analyticsClickHandler({
                  eventName: eventNames.ACCOUNT_EDIT_INFORMATION,
                  eventArgs: { informationType: "name" },
                });
              }}
              className="underline uppercase text-sm font-semibold tracking-wider cursor-pointer underline-offset-4"
            >
              Edit
            </button>
          </div>
        </div>

        <div className="border-b border-dashed py-8">
          <label className={SECTION_HEADER_CLASS}>Email</label>
          <div className="flex pt-4 items-center">
            <div className="mr-auto">{currentUser.identity.email}</div>
            <button
              onClick={() => {
                setEmailModalOpen(true);
                analyticsClickHandler({
                  eventName: eventNames.ACCOUNT_EDIT_INFORMATION,
                  eventArgs: { informationType: "email" },
                });
              }}
              className="underline uppercase text-sm font-semibold tracking-wider cursor-pointer underline-offset-4"
            >
              Edit
            </button>
          </div>
        </div>

        <div className="py-8">
          <label className={SECTION_HEADER_CLASS}>Password</label>
          <div className="flex pt-4 items-center">
            <div className="mr-auto">*********</div>
            <button
              onClick={() => {
                setPasswordModalOpen(true);
                analyticsClickHandler({
                  eventName: eventNames.ACCOUNT_EDIT_INFORMATION,
                  eventArgs: { informationType: "password" },
                });
              }}
              className="underline uppercase text-sm font-semibold tracking-wider cursor-pointer underline-offset-4"
            >
              Edit
            </button>
          </div>
        </div>
        {<MembershipBlock />}
      </div>

      {currentUser.identity.isProvider && (
        <div className="bg-evvy-white rounded-xl py-8 px-4 md:px-8 mt-8 flex gap-4 text-gray-400">
          <Info className="w-6 h-6" />
          <div>
            <p className="">Are you looking for your patient account?</p>
            Reminder: if you have a personal patient account with Evvy, you'll
            have to log out of your provider account and into the patient
            account to access your results.
          </div>
        </div>
      )}

      <div className="flex py-6 md:py-12">
        <button
          className="mx-auto cursor-pointer t4 underline underline-offset-4"
          onClick={analyticsClickHandler({
            eventName: eventNames.CLICKED_LOGOUT,
            eventArgs: {},
            clickHandler: () => authService.logout(),
          })}
        >
          Log Out
        </button>
      </div>

      <Routes>
        <Route
          path="/expanded-pcr-info/"
          element={
            <ExpandedPCRInfoModal
              onClose={() => {
                navigate("/account/");
              }}
              from={"account"}
            />
          }
        />
        <Route
          path="/cancel-membership/"
          element={<SubscriptionCancelModal />}
        />
      </Routes>

      {nameModalOpen && (
        <NameModal handleClose={() => setNameModalOpen(false)} />
      )}
      {emailModalOpen && (
        <EmailModal handleClose={() => setEmailModalOpen(false)} />
      )}
      {passwordModalOpen && (
        <PasswordModal handleClose={() => setPasswordModalOpen(false)} />
      )}
    </Layout>
  );
};

export default Account;

const MembershipBlock = () => {
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState<Subscription>();
  const [portalLink, setPortalLink] = useState("");
  const [error, setError] = useState("");
  const isExpanded = subscription?.subscription_type === "expanded";
  const copy = isExpanded
    ? EVVY_MEMBERSHIP_COPY.expanded
    : EVVY_MEMBERSHIP_COPY.standard;

  /* Effects */
  useEffect(() => {
    async function fetchSubscriptionInfo() {
      try {
        setLoading(true);
        const response = await subscriptionService.fetchSubscriptionInfo();
        if (response?.data) {
          setSubscription(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    const getMembershipPortalLink = () => {
      setLoading(true);
      subscriptionService.fetchPortalLink(
        (response: AxiosResponse) => {
          const { url } = response.data;
          setPortalLink(url);
          setLoading(false);
          setError("");
        },
        (error: AxiosError) => {
          console.error(error);
          setError("Error loading membership portal");
          setLoading(false);
        }
      );
    };

    // get user subscription info if it exists
    fetchSubscriptionInfo();
    getMembershipPortalLink();
  }, []);

  useEffect(() => {
    const getMembershipPortalLink = () => {
      setLoading(true);
      subscriptionService.fetchPortalLink(
        (response: AxiosResponse) => {
          const { url } = response.data;
          setPortalLink(url);
          setLoading(false);
          setError("");
        },
        (error: AxiosError) => {
          console.error(error);
          setError("Error loading membership portal");
          setLoading(false);
        }
      );
    };
    if (subscription) {
      getMembershipPortalLink();
    }
  }, [subscription]);

  // Actions
  async function swapMembership() {
    const newSubscriptionType = isExpanded ? "standard" : "expanded";
    const oldSubscriptionType = subscription?.subscription_type;
    try {
      setLoading(true);
      const response = await subscriptionService.swapSubscription(
        newSubscriptionType
      );
      if (newSubscriptionType === "standard") {
        sendClickedDowngradeMembership({
          subscriptionType: oldSubscriptionType,
        });
      } else {
        sendClickedUpgradeMembership({ subscriptionType: oldSubscriptionType });
      }
      setSubscription(response.data);
    } catch (error) {
      setError(
        "Error changing membership. Please contact support@evvy.com for assistance."
      );
    } finally {
      setLoading(false);
    }
  }

  if (!subscription) {
    return <div></div>;
  } else if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="border-y border-dashed py-8">
        <label className={`${SECTION_HEADER_CLASS}`}>
          {YOUR_EVVY_MEMBERSHIP}
        </label>
        <div className="grid md:grid-cols-2 pt-6 md:space-x-6 space-y-4 md:space-y-0">
          {/* Current subscription block */}
          <div className="md:col-span-1">
            <div className="bg-evvy-silverfish py-5 px-7 rounded-xl">
              <div className="b3 medium uppercase">{CURRENT_MEMBERSHIP}</div>
              <div className="flex space-x-3 py-4">
                <img
                  src={EvvyKitStandard}
                  className="w-14 h-14 object-cover"
                  alt="Evvy Kit Standard"
                  loading={"lazy"}
                />
                <div className="space-y-1">
                  <div className={SECTION_HEADER_CLASS}>
                    <Markdown>{copy.title}</Markdown>
                  </div>
                  <div className="text-sm">
                    <Markdown>{copy.description}</Markdown>
                  </div>
                  {!loading && isExpanded && (
                    <div
                      onClick={swapMembership}
                      className="hidden md:block pt-2 t4 underline underline-offset-4 cursor-pointer"
                    >
                      {REMOVE_EXPANDED_PANEL_CTA}
                    </div>
                  )}
                </div>
              </div>
              {!loading && isExpanded && (
                <div
                  onClick={swapMembership}
                  className="md:hidden pt-2 t4 underline underline-offset-4 cursor-pointer"
                >
                  {REMOVE_EXPANDED_PANEL_CTA}
                </div>
              )}
            </div>
          </div>
          {/* Add-on subscription block */}
          {!isExpanded && subscription?.swappable && (
            <div className="md:col-span-1">
              <div className="bg-evvy-cream py-5 px-7 rounded-xl space-y-5">
                <div className="b3 medium">{ADD_ON_OPTION_FOR_PCR}</div>
                <div className="flex justify-start space-x-3">
                  <img
                    src={EvvyKitExpanded}
                    className="w-14 h-14 object-cover"
                    alt="Evvy Kit Expanded"
                    loading={"lazy"}
                  />

                  <div className="space-y-1">
                    <div className="semibold text-lg">Expanded PCR Panel</div>
                    <div className="t3 regular text-dv-pond">
                      +${subscription?.price ? 218 - subscription.price : 119}{" "}
                      per test
                    </div>
                  </div>
                </div>
                {/* value props */}
                <div className="">
                  <ul className="mx-auto space-y-1">
                    {EXPANDED_ADD_ON_VALUE_PROPS.map((valueProp, index) => (
                      <li
                        key={`value-prop-${index}`}
                        className="flex text-evvy-black/[0.6] text-sm my-auto"
                      >
                        <span className="text-dv-pond">
                          <Checkmark className="h-3.5 mt-0.5" />
                        </span>
                        <Markdown>{valueProp}</Markdown>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* upgrade disclaimer */}
                <div className="b2">{UPGRADE_DISCLAIMER}</div>
                {/* upgrade button */}
                <div className="pb-3">
                  <RectangularButton
                    disabled={loading}
                    text="Upgrade your membership"
                    bgColorClass="bg-evvy-blue"
                    textColorClass="text-evvy-black"
                    fullWidth
                    verticalPadding="py-4 md:py-6"
                    onClick={swapMembership}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pt-8 pb-4 md:pb-8 space-y-4 text-center md:text-left">
        <label className={SECTION_HEADER_CLASS}>
          {MEMBERSHIP_PORTAL_SECTION_TITLE}
        </label>
        <div className="md:flex md:justify-between items-center space-y-4 md:space-y-0">
          <div className="t3 regular md:max-w-3xl">
            {ACCESS_DASHBOARD_INSTRUCTIONS}
          </div>
          {!portalLink && error ? (
            <div>
              <div className="t3 regular text-red-500">{error}</div>
            </div>
          ) : portalLink ? (
            <div className="my-auto">
              <LinkedText
                disabled={loading}
                isLink
                noIcon
                href={portalLink}
                onClick={sendClickedManageMembership}
              >
                {ACCESS_DASHBOARD_CTA}
              </LinkedText>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {subscription.allow_cancellation && (
        <div className="border-t border-dashed pt-8 pb-4 md:pb-8 space-y-4 text-center md:text-left">
          <label className={SECTION_HEADER_CLASS}>
            {CANCEL_MEMBERSHIP_SECTION_TITLE}
          </label>
          <div className="md:flex md:justify-between items-center space-y-4 md:space-y-0">
            <div className="t3 regular md:max-w-3xl">
              {CANCEL_MEMBERSHIP_INSTRUCTIONS}
            </div>
            {!portalLink && error ? (
              <div>
                <div className="t3 regular text-red-500">{error}</div>
              </div>
            ) : portalLink ? (
              <div className="my-auto">
                <Link
                  to={CANCEL_MEMBERSHIP_URL}
                  onClick={sendClickedCancelMembership}
                  className="t4 border-evvy-black border-b-2 cursor-pointer"
                >
                  {CANCEL_MEMBERSHIP_CTA}
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

import { Field, Form, Formik, FormikErrors } from "formik";
import { useEffect, useRef, useState } from "react";

import Layout from "../../components/layout";
import { accountService } from "../../services/account";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { ProviderProfile } from "../../types/user";
import BlueRectangularButton from "../../components/common/blueRectangularButton";
import ReactTooltip from "react-tooltip";
import { indexCheckboxStyles, indexRadioStyles } from "../../utils/colors";
import Markdown from "markdown-to-jsx";
import { useNavigate } from "react-router-dom";
import { useProviderProfile } from "../../hooks/provider/useProviderProfile";
import axios from "axios";
import { OTHER_QUESTION } from "./constants";
import {
  sendProviderClickedContinueOnIntake,
  sendProviderViewedIntakePage,
} from "../../utils/analytics/customEventTracking";
import { uncheckNoneChangeHandler } from "../../utils/answerUtils";

export const ProviderIntake = () => {
  const [providerProfile, providerProfileLoading, providerProfileError] =
    useProviderProfile();
  const [updateProviderLoading, setUpdateProviderLoading] = useState(false);
  const [updateProviderProfileError, setUpdateProviderProfileError] =
    useState("");

  const navigate = useNavigate();

  useEffect(() => {
    sendProviderViewedIntakePage();
  }, []);

  const updateProviderProfile = async (values: ProviderQuestionsForm) => {
    setUpdateProviderLoading(true);
    try {
      await accountService.updateProviderProfile(values);
      navigate("/");
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        setUpdateProviderProfileError(error.message);
      }
    } finally {
      setUpdateProviderLoading(false);
    }
  };

  if (providerProfileLoading && !providerProfile) {
    return <LoadingSpinner />;
  }

  const error = updateProviderProfileError || providerProfileError;

  return (
    <Layout
      title="Account | Provider | Intake"
      padded
      bgClass="bg-evvy-cream"
      full
    >
      <div className="block w-full mt-8 sm:mt-10">
        {providerProfile && (
          <ProviderQuestions
            updateProviderLoading={updateProviderLoading}
            providerProfile={providerProfile}
            onSubmit={async (values) => {
              await updateProviderProfile(values);
              sendProviderClickedContinueOnIntake();
            }}
          />
        )}
        {error && (
          <div className="bg-red-400 p-8 rounded-2xl mb-4">{error}</div>
        )}
      </div>
    </Layout>
  );
};

type ProviderQuestionsForm = {
  provider_type: string;
  provider_type_other: string;
  specialty: string;
  affiliated_practice: string;
  clinic_state: string;
  clinic_state_other: string;
  clinic_country: string;
  clinic_country_other: string;
  clinic_province: string;
  clinic_province_other: string;
  goals: string[];
  goals_other: string;
  hdyhau: string;
  hdyhau_other: string;
};

const ProviderQuestions = ({
  providerProfile,
  onSubmit,
  updateProviderLoading,
}: {
  providerProfile: ProviderProfile;
  onSubmit: (values: ProviderQuestionsForm) => Promise<void>;
  updateProviderLoading: boolean;
}) => {
  const providerTypeRef = useRef<HTMLDivElement>(null);
  const providerTypeOtherRef = useRef<HTMLInputElement>(null);
  const specialtyRef = useRef<HTMLDivElement>(null);
  const affiliatedPracticeRef = useRef<HTMLDivElement>(null);
  const clinicStateRef = useRef<HTMLDivElement>(null);
  const clinitStateOtherRef = useRef<HTMLInputElement>(null);
  const clinicCountryRef = useRef<HTMLDivElement>(null);
  const clinicCountryOtherRef = useRef<HTMLInputElement>(null);
  const clinicProvinceRef = useRef<HTMLDivElement>(null);
  const clinicProvinceOtherRef = useRef<HTMLInputElement>(null);
  const goalsRef = useRef<HTMLDivElement>(null);
  const goalsOtherRef = useRef<HTMLInputElement>(null);
  const hdyhauRef = useRef<HTMLDivElement>(null);
  const hdyhauOtherRef = useRef<HTMLInputElement>(null);

  const formIdToRefMap = {
    provider_type: providerTypeRef,
    provider_type_other: providerTypeOtherRef,
    specialty: specialtyRef,
    affiliated_practice: affiliatedPracticeRef,
    clinic_state: clinicStateRef,
    clinic_state_other: clinitStateOtherRef,
    clinic_country: clinicCountryRef,
    clinic_country_other: clinicCountryOtherRef,
    clinic_province: clinicProvinceRef,
    clinic_province_other: clinicProvinceOtherRef,
    goals: goalsRef,
    goals_other: goalsOtherRef,
    hdyhau: hdyhauRef,
    hdyhau_other: hdyhauOtherRef,
  };

  return (
    <Formik
      initialValues={{
        provider_type: providerProfile.provider_type
          ? providerProfile.provider_type
          : providerProfile.provider_type_options[0][0],
        provider_type_other: providerProfile.provider_type_other || "",
        specialty: providerProfile.specialty ? providerProfile.specialty : "",
        affiliated_practice: providerProfile.affiliated_practice
          ? providerProfile.affiliated_practice
          : "",
        goals: providerProfile.goals
          ? providerProfile.goals
          : [providerProfile.goals_options[0][0]],
        goals_other: providerProfile.goals_other || "",
        clinic_state: providerProfile.clinic_state || "",
        clinic_state_other: providerProfile.clinic_state_other || "",
        clinic_country: providerProfile.clinic_country
          ? providerProfile.clinic_country
          : providerProfile.clinic_country_options_with_other[0][0],
        clinic_country_other: providerProfile.clinic_country_other || "",
        clinic_province: providerProfile.clinic_province || "",
        clinic_province_other: providerProfile.clinic_province_other || "",
        hdyhau: providerProfile.hdyhau
          ? providerProfile.hdyhau
          : providerProfile.hdyhau_options[0][0],
        hdyhau_other: providerProfile.hdyhau_other || "",
      }}
      onSubmit={onSubmit}
      validate={(values) => {
        const requiredQuestions: (keyof typeof formIdToRefMap)[] = [
          "provider_type",
          "specialty",
          "affiliated_practice",
          "clinic_country",
          "goals",
          "hdyhau",
        ];

        if (values.provider_type === OTHER_QUESTION) {
          requiredQuestions.push("provider_type_other");
        }

        if (values.goals.includes(OTHER_QUESTION)) {
          requiredQuestions.push("goals_other");
        }

        if (values.hdyhau === OTHER_QUESTION) {
          requiredQuestions.push("hdyhau_other");
        }

        if (values.clinic_country === "United States") {
          requiredQuestions.push("clinic_state");
        }

        if (values.clinic_country === "Canada") {
          requiredQuestions.push("clinic_province");
        }

        if (values.clinic_state === OTHER_QUESTION) {
          requiredQuestions.push("clinic_state_other");
        }

        if (values.clinic_country === OTHER_QUESTION) {
          requiredQuestions.push("clinic_country_other");
        }

        if (values.clinic_province === OTHER_QUESTION) {
          requiredQuestions.push("clinic_province_other");
        }

        const errors: FormikErrors<ProviderQuestionsForm> = {};

        requiredQuestions.forEach((question) => {
          const ref = formIdToRefMap[question];

          if (!values[question] || values[question].length === 0) {
            errors[question] = "This question is required";
            if (ref && ref.current) {
              ReactTooltip.show(ref.current);
            }
          } else {
            if (ref && ref.current) {
              ReactTooltip.hide(ref.current);
            }
          }
        });

        return errors;
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, handleSubmit, values, handleChange, setFieldValue }) => (
        <>
          <h3 className="text-center">
            Finish Creating Your Evvy Provider Account
          </h3>
          <Form className="flex-1 bg-evvy-white p-5 sm:p-12 mb-3 rounded-lg space-y-2">
            <div className="p-4">
              <div
                className={`border rounded-md  ${
                  getError("provider_type", errors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("provider_type", errors)}
                data-for="provider_type"
                ref={providerTypeRef}
              >
                <ReactTooltip
                  id="provider_type"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {"Provider Type "}
                  <span className="text-evvy-blue">*</span>
                </h4>

                <div role="group" aria-labelledby="checkbox-group">
                  {providerProfile.provider_type_options.map((o, i) => {
                    return (
                      <label
                        className="mb-3 cursor-pointer flex items-center"
                        key={o[0]}
                      >
                        <Field
                          type="radio"
                          name="provider_type"
                          value={o[0]}
                          className={indexRadioStyles(i)}
                        />
                        <span className="ml-2">
                          <Markdown>{o[1]}</Markdown>
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>
              {values.provider_type === OTHER_QUESTION && (
                <div
                  className={`p-4 border rounded-md ${
                    getError("provider_type_other", errors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("provider_type_other", errors)}
                  data-for="provider_type_other"
                  ref={providerTypeOtherRef}
                >
                  <ReactTooltip
                    id="provider_type_other"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {'Please describe "Other"'}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <Field
                    name="provider_type_other"
                    as="input"
                    className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4 w-full"
                    placeholder="Other Answer"
                  />
                </div>
              )}
            </div>
            <div
              className={`p-4 border rounded-md ${
                getError("specialty", errors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("specialty", errors)}
              data-for="specialty"
              ref={specialtyRef}
            >
              <ReactTooltip
                id="specialty"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {"Specialty "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <Field
                name="specialty"
                as="input"
                className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                placeholder="Urology, Family Medicine, etc."
              />
            </div>
            <div
              className={`p-4 border rounded-md ${
                getError("affiliated_practice", errors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("affiliated_practice", errors)}
              data-for="affiliated_practice"
              ref={affiliatedPracticeRef}
            >
              <ReactTooltip
                id="affiliated_practice"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {"Affiliated Clinic or Practice "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <Field
                name="affiliated_practice"
                as="input"
                className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                placeholder="Your Answer"
              />
            </div>
            <div
              className={`p-4 border rounded-md  ${
                getError("clinic_country", errors)
                  ? "border-coral"
                  : "border-transparent"
              }`}
              data-tip={getError("clinic_country", errors)}
              data-for="clinic_country"
              ref={clinicCountryRef}
            >
              <ReactTooltip
                id="clinic_country"
                effect="solid"
                place="left"
                type="error"
                backgroundColor="#FFA684"
                textColor="#11161A"
                className="rounded-xs py-20"
              />
              <h4 className="t1">
                {"Clinic or Practice Country "}
                <span className="text-evvy-blue">*</span>
              </h4>
              <Field
                as="select"
                name="clinic_country"
                className="border-0 bg-evvy-cream pr-8 outline-none focus:ring-0 p-4"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleChange(e);

                  setFieldValue("clinic_state", "");
                  setFieldValue("clinic_state_other", "");
                  setFieldValue("clinic_province", "");
                  setFieldValue("clinic_province_other", "");
                }}
              >
                <option key={0}>--</option>

                {providerProfile.clinic_country_options_with_other.map(
                  ([key, value]) => {
                    return value === OTHER_QUESTION ? (
                      <option key={key} value={key} className="h-4">
                        {"Other"}
                      </option>
                    ) : (
                      <option key={key} value={key} className="h-4">
                        {value}
                      </option>
                    );
                  }
                )}
              </Field>
              {values.clinic_country === OTHER_QUESTION && (
                <div
                  className={`py-4 border rounded-md ${
                    getError("clinic_country_other", errors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("clinic_country_other", errors)}
                  data-for="clinic_country_other"
                  ref={providerTypeOtherRef}
                >
                  <ReactTooltip
                    id="clinic_country_other"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {'Please describe "Other"'}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <Field
                    name="clinic_country_other"
                    as="input"
                    className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4 w-full"
                    placeholder="Other Answer"
                  />
                </div>
              )}
            </div>
            {values.clinic_country === "United States" && (
              <div
                className={`p-4 border rounded-md  ${
                  getError("clinic_state", errors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("clinic_state", errors)}
                data-for="clinic_state"
                ref={clinicStateRef}
              >
                <ReactTooltip
                  id="clinic_state"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {"Clinic or Practice State "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  as="select"
                  name="clinic_state"
                  className="border-0 bg-evvy-cream pr-8 outline-none focus:ring-0 p-4"
                >
                  <option key={0}>--</option>

                  {providerProfile.clinic_state_options_with_other.map(
                    ([key, value]) => {
                      return value === OTHER_QUESTION ? (
                        <option key={key} value={key} className="h-4">
                          {"Other"}
                        </option>
                      ) : (
                        <option key={key} value={key} className="h-4">
                          {value}
                        </option>
                      );
                    }
                  )}
                </Field>
                {values.clinic_state === OTHER_QUESTION && (
                  <div
                    className={`py-4 border rounded-md ${
                      getError("clinic_state_other", errors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError("clinic_state_other", errors)}
                    data-for="clinic_state_other"
                    ref={providerTypeOtherRef}
                  >
                    <ReactTooltip
                      id="clinic_state_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {'Please describe "Other"'}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="clinic_state_other"
                      as="input"
                      className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4 w-full"
                      placeholder="Other Answer"
                    />
                  </div>
                )}
              </div>
            )}
            {values.clinic_country === "Canada" && (
              <div
                className={`p-4 border rounded-md  ${
                  getError("clinic_province", errors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("clinic_province", errors)}
                data-for="clinic_province"
                ref={clinicProvinceRef}
              >
                <ReactTooltip
                  id="clinic_province"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {"Clinic or Practice Province "}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <Field
                  as="select"
                  name="clinic_province"
                  className="border-0 bg-evvy-cream pr-8 outline-none focus:ring-0 p-4"
                >
                  <option key={0}>--</option>

                  {providerProfile.clinic_province_options_with_other.map(
                    ([key, value]) => {
                      return value === OTHER_QUESTION ? (
                        <option key={key} value={key} className="h-4">
                          {"Other"}
                        </option>
                      ) : (
                        <option key={key} value={key} className="h-4">
                          {value}
                        </option>
                      );
                    }
                  )}
                </Field>
                {values.clinic_province === OTHER_QUESTION && (
                  <div
                    className={`py-4 border rounded-md ${
                      getError("clinic_province_other", errors)
                        ? "border-coral"
                        : "border-transparent"
                    }`}
                    data-tip={getError("clinic_province_other", errors)}
                    data-for="clinic_province_other"
                    ref={providerTypeOtherRef}
                  >
                    <ReactTooltip
                      id="clinic_province_other"
                      effect="solid"
                      place="left"
                      type="error"
                      backgroundColor="#FFA684"
                      textColor="#11161A"
                      className="rounded-xs py-20"
                    />
                    <h4 className="t1">
                      {'Please describe "Other"'}
                      <span className="text-evvy-blue">*</span>
                    </h4>
                    <Field
                      name="clinic_province_other"
                      as="input"
                      className="border-0 bg-evvy-cream outline-none focus:ring-0 p-4 w-full"
                      placeholder="Other Answer"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="p-4">
              <div
                className={`border rounded-md  ${
                  getError("goals", errors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("goals", errors)}
                data-for="goals"
                ref={goalsRef}
              >
                <ReactTooltip
                  id="goals"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {"What are you hoping Evvy can help with?  "}
                  <span className="text-evvy-blue">*</span>
                </h4>

                <p>Please select all that apply</p>
                <div role="group" aria-labelledby="checkbox-group">
                  {providerProfile.goals_options.map((o, i) => (
                    <label className="mb-3 cursor-pointer flex" key={o[0]}>
                      <Field
                        type="checkbox"
                        name="goals"
                        value={o[0]}
                        className={`h-5 w-5 ${indexCheckboxStyles(i)}`}
                        onChange={uncheckNoneChangeHandler({
                          handleChange,
                          setFieldValue,
                          fieldName: "goals",
                          fieldValues: values.goals,
                        })}
                      />
                      <span className="ml-2">{o[1]}</span>
                    </label>
                  ))}
                </div>
              </div>
              {values.goals.includes(OTHER_QUESTION) && (
                <div
                  className={`p-4 border rounded-md ${
                    getError("goals_other", errors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("goals_other", errors)}
                  data-for="goals_other"
                  ref={goalsOtherRef}
                >
                  <ReactTooltip
                    id="goals_other"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {'Please describe "Other"'}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <Field
                    name="goals_other"
                    as="input"
                    className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                    placeholder="Other Answer"
                  />
                </div>
              )}
            </div>
            <div className="p-4">
              <div
                className={`border rounded-md  ${
                  getError("hdyhau", errors)
                    ? "border-coral"
                    : "border-transparent"
                }`}
                data-tip={getError("hdyhau", errors)}
                data-for="hdyhau"
                ref={hdyhauRef}
              >
                <ReactTooltip
                  id="hdyhau"
                  effect="solid"
                  place="left"
                  type="error"
                  backgroundColor="#FFA684"
                  textColor="#11161A"
                  className="rounded-xs py-20"
                />
                <h4 className="t1">
                  {"How did you hear about Evvy?"}
                  <span className="text-evvy-blue">*</span>
                </h4>
                <div role="group" aria-labelledby="checkbox-group">
                  {providerProfile.hdyhau_options.map((o, i) => (
                    <label
                      className="mb-3 cursor-pointer flex items-center"
                      key={o[0]}
                    >
                      <Field
                        type="radio"
                        name="hdyhau"
                        value={o[0]}
                        className={indexRadioStyles(i)}
                      />
                      <span className="ml-2">
                        <Markdown>{o[1]}</Markdown>
                      </span>
                    </label>
                  ))}
                </div>
              </div>
              {values.hdyhau === OTHER_QUESTION && (
                <div
                  className={`p-4 border rounded-md ${
                    getError("hdyhau_other", errors)
                      ? "border-coral"
                      : "border-transparent"
                  }`}
                  data-tip={getError("hdyhau_other", errors)}
                  data-for="hdyhau_other"
                  ref={hdyhauOtherRef}
                >
                  <ReactTooltip
                    id="hdyhau_other"
                    effect="solid"
                    place="left"
                    type="error"
                    backgroundColor="#FFA684"
                    textColor="#11161A"
                    className="rounded-xs py-20"
                  />
                  <h4 className="t1">
                    {'Please describe "Other"'}
                    <span className="text-evvy-blue">*</span>
                  </h4>
                  <Field
                    name="hdyhau_other"
                    as="input"
                    className="border-0 bg-evvy-cream w-full outline-none focus:ring-0 p-4"
                    placeholder="Other Answer"
                  />
                </div>
              )}
            </div>
            <div className="flex mt-6">
              <BlueRectangularButton
                text={
                  updateProviderLoading
                    ? "Loading..."
                    : providerProfile.has_completed_intake
                    ? "Submitted"
                    : "Continue"
                }
                paddingXClass="sm:px-32"
                onClick={handleSubmit}
                disabled={
                  updateProviderLoading || providerProfile.has_completed_intake
                }
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

const getError = (
  key: string,
  errors: {
    [key: string]: string | string[];
  }
) => {
  // util to show error, either from frontend validation errors or from the API, which comes back as an array
  if (errors[key]) {
    return errors[key];
  }
};

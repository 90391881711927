import { ReactComponent as Microbe } from "../../components/plan_unfurled/icons/microbe.svg";
import { ReactComponent as BirthControl } from "../../components/plan_unfurled/icons/birth-control.svg";
import { ReactComponent as Medication } from "../../components/plan_unfurled/icons/medication.svg";
import { ReactComponent as Symptoms } from "../../components/plan_unfurled/icons/vaginal-symptoms.svg";
import { ReactComponent as Uterus } from "../../components/plan_unfurled/icons/uterus.svg";
import { ReactComponent as Pills } from "../../components/plan_unfurled/icons/pills.svg";

import { secondaryColors } from "../../utils/viz";
import {
  GENERAL_COACHING_CALL_LINK,
  PDP_URL,
  EVVY_MEMBERSHIP_CHECKOUT_LINK,
} from "../constants";

const links = {
  EXPERT: GENERAL_COACHING_CALL_LINK,
  MEMBER: `${EVVY_MEMBERSHIP_CHECKOUT_LINK}&utm_source=myevvy&utm_medium=myplan&utm_campaign=howtousesection`,
  SUPPORT: "mailto:support@evvy.com",
  REVIEW: `${PDP_URL}?monto_review_form_open=1`,
  COMMUNITY: "https://www.evvy.com/supportgroup",
  MICROBIOME: "https://www.evvy.com/blog/meet-your-microbiome",
};

const ecosystemItems = [
  {
    icon: (
      <Microbe stroke={secondaryColors["dv-pink"]} width="100%" height="100%" />
    ),
    text: "Test before and after a trigger (sex, menstruation, etc) to see how it alters your microbiome.",
  },
  {
    icon: (
      <BirthControl
        stroke={secondaryColors["dv-frog"]}
        width="100%"
        height="100%"
      />
    ),
    text: "Test when you change birth control methods to see how they play a role in your vaginal health.",
  },
  {
    icon: (
      <Medication
        stroke={secondaryColors["coral"]}
        width="100%"
        height="100%"
      />
    ),
    text: "Test 7-10 days after taking antibiotics to see if disruptive microbes rebound or stay away.",
  },
  {
    icon: (
      <Symptoms
        stroke={secondaryColors["dv-pond"]}
        width="100%"
        height="100%"
      />
    ),
    text: "Test when you do and don’t have symptoms to see what may be different.",
  },
  {
    icon: (
      <Uterus
        stroke={secondaryColors["evvy-blue"]}
        width="100%"
        height="100%"
      />
    ),
    text: "Test at different times in your cycle to see how hormones may contribute to your vaginal symptoms.",
  },
  {
    icon: (
      <Pills stroke={secondaryColors["evvy-pine"]} width="100%" height="100%" />
    ),
    text: "Test before and after diet and/or supplement changes to see how they alter your microbiome.",
  },
];

// research modal copy
const researchCopy = {
  RECOMMENDATIONS:
    "We dug through the research so you don’t have to. These are the products we could find with the strongest research validation",
  EVYV_RECOMMENDATIONS:
    "Evvy products are based on the latest microbiome science. Prescriptions are determined at the sole discretion of an Evvy-affiliated provider based on your results, health profile, and clinical intake",
  RESEARCH:
    "Scientific research is always evolving, so “Research Status” gives you a sense of how well-established the scientific literature is on a certain topic so far.",
};

// feedback modal copy
const feedbackCopy = {
  TITLE: "We'd love to hear from you",
  // CAPTION: "We're sorry to hear your plan wasn't useful — can you tell us more about why you feel that way? | Please note this form is for product feedback only. For anything else, please reach out to <strong>support@evvy.com.</strong>",
  CAPTION: (
    <div>
      <p>
        We're sorry to hear your plan wasn't useful — can you tell us more about
        why you feel that way?
      </p>
      <p>
        {" "}
        Please note this form is for product feedback only. For anything else,
        please reach out to <strong>support@evvy.com.</strong>
      </p>
    </div>
  ),
  SUBMIT_TITLE: "Thanks for letting us know!",
  SUBMIT_CAPTION: (
    <div>
      <p>
        We deeply care about each of our users, and your feedback helps us
        improve Evvy for the whole community.
      </p>
      <p>
        {" "}
        If you need help resolving an issue, please reach out to{" "}
        <strong>support@evvy.com</strong>
      </p>
    </div>
  ),
  SUBMIT_ERROR_CAPTION:
    "Sorry — your feedback could not be sent at this time! Please try again later.",
};

const feedbackValidationCopy = {
  LENGTH: "Feeback must be longer than 3 characters",
};

// leave review modal copy
const reviewCopy = {
  TITLE: "Thanks for letting us know!",
  CAPTION:
    "We're in this together - and sharing your experience will help other women navigate their health journeys. We'd truly appreciate if you took a moment to share your experience as a review!",
};

const nextTestCopy = (date: string) => ({
  TITLE: "Want your next test sooner?",
  CAPTION: [
    `As part of your Evvy membership, your next test ships on ${date}. If you'd like, you can reschedule this order to ship ASAP, at the same membership rate (minus any discounts that have been applied!)`,
    "Your membership schedule will automatically adjust, so the following test will be set for 3 months from today.",
  ],
  SUBMIT_TITLE: "Your order is confirmed!",
  SUBMIT_CAPTION:
    "You should receive a confirmation email shortly. We can’t wait to get you your next test! | If you have any questions, please reach out to support@evvy.com.",
  SUBMIT_ERROR_CAPTION:
    "Sorry — your request could not be completed at this time! Please try again later.",
});

const yourPlanCopy = {
  TITLE: "Your Plan",
  NON_MICROBIAL_TITLE: "More For You",
  NON_MICROBIAL_DESC:
    "Other things to consider, selected for you based on your unique results and health profile.",
  SUPPORT:
    "No one should ever feel alone while navigating the world of vaginal symptoms and infections.",
  JOIN_COMMUNITY:
    "Join Evvy's Support Community for Vaginal Health (it's free!) to connect, commiserate, share tips, learn about vaginal health, and more with other Evvy users.",
  DISCLAIMER:
    "Stop using any products immediately and report to your doctor as soon as possible if you experience any side effects: allergic reactions (like skin rashes, itching or hives), vaginal irritation, redness or burning.",
};

const coachingCopy = {
  CARE_ELIGIBLE: {
    TAG: "Free coaching call",
    TITLE: "Not ready to get treated through Evvy?",
    DESCRIPTION:
      "Book a free 20-minute call with one of our certified health coaches. You can review your results and plan, plus ask any questions you have about our treatment program.",
    CTA_TEXT: "connect with a coach",
  },
  CARE_INELIGIBLE: {
    TAG: "Free session",
    TITLE: "Want to connect with a certified health coach?",
    DESCRIPTION:
      "Book a free 20-minute call with a certified Evvy health coach. You’ll review your microbial results and this plan in the context of your unique symptoms and goals.",
    CTA_TEXT: "talk to an expert →",
  },
};

// your plan care widget

const careWidgetCopy = {
  CARE_ELIGIBLE: "Action on your plan in one easy step.",
  CARE_INELIGIBLE:
    "Take this plan to your provider, or talk to an Evvy health coach if you have more questions.",
};

const insightsCopy = {
  TITLE: "Insights for the whole you",
  DESC: "We’ve curated these researched-backed insights based on what you told us — because better caring for your body starts by better understanding it.",
};

const howToCopy = {
  TITLE: "How to use your next Evvy test",
  DESC: "While testing your vaginal microbiome at a single point in time is a useful snapshot, your microbiome shifts alongside your lifestyle changes. Testing over time reveals what impacts your microbiome and how resilient it is. Here are some ideas for when and how to test next:",
  LINK_TEXT: "Learn what affects your microbiome",
};

const planItemKeys = {
  INSIGHTS: "ED",
  NON_MICROBIAL: "NA",
  MICROBIAL: "MA",
};

const sessionItems = [
  "Get support, guidance, and answers to your questions from a certified health coach in a safe, judgement-free space",
  "Review your plan and have your results explained in the context of your unique symptoms and goals",
  "Prepare to discuss your results with your doctor (if needed)",
];

const insightsCardsColors = [
  "highlighter-mint",
  "highlighter-purple",
  "highlighter-salmon",
];

const modalTypes = {
  FEEDBACK: "_feedback",
  NEXT_TEST: "_nexttest",
  RECOMMENDATION: "_recommendation",
  LEARN_MORE: "_learnmore",
  RESEARCH: "_research",
  LEAVE_REVIEW: "_leavereview",
  PRICING: "_pricing",
  PROBLEMS: "_problems",
};

const researchModalItems = [
  { status: "Novel", description: "Research is limited but promising." },
  { status: "Emerging", description: "Research is sufficient but evolving." },
  { status: "Established", description: "Research is well-documented." },
];

const researchModalCopy = {
  TITLE: "Research status",
  DESCRIPTION: `Scientific research is always evolving, so “Research Status” gives you a sense of how well-established the scientific literature is on a certain topic so far.|
  We evaluated the research on each topic based on the quantity of high quality studies, and rated each item as:`,
  STATUSES: researchModalItems,
};

const PLAN_VERSION = "v3";

export {
  sessionItems,
  ecosystemItems,
  links,
  researchCopy,
  feedbackCopy,
  feedbackValidationCopy,
  nextTestCopy,
  yourPlanCopy,
  insightsCopy,
  howToCopy,
  planItemKeys,
  insightsCardsColors,
  modalTypes,
  researchModalCopy,
  reviewCopy,
  coachingCopy,
  careWidgetCopy,
  PLAN_VERSION,
};

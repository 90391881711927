import React from "react";

import OutlinedButton from "../plan_unfurled/outlinedButton";
import { TreatmentFAQButton } from "./treatmentFAQButton";
import { ReactComponent as Chat } from "../../components/plan_unfurled/icons/chat.svg";
import { ReactComponent as Coach } from "../../components/plan_unfurled/icons/coach.svg";
import { ReactComponent as Heart } from "../../components/plan_unfurled/icons/heart.svg";
import { ReactComponent as Blood } from "../../components/common/icons/blood.svg";
import { ReactComponent as Checklist } from "../../components/common/icons/checklist.svg";

import PrescriptionCard from "./PrescriptionCard";

import { secondaryColors } from "../../utils/viz";
import { eventNames } from "../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { getWeekCalendlyURL, getWeekCTA } from "./constants";
import { useIntercom } from "react-use-intercom";
import usePrescriptions from "../../hooks/care/usePrescriptions";

import {
  Consult,
  Prescription,
  TreatmentPlan as TreatmentPlanType,
} from "../../types/care";
import {
  SEXUAL_ACTIVITY_TITLE,
  SEXUAL_ACTIVITY_P1,
  SEXUAL_ACTIVITY_P2,
  PERIOD_TITLE,
  PERIOD_P1,
  PERIOD_P2_TREATMENT_ONLY,
  TIPS_TITLE,
  TIPS_DESCRIPTION,
  TIPS_BULLETS,
} from "./constants";
import { TreatmentCalendarExport } from "./treatmentCalendar/treatmentCalendarExport";

type TreatmentPlanProps = {
  consult: Consult;
  treatmentPlan: TreatmentPlanType;
  isMenopausal: boolean;
  showRefillsCard: boolean;
  openPrescriptionModal: (prescription: Prescription) => void;
  baseUrl: string;
  retestLink: string;
};

const TreatmentPlan: React.FC<TreatmentPlanProps> = ({
  consult,
  treatmentPlan,
  isMenopausal,
  showRefillsCard,
  openPrescriptionModal,
  baseUrl,
  retestLink,
}) => {
  const { show } = useIntercom();

  const isAlaCareTreatmentPlan = consult?.purchase_type === "a-la-care";
  const planType = consult?.plan_type;
  const isTreatmentPlan = planType === "treatment";

  const { prescriptionsWithRefillsRemaining } = usePrescriptions(consult?.uid);
  const selectedTreatmentSlugs = consult.selected_treatments?.map(
    (treatment) => treatment.slug
  );

  const treatmentTagGroupingOrder = ["treat", "rebuild", "relief"];

  return (
    <>
      <div className="bg-white py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-center capitalize">Your Treatments</h2>
          <div className="mt-12 block md:grid grid-cols-5 sm:space-x-8">
            <div className="col-span-3 space-y-6">
              {treatmentPlan ? (
                treatmentPlan?.prescriptions
                  ?.sort((a, b) => {
                    if (consult.purchase_type !== "a-la-care") return 0;

                    const indexA = treatmentTagGroupingOrder.indexOf(
                      a.purpose_tag ?? ""
                    );
                    const indexB = treatmentTagGroupingOrder.indexOf(
                      b.purpose_tag ?? ""
                    );

                    if (indexA === -1) return 1; // move 'a' to the end if its purpose_tag is not found
                    if (indexB === -1) return -1; // move 'b' to the end if its purpose_tag is not found

                    return indexA - indexB; // sort based on the predefined order treat, rebuild, relief
                  })
                  ?.map((prescription) => (
                    <PrescriptionCard
                      key={prescription.slug}
                      prescription={prescription}
                      refillablePrescriptions={
                        prescriptionsWithRefillsRemaining
                      }
                      selectedTreatmentSlugs={selectedTreatmentSlugs}
                      consult={consult}
                      openModal={() => openPrescriptionModal(prescription)}
                    />
                  ))
              ) : (
                <div className="p-4 bg-evvy-cream rounded-md text-gray-500">
                  Loading prescriptions...
                </div>
              )}

              {consult.purchase_type === "a-la-care" && (
                <TreatmentCalendarExport
                  treatmentPlan={treatmentPlan}
                  consult={consult}
                  baseUrl={baseUrl}
                  retestLink={retestLink}
                  isMenopausal={isMenopausal}
                />
              )}
            </div>

            <div className="col-span-2 space-y-6 mt-8 sm:mt-0">
              <div className="bg-highlighter-mint-dull rounded-md">
                <div className="p-4 uppercase font-semibold tracking-wider text-sm text-white bg-evvy-pine rounded-t-md">
                  Best practices during {planType}
                </div>
                <div className="rounded-b-md">
                  <div className="p-6">
                    <div className="b1 medium mb-2 flex">
                      <Heart
                        stroke={secondaryColors["evvy-pine"]}
                        height="16px"
                        width="16px"
                        className="mt-0.5"
                      />
                      <div className="ml-2 mb-2 leading-5">
                        {SEXUAL_ACTIVITY_TITLE}
                      </div>
                    </div>
                    <div className="b2">{SEXUAL_ACTIVITY_P1[planType]}</div>
                    <div className="b2 mt-3">
                      {SEXUAL_ACTIVITY_P2[planType]}
                    </div>
                  </div>
                  <hr className="bg-highlighter-mint m-0" />
                  {!isMenopausal && (
                    <div className="p-6">
                      <div className="b1 medium mb-2 flex">
                        <Blood
                          stroke={secondaryColors["evvy-pine"]}
                          height="16px"
                          width="16px"
                          className="mt-0.5"
                        />
                        <div className="ml-2 mb-2 leading-5">
                          {PERIOD_TITLE}
                        </div>
                      </div>
                      <div className="b2">{PERIOD_P1}</div>
                      {isTreatmentPlan && (
                        <div className="b2 mt-3">
                          {PERIOD_P2_TREATMENT_ONLY}
                        </div>
                      )}
                    </div>
                  )}
                  <hr className="bg-highlighter-mint m-0" />
                  <div className="p-6">
                    <div className="b1 medium mb-2 flex items-center">
                      <Checklist
                        className="mb-2"
                        stroke={secondaryColors["evvy-pine"]}
                        height="16px"
                        width="16px"
                      />
                      <div className="ml-2 mb-2 leading-5">{TIPS_TITLE}</div>
                    </div>
                    <div className="b2">{TIPS_DESCRIPTION}</div>
                    <ul className="b2 list-disc space-y-0.5">
                      <li className="mb-0 mt-2">{TIPS_BULLETS[0]}</li>
                      <li className="my-0">{TIPS_BULLETS[1]}</li>
                      <li className="my-0">{TIPS_BULLETS[2]}</li>
                      <li className="my-0">{TIPS_BULLETS[3]}</li>
                    </ul>
                  </div>
                </div>
              </div>
              {!isAlaCareTreatmentPlan && (
                <div className="mt-8 max-w-xs inline-block space-y-4">
                  <div className="t3 medium mb-4 text-evvy-pine">
                    Have Questions?
                  </div>

                  {/* Only if the current week has a CTA and only for treatment plans */}
                  {isTreatmentPlan &&
                    !isAlaCareTreatmentPlan &&
                    treatmentPlan &&
                    treatmentPlan.calendar &&
                    treatmentPlan.calendar.current_treatment_day &&
                    getWeekCTA(
                      treatmentPlan.calendar.current_treatment_day.week,
                      treatmentPlan.calendar.last_treatment_week
                    ) && (
                      <OutlinedButton
                        href={getWeekCalendlyURL()}
                        icon={
                          <Coach
                            stroke={secondaryColors["evvy-pine"]}
                            width="100%"
                            height="100%"
                          />
                        }
                        text="Book a coaching call"
                        color="evvy-pine"
                        onClick={analyticsClickHandler({
                          eventName: eventNames.TREATMENT_CLICKED_COACHING,
                          eventArgs: {
                            consultId: consult?.uid,
                            testHash: treatmentPlan?.test?.hash,
                            location: "your treatment",
                          },
                        })}
                      />
                    )}

                  <OutlinedButton
                    icon={
                      <Chat
                        stroke={secondaryColors["evvy-pine"]}
                        width="100%"
                        height="100%"
                      />
                    }
                    text="Ask us a question"
                    color="evvy-pine"
                    onClick={analyticsClickHandler({
                      eventName: eventNames.TREATMENT_ASK_QUESTION,
                      eventArgs: {
                        consultId: consult?.uid,
                        testHash: treatmentPlan?.test?.hash,
                        location: "your treatment",
                      },
                      clickHandler: () => show(),
                    })}
                  />
                  <TreatmentFAQButton
                    analyticsArgs={{
                      consultId: consult?.uid,
                      testHash: treatmentPlan?.test?.hash,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentPlan;

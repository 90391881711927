import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout";

import { usePDF } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";

import TreatmentPlanPdfExport from "../../components/care/treatmentCalendarPdfExport";

import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import useTreatmentPlan from "../../hooks/care/useTreatmentPlan";

// actual PDF content component (requires consult and treatmentPlan to be loaded to use hook)
const RenderDebugPDF = ({ consult, treatmentPlan }) => {
  const currentUser = useLoggedInUser();
  // create the PDF contents
  const [instance] = usePDF({
    document: (
      <TreatmentPlanPdfExport
        consult={consult}
        patientFirstName={currentUser.identity.firstName}
      />
    ),
  });

  return (
    <React.Fragment>
      <div>Download</div>
      {instance && (
        <a
          href={instance.url}
          disabled={instance.loading || !instance.url}
          download={`evvy-treatment-plan.pdf`}
        >
          <button className="mb-4 p-3 px-6 rounded-md bg-evvy-blue text-white inline-block">
            go
          </button>
        </a>
      )}

      <div>Debug View</div>
      {treatmentPlan && instance && (
        <PDFViewer className="w-full block" style={{ height: "600px" }}>
          <TreatmentPlanPdfExport consult={consult} isEmbed={true} />
        </PDFViewer>
      )}
    </React.Fragment>
  );
};

// Just a DEBUG view to see the PDF
const PdfDebugView = ({ consults }) => {
  const { consultId } = useParams();
  const consult = consults.filter((c) => c.uid === consultId)[0];
  const { treatmentPlan } = useTreatmentPlan(consultId);

  return (
    <Layout title="Care | PDF" padded={true} bgClass="bg-evvy-cream" full>
      {treatmentPlan && consult ? (
        <RenderDebugPDF consult={consult} treatmentPlan={treatmentPlan} />
      ) : (
        "loading..."
      )}
    </Layout>
  );
};

export default PdfDebugView;

import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "../../components/layout";

import Consults from "./consults";
import ConsultIntake from "./consultIntake";
import STIConsultIntake from "./stiConsultIntake";
import UngatedRxConsultIntake from "./intake/ungatedRx/index";
import LoadingConsult from "./intake/ungatedRx/Loading";
import ConsultTreatment from "./consultTreatment";
import ConsultTreatmentPdf from "./consultTreatmentPdf"; // debug only, remove later
import PastTreatments from "./pastTreatments";
import MessagesHome from "./messagesHome";
import MessageThread from "./messageThread";
import { SampleTreatmentPlan } from "./sampleTreatmentPlan";

import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { RecommendedTreatmentPlan } from "./recommendedTreatmentPlan";
import { BuildingRecommendations } from "./buildingRecommendations";
import RefillsCart from "./refills/refillsCart";
import { TreatmentPDP } from "./aLaCare/treatmentPDP";
import TransparentCareLanding from "./transparentCare/TransparentCareLanding";
import { ConsultsContext } from "../../contexts/consultsContext";

// top-level router for care screens
const Care = () => {
  /*
   * State
   */
  const [error, setError] = useState("");
  const [consults, setConsults] = useState([]);
  const {
    consults: contextConsults,
    loading,
    refetchConsults,
  } = useContext(ConsultsContext);

  /*
   * Context
   */
  const currentUser = useLoggedInUser();

  /*
   * Effects
   */
  useEffect(() => {
    // fetch all user's consults when they load up the page
    refetchConsults();
  }, []); // one-time action when loading up the care page

  useEffect(() => {
    if (contextConsults) {
      setConsults(contextConsults);
    }
  }, [contextConsults]);

  const vaginitisOrUngatedRxConsults = consults.filter(
    (consult) => consult.type === "vaginitis" || consult.type === "ungated-rx"
  );

  const stiConsults = consults.filter((consult) => consult.type === "sti");

  const mostRecentVaginitisOrUngatedRxConsult = vaginitisOrUngatedRxConsults[0];
  const firstSTIConsult = stiConsults[0];

  const completedConsults = consults.filter(
    (consult) => consult.status === "CP"
  );
  const mostRecentCompletedVaginitisOrUngatedRxConsult = completedConsults.find(
    (consult) => consult.type === "vaginitis" || consult.type === "ungated-rx"
  );
  const firstConsultWithRefills = completedConsults?.find(
    (consult) => consult?.ready_for_refills
  );

  return (
    <>
      {error ? (
        <Layout>
          <div className="text-red-500">{error}</div>
        </Layout>
      ) : loading ? (
        <Layout>
          <div>
            {/* TODO: do we want to show a loader while fetching consults? */}
          </div>
        </Layout>
      ) : (
        <Routes>
          {/* Until we have content in the CareLanding and Consults page, just direct to first ConsultTreatment */}
          {/* If user has a latest consult and it is in complete status */}
          {mostRecentCompletedVaginitisOrUngatedRxConsult &&
          mostRecentCompletedVaginitisOrUngatedRxConsult.status === "CP" ? (
            <Route
              path="/treatment/"
              element={
                <Navigate
                  to={`../consults/${mostRecentCompletedVaginitisOrUngatedRxConsult.uid}/treatment/`}
                />
              }
            />
          ) : (
            ""
          )}

          {/* Vanity url for refills */}
          <Route
            path="/refills/"
            element={
              firstConsultWithRefills ? (
                <Navigate
                  to={`../consults/${firstConsultWithRefills.uid}/refills/`}
                />
              ) : (
                // just go to /care/
                <Navigate to="../" />
              )
            }
          />

          {mostRecentVaginitisOrUngatedRxConsult &&
            ["IN", "OR", "ST"].includes(
              mostRecentVaginitisOrUngatedRxConsult.status
            ) && (
              <Route
                path={"/intake/"}
                element={
                  mostRecentVaginitisOrUngatedRxConsult.type === "vaginitis" ? (
                    // experiment group people drop back into intake on the shipping page
                    <Navigate
                      to={`../consults/${mostRecentVaginitisOrUngatedRxConsult.uid}/intake/demographics/`}
                    />
                  ) : (
                    // ungated rx!
                    <Navigate
                      to={`../rx/consults/${mostRecentVaginitisOrUngatedRxConsult.uid}/intake/consent/`}
                    />
                  )
                }
              />
            )}

          {firstSTIConsult &&
            ["IN", "OR", "ST"].includes(firstSTIConsult.status) && (
              <Route
                path={"/sti/intake/"}
                element={
                  <Navigate
                    to={`../sti/consults/${firstSTIConsult.uid}/intake/demographics/`}
                  />
                }
              />
            )}

          {/* Landing page route to let user opt in, or direct towards one of their consults */}
          <Route
            path="/"
            element={<TransparentCareLanding consults={consults} />}
          />
          <Route path="/sample-plan/" element={<SampleTreatmentPlan />} />
          <Route path="/consults/" element={<Consults consults={consults} />} />
          <Route
            path="/past-treatments/"
            element={<PastTreatments completedConsults={completedConsults} />}
          />
          <Route
            path="/shop/treatment/:treatmentSlug/"
            element={<TreatmentPDP />}
          />
          <Route
            path="/consults/messages/"
            element={<MessagesHome consults={consults} loading={loading} />}
          />
          <Route
            path="/consults/:consultId/messages/"
            element={<MessageThread consults={consults} />}
          />
          {/* Debug view for PDF. Staff or non-prod only */}
          {currentUser && currentUser.features.carePdfDebug && (
            <Route
              path="/consults/:consultId/treatment/pdf/"
              element={<ConsultTreatmentPdf consults={consults} />}
            />
          )}
          <Route
            path="/consults/:consultId/treatment/*"
            element={<ConsultTreatment consults={consults} />}
          />
          <Route
            path="/consults/:consultId/intake/*"
            element={<ConsultIntake consults={consults} />}
          />
          <Route
            path="/sti/consults/:consultId/intake/*"
            element={<STIConsultIntake consults={consults} />}
          />
          <Route
            path="rx/consults/intake/loading/*"
            element={<LoadingConsult source={"health-history-upsell"} />}
          />
          <Route
            path="/rx/consults/:consultId/intake/*"
            element={<UngatedRxConsultIntake consults={consults} />}
          />
          <Route
            path="/consults/:consultId/recommended-treatment-plan/*"
            element={<RecommendedTreatmentPlan consults={consults} />}
          />
          <Route
            path="/consults/:consultId/build-recommendations/*"
            element={<BuildingRecommendations />}
          />
          <Route
            path="/consults/:consultId/refills/:treatment?"
            element={<RefillsCart consults={consults} />}
          />
        </Routes>
      )}
    </>
  );
};

export default Care;

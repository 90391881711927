import Layout from "../../components/layout";
import StickyHeader from "../../components/common/stickyHeader";
import { ResultsLoadingAnimation } from "../results_v2/viz";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendRecommendedTreatmentViewLoadingPage } from "../../utils/analytics/customEventTracking";

const CALCULATING_COPY =
  "Calculating a sample treatment program based on the world’s largest dataset on the vaginal microbiome...";

export const BuildingRecommendations: React.FC<{}> = () => {
  const { consultId } = useParams();

  const sawBuildingRecommendationsKey = `consult-recommendations-loading-${consultId}`;

  // wait 6 seconds and then reload page
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `/care/consults/${consultId}/recommended-treatment-plan/`;
    }, 6000);
    // set local storage key to true so that we don't show this page again
    localStorage.setItem(sawBuildingRecommendationsKey, "true");
    // trigger analytics event
    sendRecommendedTreatmentViewLoadingPage({ consultId });
  }, []);

  return (
    <Layout
      title={`Care | Building Recommendations`}
      bgClass="bg-evvy-cream"
      full
      centered
      header={
        <StickyHeader
          backLink="/care/"
          backText="Back to Care"
          className="bg-evvy-cream"
          onClickBack={() => {}} // TODO: add analytics event
        />
      }
    >
      <div className="">
        <div className="-mt-10" />
        <ResultsLoadingAnimation />
        <div className="-mt-10 mb-20 medium text-lg md:text-xl text-center px-4">
          {CALCULATING_COPY}
        </div>
      </div>
    </Layout>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scrollama, Step } from "react-scrollama";

// components
import Layout from "../../components/layout";
import StickyHeader from "../../components/common/stickyHeader";
import { RemoveAntibioticsCheckbox } from "../../components/care/removeAntibioticsCheckbox";
import {
  sendRecommendedTreatmentClickedBuy,
  sendRecommendedTreatmentExpandPrescriptionInfo,
  sendRecommendedTreatmentViewPage,
  sendRemoveAntibioticsClicked,
} from "../../utils/analytics/customEventTracking";
import Modal from "../../components/common/modal";
import PricingTemplate from "../../components/plan_unfurled/modalTemplates/pricing";
import Markdown from "markdown-to-jsx";
import RectangularButton from "../../components/common/rectangularButton";
import { ReactComponent as Stethoscope } from "../../components/common/icons/stethoscope.svg";
import { ReactComponent as ArrowDown } from "../../components/common/icons/arrow-head-down.svg";
import LoadingSpinner from "../../components/common/loadingSpinner";
import { Bullets } from "../../components/common/bullets";
import { PrescriptionPreviewTemplate } from "../../components/care/modalTemplates/prescriptionPreview";
// images
import CarePackagingImg from "../../components/care/images/care-packaging-bg-gradient.png";
import ThreeDoctors from "../../components/care/images/doctors.png";

import { CarePricingInfo, Consult, Prescription } from "../../types/care";
import { careService } from "../../services/care";
import { testsService } from "../../services/tests";
import { AxiosError, AxiosResponse } from "axios";
import {
  getPhasesFromPrescriptions,
  getTagBackgroundClass,
} from "../../components/care/utils";
import { Test } from "../../types/test";
import { useLoggedInUser } from "../../hooks/useLoggedInUser";
import { getIsMobile } from "../../utils/general";
import GetCareStickyFooter from "../../components/care/getCareStickyFooter";
import { useExperiments } from "../../hooks/useExperiments";
import classNames from "classnames";
import {
  CARE_INTAKE_STATIC_PATH,
  SHIPPING_DAYS,
} from "../../components/care/constants";

const TITLE = "Your sample treatment program";
const CUSTOMIZE_TREATMENT = "Customize Treatment";
const ANYTHING_ELSE_QUESTION =
  "Is there anything else you'd like your doctor know as they put together your personalized treatment plan?";
const WHATS_INCLUDED = "What else is included in your treatment program?";
const BUY_CTA = "Buy treatment program";
const PROCEED_CTA = "Continue";
const REMINDER_COPY =
  "After you complete checkout, return to your Evvy portal to finish your intake and provide your shipping information.";

export const RecommendedTreatmentPlan: React.FC<{ consults: Consult[] }> = ({
  consults,
}) => {
  const { consultId } = useParams();

  //   state
  const [consult, setConsult] = useState<Consult | undefined>(
    consults.find((c) => c.uid === consultId)
  );
  const testHash = consult?.test_hash;
  const [modalData, setModalData] = useState<{ type: string; data: any }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [products, setProducts] = useState<Prescription[]>([]);
  const [anythingElse, setAnythingElse] = useState<string>("");
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false);
  const [removeAntibiotics, setRemoveAntibiotics] = useState<boolean>(false);
  const [test, setTest] = useState<Test>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [pricingInfo, setPricingInfo] = useState<CarePricingInfo>();
  // mobile scroll state
  const [currentStep, setCurrentStep] = useState<number>(0);
  const showStickyFooter = currentStep > 1;

  const navigate = useNavigate();
  const currentUser = useLoggedInUser();
  const experiments = useExperiments();

  const treatmentProgramTitle = `'s Treatment Program`;

  const productsToDisplay = removeAntibiotics
    ? products.filter((p) => p.tag !== "Reduce Disruptive")
    : products;

  //   effects
  useEffect(() => {
    async function fetchRecommendedTreatmentPlan() {
      try {
        setLoading(true);
        const response = await careService.asyncFetchRecommendedTreatmentPlan(
          consult?.uid
        );
        setProducts(response.data.products);
      } catch (error) {
        setError("Error loading recommended treatment plan");
      }
      setLoading(false);
    }

    function fetchTest() {
      setLoading(true);
      if (testHash) {
        testsService.fetchTest(
          testHash,
          (response: AxiosResponse) => {
            setTest(response.data);
            setPricingInfo(
              response.data.eligible_treatment_pathways[0]?.pricing_info
            );
            setLoading(false);
          },
          (error: AxiosError) => {
            setError("Error loading consult test");
            setLoading(false);
          }
        );
      }
    }

    async function fetchConsultIntake() {
      try {
        setLoading(true);
        const response = await careService.fetchConsultIntake(
          consultId,
          (resp: AxiosResponse) => resp
        );
        setAnythingElse(response.data.anything_else);
        setAlreadySubmitted(response.data.submitted_at !== null);
        setRemoveAntibiotics(response.data.remove_antibiotics_selected);
        setConsult(response.data.consult);
      } catch (error) {
        setError("Error loading consult intake");
      }
      setLoading(false);
    }

    if (consult) {
      fetchRecommendedTreatmentPlan();
      fetchTest();
      fetchConsultIntake();
    }
  }, [consultId, testHash]);

  // reset pricing info when removeAntibiotics or test changes
  useEffect(() => {
    // update prescription_services to be -50 if removeAntibiotics is true
    if (test) {
      const newPricingInfo = {
        ...test.eligible_treatment_pathways[0]?.pricing_info,
      };
      if (removeAntibiotics) {
        newPricingInfo.prescription_services -= 50;
        newPricingInfo.discounted_total -= 50;
        newPricingInfo.full_total -= 50;
      }
      setPricingInfo(newPricingInfo);
    }
  }, [removeAntibiotics, test]);

  // once products are loaded, then trigger analytics event
  useEffect(() => {
    if (consult && test && products.length > 0) {
      sendRecommendedTreatmentViewPage(getBaseAnalyticsEventArgs());
    }
  }, [products, test, consult]);

  // actions
  const closeModal = () => {
    setModalData(undefined);
  };

  const renderModal = (type: string, data: any) => {
    switch (type) {
      case "pricing":
        return <PricingTemplate handleClose={closeModal} data={data} />;
      case "prescription":
        return (
          <PrescriptionPreviewTemplate handleClose={closeModal} data={data} />
        );
      default:
        throw new Error("Invalid modal type");
    }
  };

  const updateConsultIntakeAnythingElse = async (anythingElseInput: string) => {
    // if intake already submitted, then cannot update
    if (alreadySubmitted) {
      return;
    }

    try {
      await careService.updateConsultIntake(consultId, {
        anything_else: anythingElseInput,
      });
    } catch (error) {
      setError("Error updating consult intake");
    }
  };

  async function buyTreatmentProgram() {
    // send analytics event!
    sendRecommendedTreatmentClickedBuy(getBaseAnalyticsEventArgs());

    // if already paid for care, then just take them to the care intake
    if (consult?.consult_paid) {
      navigate(CARE_INTAKE_STATIC_PATH);
      return;
    }

    setSubmitting(true);

    // update intake
    if (anythingElse) {
      await updateConsultIntakeAnythingElse(anythingElse);
    }

    // set remove antibiotics settings and then go to checkout
    try {
      await careService.asyncSelectRemoveAntibiotics(
        consult?.uid,
        removeAntibiotics
      );
      careService.fetchConsultCheckoutURL(consultId, (resp: AxiosResponse) => {
        const { checkout_url } = resp.data;
        window.location = checkout_url;
      });
    } catch (error) {
      setError("Error updating antibiotics setting");
    }
  }

  const openPrescriptionInfoModal = (payload: {
    type: string;
    data: { prescription: Prescription };
  }) => {
    setModalData(payload);
    sendRecommendedTreatmentExpandPrescriptionInfo({
      prescriptionSlug: payload.data.prescription.slug,
      ...getBaseAnalyticsEventArgs(),
    });
  };

  const getBaseAnalyticsEventArgs = () => {
    return {
      consultId: consult?.uid,
      testHash: test?.hash,
      testOrder: test?.test_order,
      carePrice:
        pricingInfo?.discounted_total ||
        consult?.treatment_pathway.pricing_info.discounted_total,
      treatmentPathway: consult?.treatment_pathway.slug,
      recommendedProducts: products.map((p) => p.slug),
    };
  };

  // scroll actions
  const onStepEnter = ({ data }: { data: number }) => {
    setCurrentStep(data);
    console.log("data", data);
  };

  const onStepExit = ({ data }: { data: number }) => {
    if (data === 1) {
      setCurrentStep(0);
    }
  };

  return (
    <Layout
      title={`Care | ${TITLE}`}
      bgClass="bg-evvy-cream"
      full
      centered
      header={
        <StickyHeader
          backLink={`/care/consults/${consultId}/intake/treatment/general/`}
          backText="Back to Intake"
          className="bg-evvy-cream"
        />
      }
    >
      {modalData && (
        <Modal closeModal={closeModal} widthClass="max-w-lg" hasFocusItem>
          {renderModal(modalData.type, modalData.data)}
        </Modal>
      )}
      <div className="max-w-[900px] pb-3 md:pb-0">
        <h2 className="text-center mt-7 mb-8 md:mt-12 md:mb-16 text-3xl md:text-5xl px-20">
          {TITLE}
        </h2>
        {!consult || loading ? (
          <LoadingSpinner />
        ) : error ? (
          <div className="text-center bg-coral p-2">{error}</div>
        ) : (
          <div className="mx-auto px-4 md:px-0">
            <div className="grid md:grid-cols-5 md:space-x-12 mx-auto">
              <Scrollama
                onStepEnter={onStepEnter}
                onStepExit={onStepExit}
                offset={0.1}
              >
                <Step data={1} key="step-1">
                  <div className="md:col-span-2 md:sticky md:top-24 md:h-screen mb-5">
                    <div className="bg-white rounded-lg p-6">
                      <div className="t2 semibold">
                        <span className="capitalize">
                          {currentUser?.identity.firstName}
                        </span>
                        {treatmentProgramTitle}
                      </div>
                      <div className="my-5">
                        <img
                          src={CarePackagingImg}
                          loading="lazy"
                          alt="Care packaging"
                          className="object-cover"
                        />
                      </div>
                      <div>
                        <div className="uppercase text-sm semibold tracking-wider">
                          {CUSTOMIZE_TREATMENT}
                        </div>
                        <div className="italic text-sm mt-2">
                          {ANYTHING_ELSE_QUESTION}
                        </div>
                        <div className="mb-4">
                          <textarea
                            id="anything_else"
                            name="anything_else"
                            className="w-full focus:outline-none focus:ring-transparent focus:border-crazy-purple rounded-xs p-3 mt-2"
                            rows={3}
                            value={anythingElse}
                            onChange={(e) => setAnythingElse(e.target.value)}
                          />
                        </div>

                        <div className="italic text-sm my-2">
                          {REMINDER_COPY}
                        </div>
                      </div>
                      <hr className="my-4" />
                      <div className="flex justify-between my-4">
                        <div className="my-auto semibold">
                          <span className="ml-1 text-2xl text-evvy-pine">{`$${pricingInfo?.discounted_total}`}</span>
                        </div>
                      </div>
                      <RectangularButton
                        disabled={loading || submitting}
                        text={consult?.consult_paid ? PROCEED_CTA : BUY_CTA}
                        bgColorClass="bg-evvy-blue"
                        textColorClass="text-evvy-black"
                        fullWidth
                        verticalPadding="py-6"
                        onClick={buyTreatmentProgram}
                      />
                    </div>
                  </div>
                </Step>
                <Step data={2} key="step-2">
                  <div className="md:col-span-3 pt-5 md:pt-0">
                    <div>
                      <RecommendedTreatments
                        products={productsToDisplay}
                        openModal={openPrescriptionInfoModal}
                      />
                    </div>
                  </div>
                </Step>
              </Scrollama>
            </div>
          </div>
        )}
        {showStickyFooter && (
          <GetCareStickyFooter
            className="md:hidden"
            zIndex="z-10"
            ctaText={`${BUY_CTA}`}
            onClick={buyTreatmentProgram}
          />
        )}
      </div>
    </Layout>
  );
};

const PLAN_SUBTITLE = "What your plan may look like";
const PLAN_DISCLAIMER =
  "Below is a sample treatment program that has been prescribed to people similar to you. Your specific treatment program will be developed at the sole discretion of an Evvy-affiliated provider based on your results, health profile, and clinical intake. Your program may differ from what is shown below.";
const BULLETS = [
  "20 minute onboarding call with a certified health coach and dedicated asynchronous support throughout your program",
  "Personalized day-by-day treatment calendar created by an Evvy-affiliated provider",
  `Custom treatments shipped in ${SHIPPING_DAYS} in discreet packaging`,
];

const RecommendedTreatments: React.FC<{
  products: Prescription[];
  openModal: (data: any) => void;
}> = ({ products, openModal }) => {
  const phases = getPhasesFromPrescriptions(products);
  const arrowHeightClass =
    phases.length > 2 ? "h-5/6" : phases.length > 1 ? "h-3/4" : "h-1/2";
  const description = `A treatment program for results like yours often contains ${phases.length} main phase(s):`;

  const isMobile = getIsMobile();

  return (
    <div className="mb-3 md:mb-16">
      <div className="p-4 sm:p-8 rounded-md border border-evvy-dark-cream">
        <div className={`${isMobile ? "b2" : "t3"} medium`}>{description}</div>
        <div className="flex pt-4 pb-3 md:pt-6 md:pb-1">
          <div className="justify-center">
            <div className="w-4 h-4 border-2 border-evvy-dark-cream rounded-full mx-auto" />
            <div
              className={`w-0.5 bg-evvy-dark-cream ${arrowHeightClass} mx-auto`}
            />
            <ArrowDown className={`-mt-0.5 mx-auto text-evvy-dark-cream`} />
          </div>
          <div className="ml-3 md:ml-5 space-y-2 w-full">
            {phases.map((p) => (
              <div
                key={p.number}
                className="flex border border-evvy-dark-cream rounded-md"
              >
                <div
                  className={`rounded-l-md flex-shrink-0 px-1 bg-gradient-to-b ${p.bgClass}`}
                />
                <div className="px-2 py-1.5 bg-stone-100 w-full rounded-r-md">
                  <span className="t4">Phase {p.number}: </span>
                  <span className={`ml-1 b2 regular`}>{p.goal}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex space-x-3 items-center">
        <div className="flex-1 border-b border-evvy-dark-cream" />
        <div className="t4 my-5">{PLAN_SUBTITLE}</div>
        <div className="flex-1 border-b border-evvy-dark-cream" />
      </div>
      <div className="flex text-evvy-pine p-4 space-x-3 bg-highlighter-mint-dull rounded-xl">
        <div className="my-auto">
          <Stethoscope className="w-5 h-5" />
        </div>
        <div className="text-sm medium">{PLAN_DISCLAIMER}</div>
      </div>
      <div className="mt-4 space-y-4">
        {products.map((p) => (
          <PrescriptionCard
            key={p.slug}
            prescription={p}
            onClick={() =>
              openModal({
                type: "prescription",
                data: { prescription: p },
              })
            }
          />
        ))}
      </div>
      <hr className="my-8 md:my-6" />
      <div>
        <div className="bg-evvy-pine py-5 px-4 flex flex-col md:flex-row items-center rounded-t-xl">
          <img
            src={ThreeDoctors}
            loading="lazy"
            alt="Evvy doctors"
            className="h-8 md:h-10 pb-2 md:pb-0"
          />
          <div
            className={`ml-3 ${isMobile ? "b3" : "b1"} medium text-evvy-white`}
          >
            {WHATS_INCLUDED}
          </div>
        </div>
        <div className="bg-highlighter-mint-dull rounded-b-xl px-5 py-3">
          <Bullets className={isMobile ? "b2 regular" : ""} bullets={BULLETS} />
        </div>
      </div>
    </div>
  );
};

const PrescriptionCard: React.FC<{
  prescription: Prescription;
  onClick: () => void;
}> = ({ prescription, onClick }) => {
  const isMobile = getIsMobile();

  if (isMobile) {
    return (
      <PrescriptionCardMobile prescription={prescription} onClick={onClick} />
    );
  }

  return (
    <div className="bg-evvy-white p-4 flex rounded-lg border border-dark-evvy-cream space-x-5">
      <div className="w-32">
        <img className="w-full h-full" src={prescription.image_url} />
      </div>
      <div className="flex-1">
        <div className="flex justify-between">
          <SmallPrescriptionTag tag={prescription.tag} />
          <div
            className="underline underline-offset-2 cursor-pointer b2 regular"
            onClick={onClick}
          >
            Learn more
          </div>
        </div>
        <div className="t3 medium py-2">{prescription.preview_title}</div>
        <div className="b2 regular">
          {prescription.preview_short_description}
        </div>
      </div>
    </div>
  );
};

const PrescriptionCardMobile: React.FC<{
  prescription: Prescription;
  onClick: () => void;
}> = ({ prescription, onClick }) => {
  const tagBackgroundColorClass = getTagBackgroundClass(prescription.tag);

  return (
    <div className="bg-evvy-white rounded-lg border border-dark-evvy-cream">
      <div className="flex justify-end">
        <div
          className={`rounded-tr-lg bg-gradient-to-r ${tagBackgroundColorClass} items-center px-3 py-1.5 text-[12px] uppercase semibold tracking-wider`}
        >
          {prescription.tag}
        </div>
      </div>
      <div className="px-4">
        <div className="flex">
          {/* <div className=""> */}
          <img className="w-24 h-24" src={prescription.image_url} />
          {/* </div> */}
          <div className="ml-3 t3 medium my-auto">
            {prescription.preview_title}
          </div>
        </div>

        <div className="text-sm regular py-4">
          {prescription.preview_short_description}
        </div>
        <div className="flex justify-end pb-5">
          <div
            className="underline underline-offset-2 cursor-pointer b2 regular"
            onClick={onClick}
          >
            Learn more
          </div>
        </div>
      </div>
    </div>
  );
};

const SmallPrescriptionTag: React.FC<{ tag: string }> = ({ tag }) => {
  const tagBackgroundColorClass = getTagBackgroundClass(tag);
  return (
    <>
      <div
        className={`uppercase text-sm font-semibold tracking-wider flex items-center rounded-sm flex-shrink-0 py-2 px-3.5 bg-gradient-to-r ${tagBackgroundColorClass}`}
      >
        {tag}
      </div>
    </>
  );
};

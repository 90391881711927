import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Markdown from "markdown-to-jsx";

// components
import RectangularButton from "../common/rectangularButton";
import { ReactComponent as Checkmark } from "../../components/common/icons/checkmark.svg";

// lib
import { getIsMobile } from "../../utils/general";
import { analyticsClickHandler } from "../../utils/analytics/helpers";
import { eventNames } from "../../utils/analytics/customEventTracking";

// consts
import { careLandingCopy } from "./constants";
import { handleCareIntroCTAClick } from "./utils";

const CoachingIntro = ({
  consult,
  test,
  completedConsults,
  analyticsEventArgs,
}) => {
  const navigate = useNavigate();
  // This component is only used for care ineligible tests now.
  const isMobile = getIsMobile();
  const careCopy = careLandingCopy.careIneligible;
  const careCTAText = careCopy.ctaText;
  const hasPastTreatments =
    consult?.status === "CP" && test?.eligible_for_care
      ? completedConsults?.length > 1
      : completedConsults?.length > 0;
  const hasPastConsultMessages = completedConsults.some(
    (c) => c?.consultmessagethread
  );

  return (
    <div className="max-w-6xl w-full mx-auto py-8 lg:py-12">
      <div className="block lg:flex flex-wrap lg:space-x-32">
        <div className="flex-1 my-auto">
          <div className="px-5 lg:px-8 lg:mt-10">
            <h3 className="block lg:hidden">{careCopy.title}</h3>
            <h2 className="hidden lg:block">{careCopy.title}</h2>
            <div className="text-lg lg:text-xl mb-6">
              <Markdown>{careCopy.descriptionOne}</Markdown>
            </div>
            <div className="text-lg lg:text-xl mb-6">
              <Markdown>{careCopy.descriptionTwo}</Markdown>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="mt-8 lg:mt-2 px-4 lg:px-0">
            <div className="bg-gradient-to-r from-evvy-blue-gradientLight to-evvy-blue p-1.5"></div>
            <div className="bg-evvy-white p-6 lg:p-7">
              <div className="flex">
                <div className="text-[12px] lg:text-sm font-semibold tracking-wider uppercase bg-highlighter-yellow p-2 mb-4 lg:mb-8">
                  recommended
                </div>
              </div>

              <div className="text-xl lg:text-2xl font-medium mb-2">
                {careCopy.blockTitle}
              </div>
              <ul className={`mt-4 list-none ml-0 ${isMobile ? "b2" : "b1"}`}>
                {careCopy.bullets.map((bullet, index) => (
                  <li
                    key={index}
                    className={`flex ${
                      index < careCopy.bullets.length - 1 ? "pb-2" : ""
                    }`}
                  >
                    <span className="mt-0.5 mr-2">
                      <Checkmark />
                    </span>
                    {bullet}
                  </li>
                ))}
              </ul>
              <div className="mt-0 lg:mt-10">
                <RectangularButton
                  text={careCTAText}
                  bgColorClass="bg-evvy-blue"
                  textColorClass="text-evvy-black"
                  onClick={() => {
                    handleCareIntroCTAClick(
                      test,
                      consult,
                      false,
                      navigate,
                      careCTAText,
                      "coaching-intro"
                    );
                  }}
                  fullWidth
                  verticalPadding="py-6"
                />
              </div>
            </div>
            <div>
              {(hasPastTreatments || hasPastConsultMessages) && (
                <div className="mt-6 lg:mt-10 mb-2 text-center">
                  {hasPastTreatments && (
                    <Link
                      to="/care/past-treatments/"
                      className="linkedText underline persistSize medium border-b border-evvy-black cursor-pointer w-max p-pd mt-8"
                      onClick={analyticsClickHandler({
                        eventName:
                          eventNames.CARE_CLICKED_VIEW_PAST_CONSULTATIONS,
                        eventArgs: analyticsEventArgs,
                      })}
                    >
                      View past treatment programs
                    </Link>
                  )}

                  {hasPastTreatments && hasPastConsultMessages && (
                    <span className="hidden sm:inline-block px-2">|</span>
                  )}

                  {hasPastConsultMessages && (
                    <Link
                      to="/care/consults/messages/"
                      className="linkedText underline persistSize medium border-b border-evvy-black cursor-pointer w-max p-pd mt-8"
                      onClick={analyticsClickHandler({
                        eventName: eventNames.CARE_CLICKED_VIEW_PAST_MESSAGES,
                        eventArgs: analyticsEventArgs,
                      })}
                    >
                      <Markdown>{`${
                        isMobile ? "<br><br>" : ""
                      }View past messages`}</Markdown>
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachingIntro;

import { useState, useEffect, useCallback } from "react";
import { HealthHistoryRecommendedProducts } from "../../types/care";
import { testsService } from "../../services/tests";

export const useHealthHistoryRecommendedProducts = (testHash?: string) => {
  const [data, setData] = useState<HealthHistoryRecommendedProducts>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  const fetchHealthHistoryRecommendedProducts = useCallback(async () => {
    if (testHash) {
      setIsLoading(true);
      try {
        const response = await testsService.fetchTestUngatedRxRecommendations(
          testHash
        );
        setData({
          ungatedRxTreatments: response.data.ungated_rx_treatments,
          symptoms: response.data.symptoms,
        });
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [testHash]);

  useEffect(() => {
    if (testHash) fetchHealthHistoryRecommendedProducts();
  }, [testHash, fetchHealthHistoryRecommendedProducts, refetch]);

  const refetchHealthHistoryRecommendedProducts = () => {
    setRefetch((prev) => !prev);
  };

  return {
    healthHistoryRecommendedProducts: data,
    error,
    healthHistoryRecommendedProductsIsLoading: isLoading,
    refetchHealthHistoryRecommendedProducts,
  };
};

import React, { useEffect } from "react";

import { usePDF } from "@react-pdf/renderer";

import TreatmentPlanPdfExport from "../treatmentCalendarPdfExport";
import TreatmentCalendarIcsExport from "../treatmentCalendarIcsExport";
import { eventNames } from "../../../utils/analytics/customEventTracking";
import { analyticsClickHandler } from "../../../utils/analytics/helpers";
import { useLoggedInUser } from "../../../hooks/useLoggedInUser";
import { Consult, TreatmentPlan } from "../../../types/care";

// contains add to calendar button and the PDF download button
export const TreatmentCalendarExport: React.FC<{
  treatmentPlan: TreatmentPlan;
  consult: Consult;
  baseUrl: string;
  retestLink: string;
  onClickPopup?: () => void;
  isMenopausal?: boolean;
}> = ({
  treatmentPlan,
  consult,
  baseUrl,
  onClickPopup,
  retestLink,
  isMenopausal,
}) => {
  const currentUser = useLoggedInUser();
  // create the PDF contents
  // watch performance of this on page load. if it slows page down, put behind a modal route like results PDF
  const [instance, update] = usePDF({
    document: (
      <TreatmentPlanPdfExport
        consult={consult}
        patientFirstName={currentUser.identity.firstName}
        isEmbed={false}
        isMenopausal={isMenopausal}
      />
    ),
  });

  // re-render PDF upon treatmentPlan, currentUser or consult change
  useEffect(() => {
    console.log("running update");
    update();
  }, [treatmentPlan, consult, currentUser]);

  return (
    <div className="t4 underline underline-offset-4 sm:no-underline text-center sm:text-left sm:flex justify-center sm:justify-end mx-auto my-8">
      {/* PDF button */}
      {instance && !instance.loading && instance.url ? (
        <div className="sm:flex sm:space-x-8 space-y-5 sm:space-y-0">
          <a
            href={instance.url}
            download={onClickPopup ? null : `evvy-treatment-plan.pdf`}
          >
            <div
              onClick={
                onClickPopup ||
                analyticsClickHandler({
                  eventName: eventNames.TREATMENT_CLICKED_DOWNLOAD_PDF,
                  eventArgs: {
                    consultId: consult?.uid,
                    testHash: treatmentPlan?.test?.hash,
                  },
                })
              }
            >
              <div>{"Download as PDF  ↓"}</div>
            </div>
          </a>

          {consult.purchase_type !== "a-la-care" && (
            <TreatmentCalendarIcsExport
              treatmentPlan={treatmentPlan}
              baseUrl={baseUrl}
              onClickPopup={onClickPopup}
              retestLink={retestLink}
            >
              <div
                onClick={analyticsClickHandler({
                  eventName: eventNames.TREATMENT_CLICKED_ADD_CALENDAR,
                  eventArgs: {
                    consultId: consult.uid,
                    testHash: treatmentPlan.test.hash,
                  },
                })}
              >
                <div>{"Add to Calendar  ＋"}</div>
              </div>
            </TreatmentCalendarIcsExport>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

import { ReactComponent as Lightbulb } from "../../components/plan_unfurled/icons/lightbulb.svg";
import { ReactComponent as Blood } from "../common/icons/blood.svg";
import { secondaryColors } from "../../utils/viz";
import TreatmentCalendarCTA from "./treatmentCalendarCTA";
import Markdown from "markdown-to-jsx";

import {
  getWeekCTA,
  getWeekMessage,
  TREATMENT_TYPE_TO_COLOR,
} from "./constants";
import { transparentCarePhaseColorMap } from "../../utils/colors";
import { dayOfWeek, strDateToTimestamp } from "../../utils/datetime";
import { Period, Prescription, TreatmentPlan } from "../../types/care";
import { useMemo } from "react";
import { getDateDisplay } from "../../utils/datetime";

interface TreatmentCalendarWeekProps {
  treatmentPlan: TreatmentPlan;
  currentWeek: number;
  editingStartDate: boolean;
  retestLink: string;
  openPeriodModal: (periodId: Period["id"]) => void;
  openPrescriptionModal: (prescription: Prescription) => void;
}
// Renders this week's calendar
const TreatmentCalendarWeek: React.FC<TreatmentCalendarWeekProps> = ({
  treatmentPlan,
  currentWeek,
  editingStartDate,
  retestLink,
  openPeriodModal,
  openPrescriptionModal,
}) => {
  const treatmentCalendar = treatmentPlan.calendar;
  const planType = treatmentPlan.consult.plan_type;
  const isTreatmentPlan = planType === "treatment";

  const calendarTreatments = treatmentPlan.calendar.calendar_treatments;
  // map treatment slug to color. Successive treatments of same type will round robin through the colors
  const treatmentColorMap = useMemo(() => {
    return calendarTreatments.reduce(
      (acc, treatment, index, arr) => {
        const prevTreatmentType = index > 0 ? arr[index - 1].product.tag : "";
        const colorIndex =
          prevTreatmentType === treatment.product.tag
            ? (acc.indexMap[treatment.product.tag] + 1) %
              TREATMENT_TYPE_TO_COLOR[treatment.product.tag].length
            : 0;

        const prevTreatmentTypeTransparentCare =
          index > 0 ? arr[index - 1].product.purpose_tag : "";
        const colorIndexTransparentCare =
          prevTreatmentTypeTransparentCare === treatment.product.purpose_tag
            ? (acc.indexMap[treatment.product.tag] + 1) %
              TREATMENT_TYPE_TO_COLOR[treatment.product.tag].length
            : 0;

        acc.colorMap[treatment.product.slug] = treatment.product.purpose_tag
          ? Object.values(
              transparentCarePhaseColorMap[treatment.product.purpose_tag]
            )[colorIndexTransparentCare]
          : TREATMENT_TYPE_TO_COLOR[treatment.product.tag][colorIndex];
        acc.indexMap[treatment.product.tag] = colorIndex;

        return acc;
      },
      {
        colorMap: {} as { [key: string]: string },
        indexMap: {} as { [key: string]: number },
      }
    ).colorMap;
  }, [calendarTreatments]);

  const weekSchedule = treatmentCalendar.treatment_schedule.filter(
    (d) => d.week === currentWeek
  );
  var currentWeekMessage = getWeekMessage(currentWeek, treatmentPlan);

  return (
    <>
      {/* CTA for mobile view only. Only displayed for treatment plans (not maintenance) */}
      {isTreatmentPlan &&
        getWeekCTA(currentWeek, treatmentCalendar.last_treatment_week) &&
        !editingStartDate && (
          <div className="block sm:hidden p-6 pt-0 sm:pt-6 text-center">
            <TreatmentCalendarCTA
              ctaType={getWeekCTA(
                currentWeek,
                treatmentCalendar.last_treatment_week
              )}
              treatmentPlan={treatmentPlan}
              retestLink={retestLink}
              isMember={treatmentPlan.consult}
            />
          </div>
        )}

      {/* Only display message for treatment plans (not maintenance plans) */}
      {isTreatmentPlan && currentWeekMessage && (
        <div>
          {/* web display */}
          <div className="hidden sm:block px-8 medium leading-5">
            <Markdown>{currentWeekMessage}</Markdown>
          </div>

          {/* mobile display */}
          <div className="block sm:hidden px-8 py-4 flex items-center">
            <div className="medium">
              <div className="flex items-center text-evvy-pine">
                <Lightbulb
                  stroke={secondaryColors["evvy-pine"]}
                  width="20px"
                  height="20px"
                />
                <div className="ml-2 font-semibold">From the Evvy Team</div>
              </div>
              <div className="mt-2 leading-5">
                <Markdown>{currentWeekMessage}</Markdown>
              </div>
            </div>
          </div>
        </div>
      )}

      {weekSchedule.map((day, index) => (
        <div
          key={index}
          className={`px-8 py-4 sm:py-2 flex items-center ${
            index !== weekSchedule.length - 1 ? "border-b" : ""
          }`}
        >
          <div
            className={`py-4 w-20 flex-shrink-0 ${
              day.period_id ? "text-red-500" : ""
            }`}
          >
            {day.date ? (
              <div
                className={day.period_id ? "cursor-pointer" : ""}
                onClick={() => {
                  if (day.period_id) openPeriodModal(day.period_id);
                }}
              >
                <div className="mb-2 flex">
                  <div className="font-semibold tracking-wider uppercase opacity-50">
                    {dayOfWeek(strDateToTimestamp(day.date))}
                  </div>
                  {day.period_id && <Blood className="h-4" />}
                </div>
                <div className="font-semibold tracking-wider uppercase">
                  {getDateDisplay(day.date)}
                </div>
              </div>
            ) : (
              <div className="font-semibold tracking-wider uppercase my-2">
                Day {day.day}
              </div>
            )}
          </div>
          <div className="ml-8 flex-grow block sm:flex gap-x-4 space-y-4 sm:space-y-0">
            {day?.prescriptions.map((prescription: Prescription) => (
              <div
                key={prescription.slug}
                className={`block sm:w-1/3 rounded-md p-4 medium cursor-pointer ${
                  treatmentColorMap[prescription.slug]
                }`}
                onClick={() => openPrescriptionModal(prescription)}
              >
                {prescription.title_short_display}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default TreatmentCalendarWeek;

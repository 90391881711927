import { useState } from "react";

const TreatmentPreview = ({ consult, isMobile }) => {
  /*
   * State
   */
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <div className="mt-8">
      <div className="text-sm uppercase tracking-wider font-semibold mb-2 text-gray-500">
        Your treatment
      </div>
      <div className={`${isMobile ? "b2" : "b1"} regular mb-4`}>
        An Evvy-affiliated provider has reviewed and prescribed your treatment
      </div>
      <div className={`${isMobile ? "b2" : "b1"} regular`}>
        Treatments will be shipped directly to you and should arrive within 3-5
        days. Check your email for tracking information!{" "}
        {consult?.prescription_tracking_link ? (
          <span>
            Click{" "}
            <a
              className="underline persistSize"
              target="_blank"
              href={consult.prescription_tracking_link}
              rel="noreferrer"
            >
              here
            </a>{" "}
            to track your order!
          </span>
        ) : (
          <span>
            Reach out to{" "}
            <a className="underline persistSize" href="mailto:support@evvy.com">
              support@evvy.com
            </a>{" "}
            if you have any questions!
          </span>
        )}
      </div>
    </div>
  );
};

export default TreatmentPreview;

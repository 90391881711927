import {
  Page,
  Text,
  Link,
  View,
  Font,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";

import { Marked } from "@ts-stack/markdown";

import pdfStyles from "./pdfStyles.js";
import {
  getWeekCTA,
  getWeekMessage,
  weekCtaTitle,
  weekCtaDescriptionPdf,
} from "./constants.ts";
import {
  dayOfWeek,
  strDateToTimestamp,
  getDateDisplay,
} from "../../utils/datetime";
import { capitalizeFirst } from "../../utils/text";
import {
  SEXUAL_ACTIVITY_TITLE,
  SEXUAL_ACTIVITY_P1,
  SEXUAL_ACTIVITY_P2,
  PERIOD_TITLE,
  PERIOD_P1,
  PERIOD_P2_TREATMENT_ONLY,
  TIPS_TITLE,
  TIPS_DESCRIPTION,
  TIPS_BULLETS,
} from "./constants.ts";
import RedBloodIcon from "../care/icons/red-blood.png";
import { CARE_SUPPORT_EMAIL } from "../../pages/constants.ts";
import useTreatmentPlan from "../../hooks/care/useTreatmentPlan";
import { getPurposeTagText } from "./utils";

// Create styles
const styles = StyleSheet.create(pdfStyles);

// this disables hyphenation on text wrapping (https://react-pdf.org/fonts#registerhyphenationcallback)
Font.registerHyphenationCallback((word) => [word]);

// renders the contents of the treatment plan export PDF
const TreatmentPlanPdfExport = ({
  consult,
  isEmbed,
  patientFirstName,
  isMenopausal,
}) => {
  const { treatmentPlan } = useTreatmentPlan(consult.uid);
  const planType = consult?.plan_type || "treatment";
  const isTreatmentPlan = planType === "treatment";

  // quick exits if we dont have data to return
  var errorMessage = "";
  if (!treatmentPlan || !consult || !patientFirstName) {
    errorMessage = "Missing required data";
  }
  // if (!treatmentPlan.calendar || !treatmentPlan.calendar.start_date){
  //   errorMessage = 'Missing start date for treatment plan'
  // }
  if (errorMessage) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text>Please select start date for ${planType} plan</Text>
        </Page>
      </Document>
    );
  }

  const tagToColor = (tag) => {
    if (tag === "Reduce Disruptive") {
      return styles.backgroundSalmon;
    } else if (tag === "Promote Protective") {
      return styles.backgroundEvvyBlue;
    } else {
      return styles.backgroundCrazyPurple;
    }
  };

  const purposeToColor = (purpose) => {
    if (purpose === "rebuild") return styles.backgroundEvvyBlue;
    else if (purpose === "relief") return styles.backgroundMint;
    else return styles.backgroundSalmon;
  };

  const renderFooter = () => {
    return (
      <View style={styles.calendarFooter} fixed>
        <Image style={styles.footerLogo} src="/images/evvy-logo-text.png" />

        <View style={{ flexDirection: "row" }}>
          <Text>
            <Link
              style={styles.footerEmail}
              src={"https://my.evvy.com/treatment"}
            >
              my.evvy.com/treatment
            </Link>
            {" | "}
            <Link
              style={styles.footerEmail}
              src={`mailto:${CARE_SUPPORT_EMAIL}`}
            >
              {CARE_SUPPORT_EMAIL}
            </Link>
          </Text>
        </View>

        {/* only do when downloading. when embedding using PDFViewer, this causes recursive loop :( */}
        {!isEmbed ? (
          <Text
            style={styles.footerPage}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        ) : (
          <Text style={styles.footerPage}>Page X of Y</Text>
        )}
      </View>
    );
  };

  // detects any markdown and replaces with styled html
  const renderMarkdown = (markdown) => {
    return (
      <Html
        stylesheet={{
          p: styles.htmlBlocks,
        }}
      >
        {Marked.parse(markdown)}
      </Html>
    );
  };

  const weeks = treatmentPlan
    ? Array.from(
        { length: treatmentPlan.calendar.last_treatment_week },
        (_, i) => i + 1
      )
    : Array.from({ length: 9 }, (_, i) => i + 1);

  return (
    <Document>
      {/* Intro page with the treatments */}
      <Page size="A4" style={[styles.page, styles.planPage]}>
        <View style={styles.header} fixed>
          <Text style={styles.name}>
            {capitalizeFirst(patientFirstName) || "Evvy"}'s
          </Text>
          <Text>{capitalizeFirst(planType)} Program</Text>
        </View>

        <View style={styles.context}>
          <View style={styles.intro}>
            <Text style={styles.introTitle}>
              Best practices during {planType}:
            </Text>
            <View style={styles.tip}>
              <View style={styles.tipIconContainer}>
                <Image
                  style={styles.tipIconHeart}
                  src={"/images/icons/heart.png"}
                />
              </View>
              <View>
                <Text style={styles.tipTitle}>{SEXUAL_ACTIVITY_TITLE}</Text>
                <Text style={styles.tipInfo}>
                  {SEXUAL_ACTIVITY_P1[planType]} {SEXUAL_ACTIVITY_P2[planType]}
                </Text>
              </View>
            </View>
            {!isMenopausal && (
              <View style={styles.tip}>
                <View style={styles.tipIconContainer}>
                  <Image
                    style={styles.tipIconHeart}
                    src={"/images/icons/blood.png"}
                  />
                </View>
                <View>
                  <Text style={styles.tipTitle}>{PERIOD_TITLE}</Text>
                  <Text style={styles.tipInfo}>
                    {PERIOD_P1}{" "}
                    {isTreatmentPlan ? PERIOD_P2_TREATMENT_ONLY : ""}
                  </Text>
                </View>
              </View>
            )}
            <View style={styles.tip}>
              <View style={styles.tipIconContainer}>
                <Image
                  style={styles.tipIconBlood}
                  src={"/images/icons/heart.png"}
                />
              </View>
              <View>
                <Text style={styles.tipTitle}>{TIPS_TITLE}</Text>
                <Text style={styles.tipInfo}>{TIPS_DESCRIPTION}</Text>
                <Text>{"\u2022" + ` ${TIPS_BULLETS[0]}`}</Text>
                <Text>{"\u2022" + ` ${TIPS_BULLETS[1]}`}</Text>
                <Text>{"\u2022" + ` ${TIPS_BULLETS[2]}`}</Text>
                <Text>{"\u2022" + ` ${TIPS_BULLETS[3]}`}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.questions}>
          <Text>
            Questions? See your full treatment details and FAQs at{" "}
            <Link src="https://my.evvy.com/treatment">
              my.evvy.com/treatment
            </Link>{" "}
            or reach out to{" "}
            <Link src={`mailto:${CARE_SUPPORT_EMAIL}`}>
              {CARE_SUPPORT_EMAIL}
            </Link>
          </Text>
        </View>
        <View style={styles.prescriptionsTable}>
          <View style={styles.prescriptionsTableHeader} fixed>
            <Text
              style={[
                styles.tableCell,
                styles.nameColumn,
                styles.prescriptionsTableHeaderCell,
              ]}
            >
              Prescription Name
            </Text>
            <Text
              style={[
                styles.tableCell,
                styles.descriptionColumn,
                styles.prescriptionsTableHeaderCell,
              ]}
            >
              Description
            </Text>
            <Text
              style={[
                styles.tableCell,
                styles.howColumn,
                styles.prescriptionsTableHeaderCell,
              ]}
            >
              How to Use
            </Text>
          </View>

          {treatmentPlan.prescriptions.map((prescription, index) => (
            <View
              key={prescription.slug}
              style={[
                styles.tableRow,
                index === treatmentPlan.prescriptions.length - 1
                  ? styles.lastPrescription
                  : "",
              ]}
              wrap={false}
            >
              <View style={[styles.tableCell, styles.nameColumn]}>
                <View style={[styles.nameImageContainer]}>
                  {prescription.image_url && (
                    <Image
                      style={styles.nameImage}
                      src={prescription.image_url + `?timestamp=${new Date()}`}
                    />
                  )}
                </View>
                <View>
                  <Text style={[styles.nameTitle]}>
                    {prescription.title.split("|").join("\n")}
                  </Text>
                  <View style={[styles.nameDose]}>
                    {prescription.dose_text.split("|").map((t) => (
                      <Text key={t} style={[styles.textRow]}>
                        {t}
                      </Text>
                    ))}
                  </View>
                  {prescription.ingredients && (
                    <View>
                      <Text style={[styles.nameIngredientsTitle]}>
                        Ingredients
                      </Text>
                      <Text style={[styles.nameIngredients]}>
                        {prescription.ingredients}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View style={[styles.tableCell, styles.descriptionColumn]}>
                <View style={styles.descriptionTagContainer}>
                  {prescription.purpose_tag && (
                    <Text
                      style={[
                        styles.descriptionTag,
                        purposeToColor(prescription.purpose_tag),
                      ]}
                    >
                      {getPurposeTagText(prescription.purpose_tag)}
                    </Text>
                  )}
                </View>
                {prescription.reason_for_prescribing.split("|").map((r) => (
                  <Text key={r} style={[styles.textRow]}>
                    {renderMarkdown(r)}
                  </Text>
                ))}
              </View>
              <View style={[styles.tableCell, styles.howColumn]}>
                {prescription.warning && (
                  <View style={[styles.howWarning]}>
                    {prescription.warning.split("|").map((p) => (
                      <Text key={p} style={[styles.textRow]}>
                        {renderMarkdown(p)}
                      </Text>
                    ))}
                  </View>
                )}
                {prescription.instructions.split("|").map((r) => (
                  <Text key={r} style={[styles.textRow]}>
                    {renderMarkdown(r)}
                  </Text>
                ))}
              </View>
            </View>
          ))}
        </View>

        {/* Footer for treatment plan page format (should be same as calendar page format) */}
        {renderFooter()}
      </Page>

      {/* Pages for calendar */}
      {consult.purchase_type !== "a-la-care" && (
        <Page size="A4" style={styles.page}>
          <View style={styles.calendarHeader} fixed>
            <Text>
              {capitalizeFirst(patientFirstName) || "Evvy"}'s{" "}
              {capitalizeFirst(planType)} Program
            </Text>
          </View>

          {weeks.map((week) => (
            <View key={week} style={styles.week} wrap={false}>
              <View style={styles.weekDescription}>
                <View style={styles.weekTitle}>
                  <Text style={styles.weekTitleHighlight}>Week {week}</Text>
                  <Text style={styles.weekTitleDull}>of {weeks.length}</Text>
                </View>
                {isTreatmentPlan && getWeekMessage(week, treatmentPlan) && (
                  <Text style={styles.weekDescriptionMessage}>
                    {getWeekMessage(week, treatmentPlan).replace(
                      "<br><br>",
                      ""
                    )}
                  </Text>
                )}

                {isTreatmentPlan && getWeekCTA(week, weeks.length) ? (
                  <View style={styles.cta}>
                    <View style={styles.ctaTitle}>
                      <Image
                        style={styles.checkmarkIcon}
                        src="/images/icons/green-checkmark.png"
                      />
                      <Text>
                        {weekCtaTitle[getWeekCTA(week, weeks.length)] ||
                          "Unknown type"}
                      </Text>
                    </View>
                    <Text style={styles.ctaBody}>
                      {weekCtaDescriptionPdf[getWeekCTA(week, weeks.length)] ||
                        "Unknown type"}
                    </Text>
                  </View>
                ) : null}
              </View>
              <View style={styles.weekSchedule}>
                {treatmentPlan.calendar.treatment_schedule
                  .filter((d) => d.week === week)
                  .map((day) => (
                    <View
                      key={day.date}
                      style={[styles.day, day.day === 7 ? styles.lastDay : ""]}
                    >
                      <View
                        style={[
                          styles.dayTitle,
                          day.period_id ? styles.dayPeriod : "",
                        ]}
                      >
                        <Text style={styles.dayNumber}>
                          Day {(day.week - 1) * 7 + day.day}{" "}
                          {day.period_id && <Image src={RedBloodIcon} />}
                        </Text>
                        {day.date && (
                          <Text>
                            {dayOfWeek(strDateToTimestamp(day.date))} ·{" "}
                            {getDateDisplay(day.date)}
                          </Text>
                        )}
                      </View>
                      <View style={styles.dayPrescriptions}>
                        {day.prescriptions.map((p, index) => (
                          <View
                            key={index}
                            style={[
                              styles.dayPrescription,
                              purposeToColor(p.purpose_tag),
                            ]}
                          >
                            <Text>
                              {p.title.split("(")[0].replace("|", "")}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          ))}

          {/* Footer for weekly calendar page format (should be same as treatment plan page format) */}
          {renderFooter()}
        </Page>
      )}
    </Document>
  );
};

export default TreatmentPlanPdfExport;

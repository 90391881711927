import { useContext } from "react";
import { Link } from "react-router-dom";
import { useProviderProfile } from "../hooks/provider/useProviderProfile";
import { useIdentity } from "../hooks/useIdentity";
import { AuthContext } from "../contexts/authContext";
import { useLatestOrder } from "../hooks/useLatestOrder";

const legalDisclaimerUS = `Evvy is a vaginal health platform that provides access to comprehensive testing and care. We partner with independent clinical entities that can provide medical advice, care, lab testing, pharmacy services, and more through their licensed professionals. Evvy itself is not a healthcare provider and cannot provide medical advice or care. We do not own, operate, supervise, or control the medical practices or other services users may access through the Evvy platform.  Evvy is not intended to replace the advice of your doctor, your Pap smear, or regular OB-GYN visits.`;
const legalDisclaimerCA = `The Evvy Vaginal Health Test - Canada is for people with vaginas who want to be empowered with information about their microbiome. The Evvy test in Canada is intended exclusively for wellness purposes. Evvy cannot provide you with medical advice or diagnose you with any disease or condition in Canada. Evvy does not provide medical advice and is not intended to replace the advice of your doctor.`;

const AuthenticatedFooter = () => {
  const { latestOrder } = useLatestOrder();
  const { isProvider } = useIdentity();
  const [providerProfile] = useProviderProfile();

  const getLegalDisclaimer = () => {
    const providerCountryIsUS =
      providerProfile?.clinic_country === "United States";
    const providerCountryIsCA = providerProfile?.clinic_country === "Canada";

    if (isProvider) {
      if (providerCountryIsCA) {
        return legalDisclaimerCA;
      } else if (providerCountryIsUS) {
        return legalDisclaimerUS;
      }
    }
    if (latestOrder?.country === "Canada") {
      return legalDisclaimerCA;
    }
    return legalDisclaimerUS;
  };

  return <FooterContent disclaimer={getLegalDisclaimer()} />;
};

const FooterContent = ({ disclaimer }: { disclaimer: string }) => {
  const copyrightText = `Copyright © ${new Date().getFullYear()} Evvy. All rights reserved`;
  const policyLinkStyle = "underline underline-offset-2";

  return (
    <footer className="bg-evvy-black text-evvy-cream px-6 md:px-16 pt-6 pb-8 md:flex text-sm z-0 align-bottom">
      <div className="max-w-xl pb-4 md:pb-0">
        <Link to="/tests" className="pointer-events-auto">
          <img
            className="h-8 mt-2 sm:h-10 mb-4"
            src="/images/evvy-logo-text-white.svg"
            loading="lazy"
            alt="Evvy Text Logo"
          />
        </Link>
        <div>{disclaimer}</div>
      </div>

      <div className="flex md:ml-auto items-end">
        <div className="hidden md:block">{copyrightText}</div>
        <div className="px-2 hidden md:block"></div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/terms-of-service">Terms of Service</a>
        </div>
        <div className="px-2">|</div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/privacy-policy">Privacy Policy</a>
        </div>
        <div className="px-2">|</div>
        <div className={policyLinkStyle}>
          <a href="https://www.evvy.com/consumer-health-data-privacy-policy">
            Consumer Health Data Privacy
          </a>
        </div>
      </div>
      <div className="block md:hidden mt-2">{copyrightText}</div>
    </footer>
  );
};

const Footer = () => {
  const { authenticated } = useContext(AuthContext);

  if (authenticated) {
    return <AuthenticatedFooter />;
  }

  return <FooterContent disclaimer={legalDisclaimerUS} />;
};

export default Footer;

import React from "react";

import Tippy from "@tippyjs/react";

import { CalendarTreatment, Prescription } from "../../../types/care";
import { ReactComponent as Info } from "../../plan_unfurled/icons/info.svg";

import { transparentCarePhaseColorMap } from "../../../utils/colors";
import { useBaseAnalyticsArgs } from "../../../hooks/useBaseAnalyticsArgs";
import { sendClickedEditTreatment } from "../../../utils/analytics/customEventTracking";

export const TreatmentList: React.FC<{
  calendarTreatments: CalendarTreatment[];
  setEditingTreatment: (treatment: CalendarTreatment) => void;
  openPrescriptionModal: (prescription: Prescription) => void;
}> = ({ calendarTreatments, setEditingTreatment, openPrescriptionModal }) => {
  var prevTreatmentType = "";
  var colorIndex = 0;

  return (
    <div className="my-2">
      <div className="medium mb-3">Your treatments</div>
      <div className="space-y-3">
        {calendarTreatments.map((treatment, index) => {
          let backgroundColorClass = "";

          if (prevTreatmentType === treatment.product.purpose_tag) {
            colorIndex = (colorIndex + 1) % 3;
          } else {
            colorIndex = 0;
          }
          if (treatment.product.purpose_tag) {
            backgroundColorClass = Object.values(
              transparentCarePhaseColorMap[treatment.product.purpose_tag]
            )[colorIndex];
          }

          prevTreatmentType = treatment.product.purpose_tag || "";

          return (
            <TreatmentItem
              key={`treatment-${index}`}
              backgroundColorClass={backgroundColorClass}
              treatment={treatment}
              setEditingTreatment={setEditingTreatment}
              openModal={openPrescriptionModal}
            />
          );
        })}
      </div>
    </div>
  );
};

const TreatmentItem: React.FC<{
  backgroundColorClass: string;
  treatment: CalendarTreatment;
  setEditingTreatment: (treatment: CalendarTreatment) => void;
  openModal: (prescription: Prescription) => void;
}> = ({ backgroundColorClass, treatment, setEditingTreatment, openModal }) => {
  const backgroundColor = !treatment.deleted
    ? backgroundColorClass
    : "bg-evvy-cream/50 border border-dashed border-slate-300";
  const showEditTools = treatment.dates?.start_date || treatment.deleted;
  const editsAllowed =
    treatment.deleted ||
    (showEditTools && treatment.allowed_adjustments.length > 0);

  const baseAnalyticsArgs = useBaseAnalyticsArgs();

  return (
    <div
      className={`${backgroundColor} p-4 flex justify-between rounded-md my-auto content-center`}
    >
      <div
        className="my-auto cursor-pointer"
        onClick={() => openModal(treatment?.product)}
      >
        {treatment.product.title_short_display}
      </div>
      {showEditTools && (
        <>
          {editsAllowed && (
            <div
              className={`font-semibold uppercase tracking-wide text-sm/[13px] cursor-pointer ${
                !editsAllowed ? "hidden" : ""
              }`}
              onClick={() => {
                setEditingTreatment(treatment);
                sendClickedEditTreatment({
                  ...baseAnalyticsArgs,
                  calendarTreatmentId: treatment.id,
                  prescriptionSlug: treatment.product.slug,
                });
              }}
            >
              {treatment.deleted ? "Add" : "Edit"}
            </div>
          )}
          {!editsAllowed && <NonEditableTreatmentTooltip />}
        </>
      )}
    </div>
  );
};

const NonEditableTreatmentTooltip = () => {
  return (
    <span className="my-auto">
      <Tippy
        arrow
        render={() => (
          <div
            id="tooltip"
            role="tooltip"
            className="flex bg-evvy-pine p-3 rounded-md max-w-sm"
            tabIndex={-1}
          >
            <div className="text-evvy-white">
              This treatment should not be edited for optimal clinical efficacy.
              Please reach out to care@evvy.com for any questions.
            </div>
          </div>
        )}
        animation={false}
      >
        <Info
          stroke="currentColor"
          fill="currentColor"
          className="ml-1.5 w-3.5 h-3.5 text-evvy-black-dull/50"
        />
      </Tippy>
    </span>
  );
};

import classNames from "classnames";
import { cn } from "../../../../utils/cn";

interface PageWrapperProps {
  section?: string;
  children: React.ReactNode;
  roundTop?: boolean;
}

const PageWrapper = ({ section, children, roundTop }: PageWrapperProps) => {
  return (
    <div className={classNames("flex flex-col w-full")}>
      {/* Section title */}
      {section && (
        <div className="mt-[20px] sm:mt-[10px] text-center rounded-t-xl h-full py-2.5 bg-evvy-dark-beige">
          <p className="text-xl semibold p-0 m-0">{section}</p>
        </div>
      )}
      <div
        className={cn(`rounded-b-xl bg-white mb-20 h-full py-2.5`, {
          "sm:p-[30px] p-5": true,
          "rounded-t-xl": roundTop,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const PageWrapperFormFooter = ({
  buttonCTA,
  isSubmitting,
  disabled,
  handleSubmit,
}: {
  buttonCTA: string;
  isSubmitting?: boolean;
  disabled?: boolean;
  handleSubmit?: (data: any) => void;
}) => (
  <div className="z-100">
    <div className="border-t border-evvy-black border-opacity-20 bg-evvy-cream fixed bottom-0 left-0 right-0 p-4 flex justify-center">
      <div className="block sm:flex items-center">
        <div className="flex-auto text-center">
          <button
            className="cursor-pointer tracking-wider w-64 sm:w-96 py-4 px-10 sm:px-18 font-semibold rounded-xl shadow-sm uppercase bg-evvy-blue focus:outline-none"
            onClick={handleSubmit || (() => {})}
            disabled={isSubmitting || disabled}
            type="button"
          >
            {buttonCTA}
          </button>
        </div>
      </div>
    </div>
  </div>
);

PageWrapper.FormFooter = PageWrapperFormFooter;

export default PageWrapper;
